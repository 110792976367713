import { Box, Button, Container, Stack, Tooltip, Typography } from '@mui/material'
import React, { useState, useContext, useEffect } from 'react'
import RPLogo from './RPLogo'
import { ShoppingCart, SportsBar, VideoChatOutlined } from '@mui/icons-material'
import VideocamIcon from '@mui/icons-material/Videocam';
import { StorefrontContext, StorefrontProvider } from "../../../context/StorefrontProvider";
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import useWindowDimensions from "../../../hooks/windowDimensions";
import SocialLinks from './SocialLinks';
import RedeemIcon from '@mui/icons-material/Redeem';
const MusicHeader = (props) => {
    const { height, width } = useWindowDimensions();
    const { checkoutModalOpen,
        setCheckoutModalOpen,
        listener, email, colors, setUpdateModalOpen, albums,
        chatModalOpen, setChatModalOpen, tipModalOpen, setTipModalOpen,
        albumNav, setAlbumNav, isStreaming, setIsStreaming, setStreamModalOpen,
        setGameModalOpen, artist, roomConfig,
    } = props
    const { musicCart, streamConfig } = useContext(StorefrontContext);

    useEffect(() => {
        console.log('[MusicHeader]  roomConfig:', roomConfig)
        console.log('[MusicHeader]  streamConfig:', streamConfig,)

    }, [roomConfig, streamConfig,])


    return (
        <Stack sx={{
            position: 'absolute', top: '0rem',
            left: '50%',
            width: '100%', height: '25vh',
            transform: 'translate(-50%,0)',
            borderRadius: '5px 5px 0px 0px',
            // maxWidth:'150px',
            // border:'1px solid red',
            margin: 'auto',
            // marginBottom:'1.5rem',
            //padding:'0.25rem',
            // border:' 1px solid red',
            justifyContent: 'center', alignContent: 'center', alignItems: 'center',
            boxShadow: 'inset 0px -2px 11px 5px rgba(255,255,255,0.5),0px -5px 5px 2px rgba(0,0,0,0.1)',
            // backgroundImage:`url("https://res.cloudinary.com/dzxzdsnha/image/upload/v1690040515/Rex_Entropy_Hero_Star_Bg_SML_btvj6p.png")`,//backgroundAttachment:'fixed',
            backgroundImage: `url("https://res.cloudinary.com/dzxzdsnha/image/upload/v1734750295/ART/TestGrid_Crop_md198j.png")`,//backgroundAttachment:'fixed',
            backgroundRepeat: 'no-repeat',
            // backgroundPositionY:`${album?.album_pic[2]}px`,
            backgroundPositionY: width >= 600 ? `` : '',
            backgroundSize: 'cover',
            // backgroundPosition:`fixed`,
        }}>
            {/* <Box sx={{
            border:'2px solid yellow',
        display:'flex',
        flexDirection:'column',
        // position:'absolute',
        // top:'0px',
        // left:'0px',
        justifyContent:'center',
        height:'30vh',
        // width:'100vw', 
        border:'1px solid blue', 
        margin:'auto',
        height:'fit-content'
        }}> */}
            <Stack sx={{
                border: `1px solid ${colors.main}`,
                position: 'absolute',
                margin: 'auto',
                maxWidth: '250px',
                minWidth: '150px',
                top: '-1.5rem', zIndex: 999,
                backgroundColor: '#FFF',
                width: 'fit-content', margin: 'auto',
                color: colors.accent,
                cursor: 'pointer',
                boxShadow: '0 0 3px 2px rgba( 0, 0, 0, 0.1), inset 0 0 3px 1px rgba( 0, 0,0,0.3)',
                '&:hover': { background: 'rgba(144, 254, 195,0.5)' },
                '&:active': { boxShadow: 'inset 0 0 3px 2px rgba( 0, 0, 0, 0.1)' },
                borderRadius: '5px', zIndex: 999999
            }}
                onClick={() => {
                    setUpdateModalOpen(true)
                }}
            >
                <Box style={{
                    fontSize: '0.8rem', borderBottom: `1px solid ${colors.main}`,
                    borderRadius: '5px 5px 0 0', height: '2rem', overflow: 'hidden',
                    padding: ' 0.125rem 0.25rem'
                }}>
                    <p>
                        Welcome,
                    </p>
                    <b style={{ whiteSpace: 'wrap', textWrap: 'pretty' }}>{listener?.preferredName ? listener?.preferredName : email}!</b>
                </Box>
                <b style={{
                    fontSize: '0.75rem', backgroundColor: colors.accent,
                    borderRadius: '0 0 5px 5px', color: '#FFF', padding: '0.25rem'
                }}>
                    click to edit your profile
                </b>
            </Stack>
            <Stack
                sx={{
                    height: '16vh',
                    position: 'absolute', top: '0rem',
                    // border:'1px solid rgba( 0 , 255, 1,0.5)',
                    width: '250px',
                    // left:'50%'
                }}>
                <RPLogo artist={artist} />

            </Stack>




            <Stack sx={{
                position: 'absolute',
                right: '0.5rem', top: '0.5rem',
                justifyContent: 'end',
                gap: '0.5rem',

                alignItems: 'end'
            }}>
                <Tooltip title='live stream'>

                    <Box sx={{
                        display: 'flex',
                        gap: '0.25rem',
                        //  height:' 1.5rem',
                        backdropFilter: 'blur(7px)',
                        borderRadius: '10px', alignContent: 'center', alignItems: 'center',
                        padding: '0.125rem 0.5rem', cursor: 'pointer',
                        boxShadow: `0px 0px 5px 2px ${artist?.stream?.isStreaming ? 'rgba( 0 , 255, 1,0.5)' : 'rgba(255 , 1, 0,0.5)'}, inset 0px 0px 3px 1px ${artist?.stream?.isStreaming ? 'rgba( 0 , 255, 1,0.5)' : 'rgba(255 , 1, 0,0.5)'}`,
                        border: `1px solid ${artist?.stream?.isStreaming ? 'rgba( 0 , 255, 1,0.5)' : 'rgba(255 , 1, 0,0.5)'}`,
                        '&:hover': { background: 'rgba( 255, 255, 255,0.5)' },
                        '&:active': { boxShadow: 'inset 0 0 3px 2px rgba( 0, 0, 0, 0.1)' }
                    }}
                        onClick={() => {
                            console.log('roomconfig', roomConfig)
                            // if (roomConfig?.code) {
                            setStreamModalOpen(true)
                            // } else { return }
                        }}
                    >

                        <Stack style={{ color: artist?.stream?.isStreaming ? 'rgba( 0 , 255, 1,0.5)' : 'rgba(255 , 1, 0,0.5)', fontSize: '0.8rem' }}>
                            {/* <b>
                        Live
                        </b> */}
                            <b>
                                STREAM{artist?.stream?.isStreaming ? 'ING' : ''}
                            </b>
                        </Stack>
                        <div
                            className={!artist?.stream?.isStreaming ? 'blink' : 'blink-2'}
                            style={{
                                width: '0.7rem', height: '0.7rem',
                                marginRight: '0.25rem',
                                marginLeft: '0.125rem',
                                borderRadius: '100%', backgroundColor: artist?.stream?.isStreaming ? 'rgba( 0 , 255, 1,0.5)' : 'rgba(255 , 1, 0,0.5)'
                            }}>&nbsp;</div>
                    </Box>
                </Tooltip>

                <Tooltip title='🔥🙏🙌 send some love 🙌🙏🔥'>

                    <Button
                        className='blink button-woddle'
                        sx={{
                            padding: '0.125rem 0.5rem',
                            display: 'flex',
                            fontSize: '0.65rem',
                            cursor: 'pointer',
                            // position:'absolute',
                            // top:'2.5rem',
                            // right:'0.5rem',
                            color: 'rgba(189, 255, 6, 0.99)',
                            borderRadius: '50px 50px 50px 50px',
                            // width: '1.5rem',
                            height: ' 1.5rem',
                            boxShadow: '0px 0px 5px 2px rgb(255, 255, 1)',
                            border: '2px solid #00FF00',
                            alignItems: 'center',
                            alignContent: 'center',
                            justifyContent: 'center',
                            backdropFilter: 'blur(7px)',
                            // backgroundColor:'rgba(0,255,0,0.5)',
                            gap: '0.125rem',
                        }}
                        onClick={() => {
                            setTipModalOpen(true)
                        }}
                    >Send a
                        <RedeemIcon
                            className='button-woddle'
                            sx={{ cursor: 'pointer', fontSize: '1.2rem', }}
                        />
                        {/* <SportsBar
                 sx={{cursor:'pointer' ,fontSize:'1.2rem',}}
                /> */}
                        {/* drink */}
                    </Button>
                </Tooltip>

                {/* <Tooltip title='video chat'>

            <Button
                // className='blink'
                 size='small'
                sx={{
                    cursor:'pointer',
                    // position:'absolute',
                    // fontSize:'0.65rem',
                    // top:'4.5rem',
                    // right:'0.5rem',
                    color:'#00FF00', 
                    height:'1.5rem',
                     padding:'0.5rem',

                    backgroundColor:'rgb(255,102,1,0.5)',
                    // backgroundColor:'#FFF',
                    borderRadius:'50px 50px 50px 50px',
                    // width: '2rem', height:' 2rem',
                    boxShadow:'0px 0px 5px 2px rgb(255,102,1)',
                    border: '2px solid rgb(255,102,1)',
                    alignItems: 'center',
                    alignContent:'center',
                    justifyContent:'center',
                    gap:'0.25rem'
                }}
                onClick={()=>{ setChatModalOpen(true)}}
                >

                <VideocamIcon sx={{cursor:'pointer' ,fontSize:'1rem',}}/>
                chat
            </Button>
        </Tooltip> */}
                <Tooltip title='cart'>
                    <Box sx={{
                        display: 'flex',//position:'absolute', bottom:'1.5rem', right:'0.5rem',
                        color: '#00FF00', cursor: 'pointer', minWidth: '2.5rem', height: '1rem',
                        width: '1.5rem',
                        backdropFilter: 'blur(7px)',
                        padding: '0.125rem 0.125rem',
                        boxShadow: '0px 0px 5px 2px #FFF',
                        alignContent: 'center', alignItems: 'center', textAlign: 'center', justifyContent: 'center',
                        border: '2px solid #FFF', borderRadius: '50px', gap: '0.25rem'
                    }}
                        onClick={() => { setCheckoutModalOpen(!checkoutModalOpen) }}
                    >
                        {musicCart?.items?.length ? <b>{musicCart?.items?.length}</b> : <b>0</b>}
                        <ShoppingCart sx={{ alignSelf: 'center', fontSize: '0.95rem' }} className='button-woddle blink' />
                    </Box>
                </Tooltip>

                <Tooltip title='games'
                    className='blink button-woddle'
                    sx={{
                        cursor: 'pointer', border: '3px solid #00FF00', borderRadius: '50%',
                        boxShadow: '0px 0px 5px 2px #00FF00', padding: '0.2rem',
                        position: 'absolute',
                        overflow: 'hidden',
                        backdropFilter: 'blur(7px)',
                        top: '14vh',
                        // top:'15vh',
                        right: '0rem',
                        fontSize: '1.5rem'
                        , color: '#00FF00', zIndex: 999999
                    }}
                    onClick={() => {
                        setGameModalOpen(true)
                    }}
                >
                    <SportsEsportsIcon />
                </Tooltip>
            </Stack>
            {/* <Box component='img'
    src="https://res.cloudinary.com/dzxzdsnha/image/upload/v1734750295/ART/TestGrid_Crop_md198j.png"
    sx={{
        
        //  border:'1px solid rgba(255 , 1, 0,0.5)',
        position:'absolute',
        bottom:'0rem',
        left: 0,
        width:'100%',
        // height:'100%',
        zIndex:-1,
//         background:`url("https://res.cloudinary.com/dzxzdsnha/image/upload/v1734750295/ART/TestGrid_Crop_md198j.png")';
//   )`,
  backgroundRepeat: 'no-repeat',
        // backgroundPositionY:`${album?.album_pic[2]}px`,
        backgroundSize: 'cover',
        backgroundPosition:`fixed`,
  }}>
     </Box> */}
            <Box sx={{
                //  border:'1px solid rgba(255 , 1, 0,0.5)',
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                height: '5vh',
                backgroundImage: `linear-gradient(to top, #ffffff, #ffffff, #ffffff80, #ffffff40, #ffffff00);
  );`}}>
            </Box>
            <div id="scroll-container-2" style={{
                position: 'absolute',
                bottom: '0rem',
                paddingTop: '0.25rem', backgroundColor: 'rgba(0, 0, 0, 0.9)',
                width: '100%',
                borderBottom: '4px ridge rgba(255,255,255,0.7)', //boxShadow:'inset 0px 0px 5px 3px rgba(255,255,255,0.5)', 
                boxShadow: `0px -4px 10px 5px rgba(0, 0, 0, 0.5),inset 0px 0px 10px 4px rgba(255,247,123,${0.3})`,
                borderTop: '2px ridge rgba(255,255,255,0.7)', marginTop: '1rem', backdropFilter: 'blur(10px)', transition: '2s ease-in-out'
            }}>
                <div id={"scroll-text"} style={{ padding: '0', textAlign: 'center' }}>
                    {!listener?.subscriptions[0]?.isActive ?
                        <Typography sx={{ fontFamily: 'LCD2,sans-serif', fontSize: '0.75rem', color: "#00FF00", whiteSpace: 'no-wrap' }}// dangerouslySetInnerHTML={{__html:scrollComp}}>
                        >
                            Welcome! Be sure to subscribe for updates, events, promos & more!
                            {/* <img src='/ßassets/MedMan_Flat.png' height='20rem' width={'auto'} style={{background:' rgb(255,255,255)',
                                   background: 'radial-gradient(circle, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.3) 35%, rgba(255,255,255,0) 81%)'}}/> */}
                        </Typography> :
                        <Typography sx={{ fontFamily: 'LCD2,sans-serif', fontSize: '0.75rem', color: "#00FF00", whiteSpace: 'no-wrap' }}// dangerouslySetInnerHTML={{__html:scrollComp}}>
                        >
                            Welcome back & Thanks for your support!
                            {/* <img src='/assets/MedMan_Flat.png' height='20rem' width={'auto'} style={{background:' rgb(255,255,255)',
                                   background: 'radial-gradient(circle, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.3) 35%, rgba(255,255,255,0) 81%)'}}/> */}
                        </Typography>

                    }
                </div>
            </div>
        </Stack>
    )
}

export default MusicHeader