import React, { useState, useRef, useContext, useEffect } from "react";
import { AppContext } from "../context/AppProvider";
import { QRCodeContext } from "../context/QRCodeProvider";
import { StorefrontContext, StorefrontProvider } from "../context/StorefrontProvider";
import QRCodeStyling from "qr-code-styling";
import defaultQR from '../defaults/QRRough.json'
import {
  Box, Button, Container, FormControl, MenuItem, InputLabel,
  Divider,
  Select, Stack, TextField, Typography, Switch
} from "@mui/material";

import * as API from '../utils/APICalls'
import * as helpers from '../utils/helpers'

// import { Canvas } from "react-canvas";
// import User from "../../../api/models/userModel";


const CreateHMNIStickerAsset = (props) => {
  const { view, qr_pic, setFramingModalOpen, order } = props

  const { qrCodeData, updateQRCodeData } = useContext(QRCodeContext);

  const { assetText, setAssetText, buildStep, setBuildStep,
    cart, setCart, setShop, shop, storefrontData,
    createCart, generateOrder,
    updateStorefrontData } = useContext(StorefrontContext);

  const { appData, updateAppData, currentOrder, setCurrentOrder, modalOpen, newPersona, setNewPersona, currentPersona, setCurrentPersona,
    assetQR, setAssetQR, currentAsset, setCurrentAsset, userData, setQRs, QRs,// currentOrder, setCurrentOrder,
    newAsset, setNewAsset, selectedQR, setSelectedQR } = useContext(AppContext);

  const [showList, setShowList] = useState(false)

  // const [name1, setName1] = useState((view === 'build-shirt' || view === 'process') && currentOrder?.asset_text[0] ? currentOrder?.asset_text[0] : '');
  // const [name2, setname2] = useState((view === 'build-shirt' || view === 'process') && currentOrder?.asset_text[1] ? currentOrder?.asset_text[1] : '');
  const [name1, setName1] = useState(currentOrder?.asset_text?.length >= 1 ? currentOrder?.asset_text[0] : '');
  const [name2, setname2] = useState(currentOrder?.asset_text?.length >= 2 ? currentOrder?.asset_text[1] : '');
  const [fontType, setFontType] = useState('Gilroy');
  const [fontOpen, setFontOpen] = useState(false);
  const [hasChanged, setHasChanged] = useState(true);
  const [isSaved, setIsSaved] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [multiLine, setMultiLine] = useState(currentOrder?.asset_text?.length > 1 ? true : false)
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [qrImage, setQrImage] = useState(null); // State to store the QR image
  const canvasRef = useRef(null);

  const fonts = ['Permanent Marker', 'Gilroy', 'Protest Riot']//,'Sofia']
  const build = 'https://res.cloudinary.com/dzxzdsnha/image/upload/v1711236985/HELLO_My_Name_Is_Sticker_Blank_pbugjt.png';
  const mock = 'https://res.cloudinary.com/dzxzdsnha/image/upload/v1721241460/PARALLAXR_PRINTFILES/REFS/HELLO_My_Name_Is_Sticker_Demo_Slug_hhwrsr.png';
  const imgSrc = view === 'order' ? mock : build
  console.log('[useEffect] imgSrc', imgSrc)
  // if(view === 'order'){

  const handleFontClose = () => { setFontOpen(false); };
  const handleFontOpen = () => { setFontOpen(true); };
  const handleFontChange = async (e) => {
    setHasChanged(true)
    setIsSaved(false)
    await setFontType(e.target.value)

    // console.log('[handleGpChange] selectedPin:',selectedPin)

  };

  const handleSave = async () => {
    try {

      setIsSaving(true)
      if (view === 'order') {
        setCurrentOrder({
          ...currentOrder,
          asset_text: name2 ? [name1, name2] : [name1]
        })
        updateStorefrontData({
          ...storefrontData,
          currentOrder,
        })
        if (!cart?._id) {
          // await setCurrentProduct(item)
          const newCart = await createCart({
            ...currentOrder,
            asset_text: multiLine ? [name1, name2] : [name1]
          }, 'merch')

          if (newCart) {
            await updateStorefrontData({
              ...storefrontData,
              cart: newCart,
              currentOrder
            })
          }
        } else if (cart?._id) {
          await generateOrder(cart, currentOrder)

        }
        else { return }


      } else {

        const canvas = canvasRef.current;
        const image = canvas.toDataURL('image/png').replace("image/png", "image/octet-stream");

        postPicDetails(image)

        // const link = document.createElement('a');
        // link.href = image;
        // link.download = `QauRa_nametag.png`;
        // link.click();
      }
    } catch (err) { console.log(err) }
  };

  const previousPicCheck = async (view) => {
    switch (view) {
      case 'create-asset':
        if (newAsset?.pic_public_id) {
          const a = await API.deleteOrderAsset(newAsset?.pic_public_id)
        }
        break;
      case 'edit-asset':
        if (currentAsset?.pic_public_id) {
          const a = await API.deleteOrderAsset(currentAsset?.pic_public_id)
        }
        break;
      case 'build-shirt':
        if (currentOrder?.pic_public_id) {
          const a = await API.deleteOrderAsset(currentOrder)
        }
        break
    }
  }
  const postPicDetails = async (pics) => {

    if (typeof pics === undefined) {
      console.log("pics undefined")
      return;
    }

    try {

      if (currentOrder?.pic_public_id) {
        previousPicCheck(view)
      }
      const data = new FormData();
      data.append("file", pics);
      data.append("upload_preset", "TinCan");
      data.append("folder", "NAMETAGS");
      data.append("cloud_name", "dzxzdsnha");
      fetch("https://api.cloudinary.com/v1_1/dzxzdsnha/upload", {
        method: "post",
        body: data,
      })
        .then((res) => res.json())
        .then(async (data) => {

          if (view === 'edit-asset') {

            setCurrentAsset({
              ...currentAsset,
              pic: data?.url?.toString(),
              pic_public_id: data?.public_id?.toString(),

            })
            updateAppData({
              ...appData,
              currentAsset,

            })
          }
          if (view === 'create-asset') {

            setNewAsset({
              ...newAsset,
              pic: data?.url?.toString(),
              pic_public_id: data?.public_id?.toString(),
              text: name2 ? [name1, name2] : [name1]
            })
            updateAppData({
              ...appData,
              newAsset,

            })
          }


          if (view === 'build-shirt') {
            // console.log('pre-update currentOrder:', currentOrder)

            // setCurrentOrder({
            //   ...currentOrder,
            //   asset_url: data?.url?.toString(),
            //   asset_pic_public_id: data?.public_id?.toString(),
            //   asset_text: name2 ? [name1, name2] : [name1]
            // })
            // updateStorefrontData({
            //   ...storefrontData,
            //   currentOrder,
            // })
            console.log('pre-save currentOrder:', currentOrder)
            const updatedOrder = await API.updateOrder({
              _id: order?._id,
              asset_url: data?.url?.toString(),
              asset_pic_public_id: data?.public_id?.toString(),
              asset_text: name2 ? [name1, name2] : [name1]
            })
            if (updatedOrder) {

              setCurrentOrder(updatedOrder)
              updateStorefrontData({
                ...storefrontData,
                currentOrder,
              })
            } else { console.log('Error saving asset pic to cloud.') }
            // if (helpers.itemNameFilter(currentOrder?.itemName, "Hello My Name is")) {

            //   setBuildStep(3)
            //   updateStorefrontData({
            //     ...storefrontData,
            //     buildStep
            //   })
            // } else {
            //   setBuildStep(3)
            //   updateStorefrontData({
            //     ...storefrontData,
            //     buildStep
            //   })
            // }


          }

          console.log('[BuildLSAsset]:', data.url);
          setHasChanged(false)
          setIsSaved(true)
          setEditMode(false)
          //  setPicLoading(false);
        })
        .catch((err) => {
          console.log(err);
          //  setPicLoading(false);
        });
      setFramingModalOpen(false)
    } catch (err) { console.log(err) }
    setIsSaving(false)
  }
  const handleChange = (event) => {
    if (event.target.name === 'name1') {
      // setHasChanged(true)
      setName1(event.target.value);
    } else if (event.target.name === 'name2') {
      // setHasChanged(true)
      setname2(event.target.value);
    }
    setHasChanged(true)
    setIsSaved(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true)
    // const text = `${name1}\n${name2}`; 
    try {

      // const image = await fetch('https://res.cloudinary.com/dzxzdsnha/image/upload/v1711293982/Framed_QR_hzwxrs.png'); // Replace with path to your image
      // // const image = await 'https://res.cloudinary.com/dzxzdsnha/image/upload/v1711293982/Framed_QR_hzwxrs.png'; // Replace with path to your image
      // // const image = await fetch('path/to/your/qr_image.png'); // Replace with path to your image
      // const qrImageBlob = await image?.blob();
      // console.log('[handleSubmit] qrImageBlob:',qrImageBlob)
      // setQrImage(URL.createObjectURL(qrImageBlob));
      // setIsSubmitting(false)
      setIsSubmitting(false)
    } catch (err) {
      setIsSubmitting(false)
      console.log(err)
    }
  };
  const drawImage = (ctx, imageUrl, x, y, width, height) => {
    const img = new Image();
    img.src = imageUrl;
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      ctx.drawImage(img, x, y, width, height);
    };
  };
  const fontOutline = (font, string) => {
    switch (font) {
      case 'Gilroy':
        return 20
        break;
      case 'Permanent Marker':
        return 10
        break;
      case 'Protest Riot':
        return 10
        break;
      case 'Sofia':
        return 18
        break;
    }
  }
  const fontSizeSwitch = (type) => {
    switch (type) {
      case 'Gilroy':
        return 240
        break;
      case 'Permanent Marker':
        return 320
        break;
      case 'Protest Riot':
        return 300
        break;
      case 'Sofia':
        return 240
        break;
    }
  }
  const yAxisSwitch = (type) => {
    switch (type) {
      case 'Gilroy':
        return 540
        break;
      case 'Permanent Marker':
        return 580
        break;
      case 'Protest Riot':
        return 580
        break;
      case 'Sofia':
        return 580
        break;
    }
  }
  const drawText = (ctx, text, x, y, maxWidth = 840, fontSize = fontSizeSwitch(fontType), color = 'black') => {
    let currentFontSize = fontSize;
    ctx.font = `900  ${currentFontSize}px ${fontType}`;
    // Measure text width
    let textWidth = ctx.measureText(text).width;
    // Calculate horizontal centering offset
    //   console.log(['text width:', textWidth])
    // Check for overflow and adjust font size if needed
    while (textWidth > maxWidth) {
      currentFontSize -= 2; // Adjust decrement value as needed
      ctx.font = `${currentFontSize}px ${fontType}`;
      textWidth = ctx.measureText(text).width;
    }
    const horizontalCenterOffset = (maxWidth - textWidth) / 2;

    ctx.fillStyle = color;
    ctx.fillText(text, x + horizontalCenterOffset, y);
    ctx.strokeStyle = 'black'
    ctx.lineWidth = fontOutline(fontType, text)
    // ctx.lineJoin = "round"
    ctx.strokeText(text, x + horizontalCenterOffset, y)
  };


  useEffect(() => {
    if (qrCodeData?.pic_public_id && qrCodeData?.pic_public_id !== undefined) {

      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      canvas.style.letterSpacing = '5px';


      // Replace with path to your base image
      const baseImage = new Image();
      baseImage.onload = async () => {
        baseImage.crossOrigin = 'Anonymous';
        ctx.drawImage(baseImage, 0, 0, canvas.width, canvas.height);

        if (!qrImage) {
          //  const image = await fetch('https://res.cloudinary.com/dzxzdsnha/image/upload/v1711293982/Framed_QR_hzwxrs.png'); // Replace with path to your image
          // const image = await fetch('path/to/your/qr_image.png'); // Replace with path to your image
          // const qrImageBlob = await image?.blob();
          // console.log('[handleSubmit] qrImageBlob:',qrImageBlob)
          // setQrImage(URL.createObjectURL(qrImageBlob));
          const image = (view === 'build-shirt') ? qrCodeData?.pic_url : assetQR?.pic_url; // Replace with path to your image
          setQrImage(image);
        }

        if (qrImage && view !== 'order') {
          drawImage(ctx, qrImage, 40, 340, 440, 440);// x-coordinate, y-coordinate, width, height
          // Adjust these values to position and size the image on the canvas

        }
        // Draw Text
        const name1Text = name1; // Assuming minimal formatting needed
        const name2Text = name2;

        drawText(ctx, name1?.toUpperCase(), 540, multiLine ? yAxisSwitch(fontType) : yAxisSwitch(fontType) + 100); // Adjust X and Y for name placement
        if (multiLine) {
          drawText(ctx, name2?.toUpperCase(), 540, yAxisSwitch(fontType) + 220); // Adjust X and Y for company placement
        }
      };

      //   src = 'https://res.cloudinary.com/dzxzdsnha/image/upload/v1721241460/PARALLAXR_PRINTFILES/REFS/HELLO_My_Name_Is_Sticker_Demo_Slug_hhwrsr.png';
      // }
      // else{
      //   src = 'https://res.cloudinary.com/dzxzdsnha/image/upload/v1711236985/HELLO_My_Name_Is_Sticker_Blank_pbugjt.png';
      // }
      // baseImage.src = mock ;
      baseImage.src = imgSrc;
    }
  }, [qrImage, name1, name2, fontType, multiLine]);

  const disableCheck = () => {
    if (view === 'build-shirt' && !currentOrder?.pic_public_id && hasChanged) { return true }
    else if (view === 'create-asset' && newAsset?.pic_public_id && !hasChanged) { return true }
    else if (view === 'edit-asset' && currentAsset?.pic_public_id && !hasChanged) { return true }
    else { return false }

  }


  console.log('currentOrder', currentOrder)

  useEffect(() => {

  }, [currentOrder, newAsset, currentAsset, hasChanged, isSaved])
  return (
    <Box sx={{
      fontFamily: 'Gilroy,sans-serif', display: 'flex', marginTop: '1rem',
      maxWidth: '500px', flexDirection: 'column',
      gap: '0.5rem', justifyContent: 'center', padding: '2rem'
    }}>
      <Typography variant="h4" sx={{ fontFamily: 'Gilroy,sans-serif', fontWeight: 'bold' }}>Create Your Nametag Asset</Typography>

      {editMode && <form //onSubmit={handleSubmit}
        //   style={{display:'flex', flexDirection:'column', gap:'0.5rem',}} 
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          width: '80%',
          maxWidth: '500px',
          minWidth: '200px',
          margin: 'auto'
        }}>
        <Stack direction="row" spacing={1} alignItems="center" justifyContent='center'>
          <Typography>2 lines</Typography>
          <Switch defaultChecked inputProps={{ 'aria-label': 'ant design' }} onChange={(e) => { setMultiLine(!multiLine) }} checked={!multiLine} />
          <Typography>1 line</Typography>
        </Stack>

        <TextField type="text" name="name1" label="name1"
          autoComplete="off"
          value={name1} onChange={handleChange} />

        {multiLine && <TextField type="text" name="name2"
          autoComplete="off"
          label="name2" value={name2} onChange={handleChange}
        />}

        <Select
          // labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={fontOpen}
          onClose={handleFontClose}
          onOpen={handleFontOpen}
          defaultValue={0}
          min={0}
          label="font"
          name="font"

          sx={{ width: '80%', minWidth: '100px', margin: 'auto' }}
          onChange={(e) => handleFontChange(e)}

        >

          <MenuItem value={0}>
            {/* <em>0</em> */}
            font:
          </MenuItem>
          {fonts?.map((o, j) => {
            return (
              <MenuItem value={o} key={j} sx={{ flexDirection: 'column' }} >
                <em>{fontType === o ? '* ' : ''}{o}</em>

                <Divider />
              </MenuItem>
            )
          })

          }

        </Select>
      </form>}
      <canvas ref={canvasRef} width={1488} height={901} />
      <Stack sx={{ display: 'flex', flexDirection: 'row', gap: '0.5rem', margin: '1rem auto' }}>
        {/* <Button variant='outlined' type="submit" onClick={()=>setRefresh(!refresh)}>Refresh{isSubmitting?'ing':''}</Button> */}

        {/* <Button variant='outlined' 
              // disabled={disableCheck()}
              onClick={()=>{
                setBuildStep(1)
                
              }

              }>Refresh Nametag</Button> */}

        {!isSaved && hasChanged && <Button variant='outlined'
          disabled={isSaving}
          onClick={handleSave}>Save Nametag</Button>}
        {/* {isSaved &&  */}
        {/* } */}

      </Stack>
    </Box>
  );
};
export default CreateHMNIStickerAsset;
