import React, { useState, useEffect } from 'react';
import { useHMSStore, selectHLSState } from '@100mslive/react-sdk';
import { Typography, Box, Tooltip } from '@mui/material';
import AlarmIcon from '@mui/icons-material/Alarm';



function StreamDuration({ isPlaying, socketRef, startTime, isHost, showTime, setShowTime }) {
    const currentTime = Date.now()
    const [duration, setDuration] = useState(currentTime - startTime);
    const hlsState = useHMSStore(selectHLSState);
    // const [showTime, setShowTime] = useState(false)
    // console.log('[StreamDuration] hlsState:', hlsState)


    useEffect(() => {
        let interval;
        if (isPlaying) {
            interval = setInterval(() => {
                setDuration(prev => prev + 1);
            }, 1000);
        } else {

            setDuration(0);
        }
        return () => clearInterval(interval);
    }, [isPlaying, startTime]);

    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    // console.log('[StreamDuration] duration:', duration)
    // console.log('[StreamDuration] duration:', formatTime(duration))
    // useEffect(() => {
    //     let interval;
    //     if (hlsState.running) {
    //         interval = setInterval(() => {
    //             setDuration(prev => prev + 1);
    //         }, 1000);
    //     } else {
    //         setDuration(0);
    //     }
    //     return () => clearInterval(interval);
    // }, [hlsState.running]);



    return (
        <Tooltip title='Stream Duration'>
            <Box className="stream-duration" style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                alignContent: 'center',
                textAlign: 'center',
                //  justifyContent: 'center',
                gap: '0.5rem',
                width: '100%',
                height: '100%',
                cursor: 'pointer',

                // padding: '0.125rem'
            }}
                onClick={() => { setShowTime(!showTime) }}

            >
                <div>
                    <AlarmIcon sx={{
                        color: '#00FF00', alignSelf: 'center',
                        fontSize: showTime ? '2rem' : '1.5rem'
                    }} />

                </div>
                {showTime && <b style={{
                    color: '#00FF00', display: 'flex', fontSize: '1rem',
                    fontFamily: 'LCD2,sans-serif', alignSelf: 'center', width: '65px'
                }}>
                    {formatTime(duration)}
                </b>}
            </Box >
        </Tooltip>
    );
}

export default StreamDuration;