import ContactPhone from '@mui/icons-material/ContactPhone'
import { Container, Modal, Box, Button, Stack, Input, Typography, TextField } from '@mui/material'
import React, { useState, useEffect } from 'react'
import CloseButton from '../../../components/CloseButton'
import Copyright from '../../../components/Copyright';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Link, useParams } from 'react-router-dom';
import * as API from '../../../utils/APICalls'




const LockoutModal = (props) => {
    const emailParam = useParams().email
    const sharedByParams = useParams().trackId
    const { setLockoutModal, lockoutModal, checkForListener,
        isShare,//sharedById
        lock, listener, setListener } = props
    const [email, setEmail] = useState(emailParam && emailParam !== 'share' ? emailParam : '')

    const [pin, setPin] = useState('')
    const [actionError, setActionError] = useState('')
    const [hideCopy, setHideCopy] = useState(false)
    const [isLoading, setIsLoading] = useState(emailParam && emailParam !== 'share' ? true : false)
    const [isChecked, setIsChecked] = useState(false)
    const [sharedById, setSharedById] = useState(emailParam === 'share' ? sharedByParams : '')

    const colors = {
        main: '#858585',
        accent: '#000',
    }

    const handlePinVerification = async () => {
        setIsLoading(true)
        try {
            if (pin) {
                // window.location.assign(`/music/${email}`)
                const list = await API.verifyListener(listener, pin, sharedById)
                if (list) {
                    console.log('handlePinVerification:', list)
                    setListener(list)
                    localStorage.setItem('listener', JSON.stringify(list))
                } else { setActionError('Invalid pin') }
            } else { setActionError('Pin required') }
        } catch (err) { console.log(err) }
        setIsLoading(false)
    }

    useEffect(() => {
        const check = async () => {

            if (emailParam && !listener?._id) {
                console.log('[-------[ isChecked check ] -------------]:')
                setIsChecked(true)
                await checkForListener(emailParam?.trim())
                // setListener({
                //     email:emailParam
                // })
            } else { return }
        }
        console.log('sharedById', sharedByParams)
        console.log('window.location.pathname', window.location.pathname)
        console.log('emailParam', emailParam)
        if (!isChecked && !isShare && emailParam !== 'share') {

            check()
            // setInterval(() => {check()}, 5000);
        }
    }, [])

    // useEffect(()=>{
    //     const checkIfVerified = () =>{
    //         console.log('[checkIfVerified] listener:',listener)
    //     }
    //     checkIfVerified()
    // },[listener])
    return (
        <>
            <Modal
                // open={LockoutModal}
                open={listener?.isVerified ? false : true}
                onClose={() => setLockoutModal(!lockoutModal)}
            >
                <Container sx={{
                    zIndex: 999, fontFamily: 'Gilroy, sans-serif',
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#000',
                    // backgroundImage: 'url("/assets/BodyBG2.png")',
                    // backgroundImage:'url("/assets/Gradient_01_Frame2.png")',
                    backgroundColor: 'rgba( 0, 0, 0, 0.6)',
                    backgroundImage: 'url("/assets/BodyBG2.png")',
                    // backgroundImage:'url("/assets/Gradient_01_Frame2.png")',
                    backgroundAttachment: 'cover',
                    backgroundRepeat: 'no-repeat',
                    border: '2px solid #000',
                    boxShadow: 'inset 0px 0px 60px 40px rgba(4, 16, 42, 0.6)',
                    border: '2px solid #000',
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -70%)',
                        width: '100%',
                        height: '100%',

                        // backgroundColor:'#000',
                        // backgroundImage:'url("/assets/BodyBG2.png")',
                        // backgroundImage:'url("/assets/Gradient_01_Frame2.png")',
                        backgroundAttachment: 'cover',
                        backgroundRepeat: 'no-repeat',
                        borderRadius: '10px',
                        // border:'2px solid #000',
                        fontFamily: 'Gilroy, sans-serif',
                        justifyContent: 'center',
                        textAlign: 'center',
                        alignItems: 'center',
                        gap: '0.5rem',
                        padding: '0.5rem'

                    }}>
                        {/* <Box sx={{position:'absolute', top:'0.5rem', right:'0.5rem'}}>
                            <CloseButton func={setLockoutModal} val={LockoutModal}
                        
                            />
                        </Box> */}
                        {/* <h2 style={{color:'#FFF'}}>Welcome to</h2> */}
                        <Stack sx={{
                            display: 'flex', flexDirection: 'column', height: '270px',//width:'fit-content',
                            alignItem: 'center', marginBottom: '1rem',
                            position: 'relative', //border:'1px solid red'
                            width: '100%',
                            margin: 'auto'
                        }}>
                            <Box component={'img'}
                                className='lock-logo'
                                // src={`/assets/Rex_Entropy_Icon_Glow.png`}
                                src={`/assets/RexRecord.png`}

                                sx={{
                                    width: '100%',
                                    width: '300px',
                                    height: 'auto',
                                    margin: 'auto',
                                    // opacity:'0.5',
                                    // border:'3px solid rgba( 0,0 ,0,0.5)',
                                    // borderRadius:'50%',
                                    // backdropFilter:'blur(5px)',
                                    // backgroundColor:'#000',
                                    // boxShadow:'0 0 15px 5px rgba( 0,0, 0,0.2), inset 0 0 10px 3px rgba( 255, 255,255,0.3)',
                                    position: 'relative',
                                    zIndex: 0,
                                    // top:'0',
                                    // left:'50%',
                                    // transform:'translate(50%,0)'

                                }}
                            ></Box>

                            <div style={{
                                // position:'absolute',
                                // bottom:'1rem',
                                // left:'50%',
                                // transform:'translate(50%,0)',
                                // transform:'',
                                margin: 'auto',
                                transform: 'scale(2,2) translate(0%,-50px)',
                                display: 'flex', flexDirection: 'column', color: '#000',
                                backgroundColor: '#FFF', fontFamily: 'Gilroy,sans-serif',
                                width: 'fit-content',
                                boxShadow: '0 0 10px 4px rgba( 255, 255, 255, 0.3), inset 0 0 5px 1px rgba( 0, 0,0,0.3)',
                                border: '2px solid #000', borderRadius: '50px', padding: '0.5rem 1rem'
                            }}>
                                <b style={{ position: 'absolute', left: '2rem', top: '0.25rem' }}>
                                    The
                                </b>
                                <h1>RexPlay<img src='/assets/ContenterIcon.png' width='18rem' /></h1>
                                {/* <h1>RexPlayer</h1> */}
                                <Stack >
                                    {/* <span style={{fontSize:'0.7rem',textAlign:'left'}} >Interactive</span> */}
                                    <span style={{ fontSize: '0.5rem' }} >Streaming | Downloading | Interacting</span>
                                </Stack>
                            </div>
                        </Stack>

                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            // marginTop:'1rem',
                            marginBottom: '1rem',
                            // position:'absolute',
                            borderRadius: '10px',
                            border: '3px solid #000',
                            backgroundColor: 'rgba( 255,255,255,1)',
                            // backdropFilter:'blur(3px)',
                            boxShadow: '0px 0px 20px 4px rgba( 255,255,255,0.2)',
                            width: '300px',
                            minHeight: '200px',
                            padding: '1rem'
                            // left:'50%', top:'60%', 
                            // transform:'translate(-50%,0%)'
                        }}>

                            {!listener?.email && <Stack sx={{
                                gap: '0.75rem', width: '100%', justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <h4>Please enter your email to continue</h4>
                                <TextField sx={{ wdith: '90%' }}
                                    autoComplete='email'
                                    defaultValue={email}
                                    label='email'

                                    onChange={(e) => { setEmail(e.target.value.toLowerCase()) }} />
                                <Button variant='outlined'
                                    disabled={isLoading}
                                    onClick={async () => {
                                        if (email) {
                                            setIsLoading(true)
                                            // await checkForListener(email)
                                            if (isShare) {

                                            }
                                            window.location.replace(`/rexPlayer/${email}/${sharedById}`)
                                        } else { setActionError('Email required') }

                                    }}>
                                    {emailParam && emailParam !== 'share' ? 'loading...' : 'enter'}
                                </Button>
                                <Typography sx={{ fontSize: '0.7rem', marginTop: '0.5rem' }}>( Outside of verification,<b style={{ backgroundColor: 'yellow', }}> you wont receive anything</b> unless you subscribe...and then you're mine...unless you unsubscribe... )</Typography>
                            </Stack>}

                            {(listener?.email && !listener?.isVerified) && <Stack sx={{
                                gap: '0.5rem',
                                //  width:'100%', height:'50px',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <h2>Email Verification Pin</h2>
                                <h4>Please check your email for pin</h4>
                                <Typography variant='caption'>( 🤷 Possibly in spam 🤷 )</Typography>
                                <TextField
                                    label='pin'
                                    sx={{ wdith: '90%' }} onChange={(e) => { setPin(e.target.value) }} />

                                {actionError && <h3>{actionError}</h3>}
                                <Button variant='outlined' onClick={() => {
                                    handlePinVerification()

                                }}>
                                    submit
                                </Button>

                                {email && <Link onClick={async () => {
                                    try {
                                        const res = await API.fetchListener(email)
                                    } catch (err) { console.log(err) }
                                }}>click to resend email</Link>}
                            </Stack>

                            }

                        </Box>

                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'fixed',
                        bottom: '0',
                        left: '50%',
                        transform: 'translate(-50%,20%)',
                        width: '50%',
                        minWidth: '350px',
                        maxWidth: '450px',
                        height: hideCopy ? '0' : '8.5rem'
                    }}>

                        <Box sx={{ position: 'relative', alignSelf: 'start', width: '100%', }}
                            onClick={() => {
                                setHideCopy(!hideCopy)
                            }}>

                            {!hideCopy ?
                                <Box sx={{ position: 'absolute', left: '0.5rem', top: '0.5rem', zIndex: 999 }}>
                                    <CloseButton />
                                </Box>
                                : <Box sx={{
                                    display: 'flex',
                                    position: 'absolute',
                                    bottom: '-2px',
                                    left: '50%',
                                    transform: 'translate(-50%,0)',
                                    border: '2px solid #858585',
                                    padding: '0.25rem 1.25rem',
                                    backgroundColor: '#fff', cursor: 'pointer',
                                    alignItems: 'center',
                                    boxShadow: '0px -1px 7px 2px rgba(0,0,0,0.2)',
                                    borderRadius: '5px 5px 0px 0px'
                                }}>
                                    <ArrowDropUpIcon />
                                    about us
                                </Box>}
                        </Box>

                        {!hideCopy && <Copyright view='signupSheet'
                            hide={hideCopy}
                            color={colors.main}
                            shadow={true}
                        />}
                    </Box>
                </Container>
            </Modal>

        </>
    )
}

export default LockoutModal