import React, { useState, useEffect, useContext } from "react";
// import axios from "axios";
import QRCodeStyling, { gradientTypes } from "qr-code-styling";
import QRCodeForm from "./qrForm/QRCodeForm";
import Qr from "./QR";
import GeneratedQR from './GeneratedQR'
import ManagerQR from './ManagerQR'
import QR from './QR'
import { QRCodeContext } from "../context/QRCodeProvider";
import { AppContext } from "../context/AppProvider";
import { Container } from "@mui/material";
import CloseButton from "./CloseButton";
import QRFraming from "./QRFraming";



// const Default_QR = require('../options_02.json')
const Default_QR = require('../defaults/QRRough.json')



const CreateQR = (props) => {
  const view = props.view
  const { qrCodeData, setQRCodeData, updateQRCodeData,defaultQR } = useContext(QRCodeContext);
  const { appData, updateAppData,setPreviewQR, previewQR,selectedQR, setSelectedQR,
    modalOpen ,currentAsset, setCurrentAsset} = useContext(AppContext);
  const [ qrTop, setQrTop ] = useState(true)
  const data_default = 'https://qaura.pro/assets/00000001'
    
    

  useEffect(()=>{

        updateQRCodeData({})

    
  },[modalOpen])

  useEffect(()=>{
    if(!qrCodeData){

      if (view === 'create-QR'){
        // updateQRCodeData({})
        updateQRCodeData({...defaultQR})
      }
      else if(view === 'edit-QR'){
        // updateQRCodeData({})
          updateQRCodeData({...selectedQR})
        }
    }
      // setSelectedQR({...qrCodeData})
    
  },[])


  return (
    <Container style={{height:'100%',width:'100%',maxWidth: '800px', 
      minWidth:'375px',borderRadius: '10px', margin: 'auto',
      backgroundImage: "url('/assets/bodybg.png')",
      padding:'1rem',
        backgroundAttachment:'fixed',
        backgroundRepeat:'no-repeat',
        backgroundSize:'cover',flex:1}} >
         <div style={{position:'absolute', left:0,width:'100%', height:'3rem', backgroundColor:'#fff',marginBottom:'1rem'}}>

            <h2>{view === 'create-QR'?'Create':'Edit'} a QR</h2>
            <div style={{position:'absolute', top:'0.5rem', right:'0.5rem', zIndex:9999}}>
                <CloseButton func={updateAppData} view='dash' val={{
                            modalOpen:false,
                            modalView:''
                        }} />
            </div>
         </div>
      {/* <section className="QR-frame" 
      style={{ display: 'flex',position: 'relative', height:'40vh', 
      minHeight:'250px',zIndex: qrTop ? 1000 : 1, alignItems: 'center' }} 
        onClick={(e)=>{
          console.log('section click: ', e.target.style)
          setQrTop(true)}}>


          {!qrTop && <b className="blink-2" style={{fontSize:'0.85rem', width:'100%', position:'absolute',top:'0.5rem',left:'1.5rem', zIndex:999999}}>click to bring to front</b>}
        {/* <ManagerQR QR={view === 'edit-QR'? selectedQR : qrCodeData } view={view} key={view}/>  */}
        {/* <QR QR={view === 'edit-QR'? selectedQR : defaultQR } view={view} key={view}/>  */}
      {/* </section>  */}
      <section style={{position: 'relative',marginTop:'2rem',zIndex: qrTop ? 1 : 1000 }} onClick={()=>{setQrTop(false)}}>
          {qrTop && <b className="blink-2" style={{fontSize:'0.85rem', width:'100%', position:'absolute',bottom:'0.5rem',left:'1.5rem', zIndex:999999}}>click to bring to front</b>}
        
        <QRCodeForm  view={view} />
      </section>
    </Container>
  );
};

export default CreateQR;


  