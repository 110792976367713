import {
    Container, Modal, Box, Button,
    Input, Typography, TextField, Stack,
    TabPanel, TabList,
    Tab,
    Tabs,
    Tooltip, Pagination
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import PersonIcon from '@mui/icons-material/Person';

import CloseButton from '../../../components/CloseButton'
import RefreshIcon from '@mui/icons-material/Refresh';
import * as API from '../../../utils/APICalls'


const ViewersModal = (props) => {
    const {
        showViewers, setShowViewers,
        participantIds, isHost,
        setShareModalOpen, listener, setListener } = props
    const [walletView, setWalletView] = useState('viewers')
    const [shareView, setShareView] = useState('shares')
    const [viewers, setViewers] = useState(null)
    let participantCheck = false
    const handleButtonClick = (row) => {
        // Perform action with the row data
        console.log('Button clicked for row:', row);
    };

    const fetchParticipants = async () => {
        try {
            const response = await API.fetchListenersByIds(participantIds)
            // console.log('viewers response:', response)
            if (response) {
                setViewers(response)
                // console.log('viewers:', viewers)
            } else { return }
            participantCheck = true
        } catch (err) { console.log(err) }

    }

    const shareColumns = [
        {
            field: 'preferredName',
            headerName: 'Name',
            width: 120,
            // editable: true,
            // valueGetter: (params) => {
            renderCell: (params) => {
                return params?.value ? params?.value : params?.row?.email
            }
        },
        // { field: 'email', headerName: 'Email', width: 120 },
        {
            field: 'subscriptions',
            headerName: 'Status',
            width: 80,
            // editable: true,
            renderCell: (params) => {
                // if (params.value === 'resend') {
                // const title = params.value ? params.value[0].title : 'n/a'
                return params.value ? params.value[0].title : 'n/a'
            }

            //     return (
            //         <div style={{
            //             color: params.value !== 'resend' ? '#FF00FF' : '#00ff00', display: 'flex', fontSize: '0.85rem',
            //             alignContent: 'center',
            //             alignItems: 'center',
            //             fontFamily: 'LCD2', //className='blink'
            //             justifyContent: 'center'
            //         }}>
            //             {params.value}  <img style={{ alignSelf: 'center' }} className='coin-rotate'
            //                 src={'/assets/RexCoin.png'} width='20px' height='auto' />
            //         </div>
            //     )
            //     return params.value;
            // },
        },
        {
            field: 'message',
            headerName: 'Msg',
            width: 85,
            // valueGetter: (params) => {
            renderCell: (params) => {

                return (
                    <Tooltip title='Direct Message ( coming soon )'>

                        <Button
                            variant="contained"
                            color="success"
                            size="small"
                        // disabled={true}
                        // onClick={() => handleButtonClick(params.row)}
                        >
                            msg
                        </Button>
                    </Tooltip>
                );
            }
        },
        {
            field: 'customField',
            headerName: 'Gift',
            width: 85,
            // valueGetter: (params) => {
            renderCell: (params) => {

                return (
                    <Tooltip title='Send a Gift ( coming soon )'>

                        <Button
                            variant="contained"
                            color="success"
                            size="small"
                        // disabled={true}
                        // onClick={() => handleButtonClick(params.row)}
                        >
                            gift
                        </Button>
                    </Tooltip>
                );
            }
        },

    ];
    let fullList = []
    let arr = []
    let totalShares = []
    listener?.shares?.forEach((s, i) => {
        s.emails?.forEach((e, i) => {
            totalShares.push({
                email: e,
                date: s?.date,
            })
        })

    })
    //   console.log('totalShares',totalShares)
    // const shareRows = totalShares?.map((s, i) => {
    //     const isRedeemed = listener?.returned_shares?.find(rs => rs?.email === s?.email)
    //     const date = new Date(s?.date)
    //     let formatted_date = date.toLocaleDateString('en-US', {
    //         month: '2-digit',
    //         day: '2-digit',
    //         year: '2-digit'
    //     })
    //     const statusValue = isRedeemed ? isRedeemed?.value : 'resend'//<div style={{borders:'1px solid magenta', borderRadius:'5px'}}>resend</div>
    //     return {
    //         id: i + 1,
    //         email: s?.email,
    //         date: formatted_date,
    //         status: statusValue
    //     }
    // })


    const handleListenerRefresh = async () => {
        try {

            const data = await API.fetchListener(listener?.email)
            if (data) {
                setListener(data)
            } else {
                console.log('Error', data)
            }
        } catch (err) {
            console.log('[ handleListenerRefresh ]Error:', err)
        }

    }

    useEffect(() => {
        // if (!participantCheck) {
        if (participantIds?.length > 0 && !viewers) {

            fetchParticipants()
        }
    }, [])

    useEffect(() => {

    }, [viewers, participantIds])

    return (
        <>
            <Modal
                open={showViewers}
                onClose={() => setShowViewers(!showViewers)}
            >
                <Container sx={{ zIndex: 999 }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba( 0, 0, 0, 0)',
                        backdropFilter: 'blur(5px)',
                        borderRadius: '10px',
                        border: '2px solid #000',
                        fontFamily: 'Gilroy, sans-serif',
                        justifyContent: 'center',
                        textAlign: 'center',
                        alignItems: 'center',
                        gap: '0.5rem',
                        padding: '0.5rem',
                        color: '#FFF',
                        // minHeight: isHost ? '550px' : '520px',
                    }}>
                        <Box sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}>
                            <CloseButton func={setShowViewers} val={showViewers}
                            />
                        </Box>
                        <Box sx={{
                            position: 'relative', display: 'flex', flexDirection: 'column', background: '#000',
                            boxShadow: '0 0 10px rgba(0, 255, 0 ,0.5)',
                            overflow: 'hidden', overflowY: 'scroll',
                            width: '90%', maxWidth: '600px',
                            minWidth: '340px', minHeight: '500px', gap: '0.5rem', color: '#00FF00', borderRadius: '5px'
                        }}>
                            <Box sx={{ position: 'sticky', top: 0, left: 0, zIndex: 999999999, width: '100%', height: '1.5rem', background: '#F6F6F6' }}>

                                <Typography sx={{ display: 'flex', color: '#858585', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }} className='blink'>
                                    <PersonIcon /> <b style={{}}>Viewers</b> <PersonIcon />
                                </Typography>
                            </Box>


                            {/* <Box sx={{width:'100%',height:'100%',padding:'0.5rem',margin:'0'}}> */}
                            <Tooltip label='refresh'>
                                <RefreshIcon className='button-accent-hover'
                                    sx={{ position: 'absolute', left: '0.5rem', top: '2rem', cursor: 'pointer' }}
                                    onClick={() => {
                                        // handleListenerRefresh()
                                        fetchParticipants()
                                    }}
                                />
                            </Tooltip>

                            {/* <span style={{
                                color: '#00FF00', textAlign: 'center', width: '100%',// borderBottom:'1px solid #00FF00'

                            }} //className='blink'
                            >
                                <span style={{ color: 'magenta' }}>
                                    account:
                                </span>
                                &nbsp;
                                {listener?.email}
                            </span> */}


                            {isHost && <Box sx={{ borderBottom: 1, borderColor: '#00FF00', color: '#00FF00', margin: '1rem auto', }}>
                                <Tabs sx={{ display: 'flex', }}
                                    textColor="#00FF00"
                                    indicatorColor="#00FF00"
                                // onChange={(e)=>setWalletView(e.target.value)}
                                >
                                    <Tab label="Viewers"
                                        value="viewers"
                                        sx={{ color: walletView === 'viewers' ? 'magenta' : "#00FF00" }}
                                        onClick={(e) => setWalletView('viewers')}
                                    />
                                    <Tab label="Tracking"
                                        value="shares"
                                        sx={{ color: walletView === 'shares' ? 'magenta' : "#00FF00" }}
                                        onClick={(e) => setWalletView('shares')}
                                    />
                                    <Tab label={`promos ${listener?.wallet?.promos?.length ? listener?.wallet?.promos?.length : '0'}`}
                                        value='promos'
                                        sx={{ color: walletView === 'promos' ? 'magenta' : "#00FF00" }}
                                        onClick={(e) => setWalletView('promos')}
                                    />
                                </Tabs>
                            </Box>}
                            {walletView === 'shares' &&
                                <Box sx={{
                                    display: 'flex', borderBottom: 1, borderColor: '#00FF00', width: '100%',
                                    height: '100%',
                                    color: '#00FF00', margin: '0 auto',
                                    justifyContent: 'center',
                                    boxShadow: 'inset 0px -5px 5px 3px rgba(0, 255, 0 ,0.2)',
                                }}>
                                    <Tabs
                                        key='sub-tabs'
                                        sx={{
                                            display: 'flex', //margin:'auto', 
                                            // border:'1px solid red',
                                            fontSize: '0.5rem',
                                            // justifyContent:'center',
                                        }}
                                        textColor="#00FF00"
                                        indicatorColor="#00FF00"
                                    // onChange={(e)=>setWalletView(e.target.value)}
                                    >
                                        <Tab label={`Stats`} value='stats'
                                            sx={{ color: shareView === 'stats' ? 'magenta' : "#00FF00" }}
                                            onClick={(e) => setShareView('stats')}
                                        />
                                        <Tab label={`Shares`} value="shares"
                                            sx={{ color: shareView === 'shares' ? 'magenta' : "#00FF00" }}
                                            onClick={(e) => setShareView('shares')}
                                        />
                                        <Tab label="Gifts" value="gifts"
                                            sx={{ color: shareView === 'gifts' ? 'magenta' : "#00FF00" }}
                                            onClick={(e) => setShareView('gifts')}
                                        />
                                        {/* <Tab label="Plays" value="all" 
                                    sx={{color:shareView === 'all'? 'magenta':"#00FF00"}}
                                    onClick={(e)=>setShareView('all')}
                                    /> */}
                                    </Tabs>
                                </Box>

                            }

                            {/* <TabPanel value="coins"> */}



                            <Stack
                                sx={{
                                    position: 'relative',
                                    margin: '0 auto', width: '85%', minHeight: '300px',
                                    // height: '90%',
                                    maxHeight: '500px',
                                    marginBottom: '0.5rem',
                                    // backgroundColor:'rgba(255,255,255,0.3)', 
                                    backdropFilter: 'blur(2px)',
                                    padding: '0.5rem', justifyContent: 'center',
                                    borderRadius: '10px', gap: '0.5rem',
                                    // alignItems:'center',
                                    // alignContent:'center', 
                                    border: '2px solid #00FF00',
                                    boxShadow: '0px 0px 15px 5px rgba(0, 255, 0 ,0.5),inset 0px 0px 5px 3px rgba(0, 255, 0 ,0.2)',
                                    marginTop: !isHost ? '2.5rem' : ''
                                }}>



                                <Box sx={{
                                    height: '100%', width: '100%', color: '#00FF00',

                                }}>
                                    {viewers && <DataGrid
                                        rows={viewers}
                                        getRowId={(row) => viewers?.indexOf(row)}
                                        columns={shareColumns}
                                        initialState={{
                                            pagination: {
                                                paginationModel: {
                                                    pageSize: 10,
                                                    // color:'#00ff00'
                                                },
                                            },
                                        }}
                                        components={{
                                            Pagination: (props) => (
                                                <Pagination {...props} className="custom-pagination" />
                                            ),
                                        }}
                                        getRowClassName={() => 'share-row-text-color'}
                                        pageSizeOptions={[5]}
                                        // checkboxSelection
                                        disableRowSelectionOnClick
                                        sx={{
                                            '& .MuiTablePagination-root': {
                                                color: '#FF00FF', // Main text color
                                            },
                                            '& .MuiTablePagination-selectLabel': {
                                                color: '#00ff00', // "Rows per page" label color
                                            },
                                            '& .MuiTablePagination-select': {
                                                color: '#00FF00', // Dropdown text color
                                            },
                                            '& .MuiTablePagination-displayedRows': {
                                                color: '#FF00FF', // Displayed rows text color
                                            },
                                        }}
                                    />}
                                </Box>


                            </Stack>

                        </Box>
                    </Box>
                </Container>
            </Modal>

        </>
    )
}

export default ViewersModal