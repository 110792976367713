import { Box, Button, Divider, Stack, Typography } from '@mui/material'
import React,{useContext, useEffect, useState} from 'react'
import { AppContext } from "../../context/AppProvider";
import { StorefrontContext, StorefrontProvider }  from "../../context/StorefrontProvider";
import * as API from '../../utils/APICalls'
import * as helpers from '../../utils/helpers'

const CartReview = (props) => {
    const ship_keyList = ['name','address','address2',
        'city','state', 'zip','email','phone',]
    const bill_keyList = ['name','address','address2',
        'city','state', 'zip','email','phone',]

         const { modalOpen, setModalOpen,cart,setCart,
        order, view, setView, shop, setShop, currentOrder,
        storefrontData, setStorefrontData, currentProduct,
        items, setItems,owner, setOwner,cartOpen, setCartOpen,
        subView,cartView,setCartView
    } = useContext(StorefrontContext);

    useEffect(()=>{
        
    },[cart])
  return (
    <Stack sx={{gap:'0.5rem', fontFamily:'Gliroy, sans-serif'}}
    >
    <Typography><b>Shipping</b></Typography>
    <Divider />
    {cart?.shipping_addresses[0] && 
        Object.keys(cart?.shipping_addresses[0])?.map((key,i)=>{
            if(ship_keyList.includes(key) ){
                return(
                    <span key={i}>
                        <b>
                            {helpers.splitKey(key,'_',' ').toUpperCase()[0] + key.substring(1)}: 
                        </b>
                        <Typography>
                            {cart?.shipping_addresses[0][key]}
                        </Typography>
                    </span>
                )
            }
        })}
         <Button
            fullWidth
            variant="contained"
            sx={{display:'flex',margin:'auto', mt: 3, mb: 2,  }}
            onClick={()=>{
                setCartView('shipping')
            }}
                >
            Back To Shipping
        </Button>
    <Typography><b>Billing</b></Typography>
<Divider />
    {cart?.billing_info && 
        Object.keys(cart?.billing_info)?.map((key,i)=>{
            if(bill_keyList.includes(key) ){
                return(
                    <span key={i}>
                        <b>
                            {helpers.splitKey(key,'_',' ').toUpperCase()[0] + key.substring(1)}: 
                        </b>
                        <Typography>
                            {cart?.billing_info[key]}
                        </Typography>
                    </span>
                )
            }
        })}

     <Button
            fullWidth
            variant="contained"
            sx={{display:'flex',margin:'auto', mt: 3, mb: 2,  }}
            onClick={()=>{
                setCartView('billing')
            }}
                >
            Back To Billing
        </Button>
    </Stack>
  )
}

export default CartReview