
import React, {useState, useContext, useEffect } from "react";
import { AppContext } from "../context/AppProvider";
import * as API from '../utils/APICalls'
import { Container, Stack } from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CloseButton from "./CloseButton";
import MapComponent from "./Map";
import MapContainer from "./MapContainer";

ChartJS.register(ArcElement, Tooltip, Legend);



const SingleMetric = () => {
    const { appData,updateAppData,userData,currentScanSet,  setCurrentScanSet,
        scans, setScans,modalOpen, follows, setFollows, QRs,setModalOpen ,
        scanHeaders, setScanHeaders } = useContext(AppContext);
   
    const [ isMap, setIsMap ] = useState()
    const [ data, setData ] = useState()
      const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

   const getGeoArrayByKey = (array,key) =>{
        const uniqueArray = [...new Set(array?.map(s => s?.geolocation[key]))];
        // console.log("uniqueArray:",uniqueArray)
        return uniqueArray;
    }
    
    const countryList = getGeoArrayByKey(currentScanSet,'country_name')
    const stateList = getGeoArrayByKey(currentScanSet,'state')
    const cityList = getGeoArrayByKey(currentScanSet,'city')
    const zipList = getGeoArrayByKey(currentScanSet,'postal')
    const ipList = getGeoArrayByKey(currentScanSet,'IPv4')

  const handleSelection =async (val) => {
   console.log('Selection:',val)
    if(val !== 'gps'){ setIsMap(false)}
    if(val === 'country' ) await setData({...geoDataObj(countryList,currentScanSet,'country')});
    if(val === 'state' ) await setData({...geoDataObj(stateList,currentScanSet,'state')});
    if(val === 'city' ) await setData({...geoDataObj(cityList,currentScanSet,'city')});
    if(val === 'postal' ) await setData({...geoDataObj(zipList,currentScanSet,'postal')});
    // if(val === 'unique IPs' ) await setData({...geoDataObj(ipList,currentScanSet,'IPv4')});
    if(val === 'gps' ){
        setIsMap(true) 
        // await setData()
        ;}
    if(val === 'unique IPs' ) await setData({...geoDataObj(ipList,currentScanSet,'IPv4')});;
  };


   
    const geoElementCount = (arr,key,el)=>{
        let narr = []
        arr?.forEach((e,i)=>{
            if(e?.geolocation[key] === el)narr.push(el)
        })
    
        return narr.length
    }

 /*Uniqe Scans
 -Array of scan objects unique IP address
 */   

/*Cities
-returns an a count of each time element appeans in array
*/  

/*Devices
-the count of each type of device 
*/     

    const valueArrayByKey = (keyArray,arr,key) =>{
        let countArray = []
        keyArray?.forEach((c,i)=>{
            countArray.push(geoElementCount(arr,key,c))
        })

        console.log('countArray: ',countArray)
        return countArray
    }


    const createSets = (arr,field,val)=>{
        const arrSet = arr?.filter(s=> {return s[field] === val})
        console.log('createArrSet: ',arrSet)
        return arrSet
    }

    const dataBGs = [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ]
    const dataBorders = [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ]
  
    const stateData = {
        labels: stateList,//['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [
            {
            label: '# of Scans',
            data: valueArrayByKey(stateList, currentScanSet,'state'),
            backgroundColor: dataBGs,
            borderColor: dataBorders,
            borderWidth: 1,
            },
        ],
     };

    const cityData = {
        labels: cityList,//['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [
            {
            label: '# of Scans',
            data: valueArrayByKey(cityList, currentScanSet,'city'),
            backgroundColor: dataBGs,
            borderColor: dataBorders,
            borderWidth: 1,
            },
        ],
     };

    const zipData = {
        labels: zipList,//['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [
            {
            label: '# of Scans',
            data: valueArrayByKey(zipList, currentScanSet,'postal'),
            backgroundColor: dataBGs,
            borderColor: dataBorders,
            borderWidth: 1,
            },
        ],
     };

     const geoDataObj = (list,arr,key)=>{
        console.log("[geoDataObj] list:",list)
        return ({
             labels: list,//['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [
            {
            label: '# of Scans',
            data: valueArrayByKey(list, arr,key),
            backgroundColor: dataBGs,
            borderColor: dataBorders,
            borderWidth: 1,
            },
        ],
        })
     }
     const menuItems=['country','state','city','postal','gps','unique IPs']
useEffect(()=>{},[data])
      return (
    <Container sx={{minHeight:'300px',padding:'0.5rem'}} >
         <span style={{display: 'flex', flexDirection:'row',
        // position:'absolute',left:'1rem',top:'0.75rem', 
        textAlign:'center',cursor: 'pointer',gap:'0.125rem',whiteSpace:'nowrap'}}
            onClick={()=>{
                updateAppData({
                    modalOpen:!modalOpen,
                    modalView:'metrics'
                })
            }}
            >
           {/* {currentQR &&  */}
           <img //className={`rot180` }
           src={`/assets/arrow-left.svg`} style={{height:'1.5rem', alignSelf: 'center',opacity:'1'}} alt="Back to Scans"/>
           {/* } */}
        </span>
        <Stack sx={{display:'flex', flexDirection:'row', gap:'0.5rem',
             alignItems:'center',justifyContent:'space-evenly',marginBottom:'0.5rem'}}>

        <b>
         {currentScanSet[0]?.qr_name}
         </b>  <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="outlined"
      >
        Insights by...
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {menuItems?.map((el,i)=>{
            return(

                <MenuItem onClick={()=>{
                    handleSelection(el)
                    handleClose()
                }
                } key={i}>{el}</MenuItem>
            )
        })}
        {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem> */}
      </Menu>
    </div>
      <div style={{position:'absolute', top:'0.5rem', right:'0.5rem'}}>
            <CloseButton func={updateAppData} val={{
                    modalOpen:!modalOpen,
                    modalView:''
                }} />
        </div>
        </Stack>
  
         {(data && !isMap) &&<Doughnut data={data} />}
         {/* {(isMap) &&<MapComponent />} */}
         {( isMap || !data)&& <MapContainer/>}
         {/* {( isMap && !dat) && <MapContainer/>} */}

         {/* <Doughnut data={cityData} /> */}
         {/* {currentScanSet?.map((e,i)=>{
            return(
                <div>
                    {e?.geolocation?.city}
                </div>
            )
         })}
        */}
    </Container>
  )
}

export default SingleMetric