export const brands = ['facebook', 'instagram', 'linkedin', 'upwork', 'github', 'gitlab', 'twitter', 'bandcamp', 'soundcloud', 'spotify', 'youtube', 'vimeo', 'amazon', 'tidal', 'youTube',
  'airbnb', 'uber', 'lyft', 'stackoverflow', 'stackexchange', 'pinterest', 'skype', 'etsy', 'codepen', 'dropbox', 'googleplay', 'imdb', 'npm', 'meetup', 'itunes', 'cashApp', 'venmo', 'paypal', 'pandora', 'deezer', 'linktree', 'xbox', 'playstation', 'steam', 'snapchat', 'slack', 'kickstarter', 'patreon', 'stripe',
  'discord', 'wordpress', 'tiktok', 'whatsapp',
  'windows', 'apple', 'shopify', 'twitch', 'tumblr', 'trello', 'unity', 'wikipedia'
]

export const generateRandomHex = (len) => {
  // Create an array of hexadecimal characters
  const characters = 'ABCDEF0123456789';
  // Create an empty string to store the hex string
  let hexString = '';
  // Generate 6 random characters and add them to the hex string
  for (let i = 0; i < len; i++) {
    hexString += characters[Math.floor(Math.random() * characters.length)];
  }

  // Return the hex string
  return hexString;
}
export const formatDateToLocal = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleString(); // Formats the date and time according to the user's locale
};

export function validateEmail(email) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

export const getDisplayDate = (date) => {
  const now = new Date();
  const createdAt = new Date(date);

  if (isNaN(createdAt.getTime())) {
    return 'Invalid date';
  }

  const diffInMs = now - createdAt;
  const diffInSecs = Math.floor(diffInMs / 1000);
  const diffInMins = Math.floor(diffInMs / (1000 * 60));
  const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

  if (diffInSecs < 60) {
    return `${diffInSecs} secs ago`;
  } else if (diffInMins < 60) {
    return `${diffInMins} mins ago`;
  } else if (diffInHours < 24) {
    return `${diffInHours} hrs ago`;
  } else if (diffInDays < 7) {
    return `${diffInDays} days ago`;
  } else {
    return createdAt.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
  }
};

export const secsToMins = (duration) => {
  let mins = duration / 60
  let secs = duration
  mins = mins?.toString()?.split(".")[0]
  return `${duration > 60 ? mins : '00'}:${duration % 60 < 10 ? '0' + Math.round(duration % 60) : Math.round(duration % 60)}`
}

export const splitKey = (string, sym, rep) => {
  return string.split(sym).join(rep)
}

export const itemNameFilter = (item, term) => {
  return item?.name?.includes(term);
}

export const itemFieldFilter = (item, field, term) => {
  console.log('item:', item)
  const field_value = item[field?.toString()]
  if (field_value) {

    if (item[field]?.includes(term))
      return true
    else return false
  }
  else return false
}

export const QauRaTagFilter = ({ text, string = 'QauRa' }) => {
  if (!text?.includes(string)) {
    return text;
  }

  const parts = text?.split(new RegExp(`(\\b${string}\\b)`, 'gi'));

  return (
    <>
      {parts.map((part, index) =>
        part?.toLowerCase() === string?.toLowerCase() ? (
          <b key={index}>
            <a
              href="https://qaura.pro"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              {part}
            </a>
          </b>
        ) : (
          part
        )
      )}
    </>
  );
};


export const orderColorCheck = (status) => {
  switch (status) {

    case 'draft':
      return 'grey'
      break;
    case 'cart':
      return 'orange'
      break;
    case 'complete':
      return 'green'
      break;
    case 'hold':
      return 'red'
      break;
    case 'processing':
      return 'orange'
      break;
    case 'printing':
      return 'blue'
      break;
    case 'fulfilled':
      return 'green'
      break;
    case 'shipped':
      return 'limegreen'
      break;
  }
}


export function hasValue(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== undefined && obj[key] !== null && obj[key] !== '') {
      return true;
    }
  }
  return false;
}

export const returnTrackTitle = (track) => {
  return `${track?.subtitle ? track?.name + ' ' + track?.subtitle : track?.name}`
}

export const dataURLToBlob = (dataURL) => {
  const byteString = atob(dataURL.split(',')[1]);
  const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
}

//  const totalTrackPlays = (track) =>{
//         let playCount = 0
//         if (track && track.listeners && Array.isArray(track.listeners)) {
//             playCount = track.listeners.reduce((total, listener) => {
//                 if (listener.plays && Array.isArray(listener.plays)) {
//                     return total + listener.plays.length
//                 }
//                 return total;
//             }, 0);
//         }
//         return playCount
//     }

// const trackCompletionPercentage = (track) => {
//     if (!track || !track.listeners || !Array.isArray(track.listeners) || !track.duration) {
//         return 0; // or some default value
//     }

//     let playDuration = track.listeners.reduce((total, listener) => {
//         if (listener.plays && Array.isArray(listener.plays)) {
//             return total + listener.plays.reduce((sum, play) => {
//                 return sum + (typeof play.length === 'number' ? play.length : 0);
//             }, 0);
//         }
//         return total;
//     }, 0);

//     const totalPlays = totalTrackPlays(track); // Assuming this function exists and works correctly

//     if (totalPlays === 0 || track.duration === 0) {
//         return 0; // or some default value
//     }

//     return (playDuration / totalPlays / track.duration) * 100;
// };

//     const trackShopRanking = (track) =>{
//         let ranking = 0
//         if (track && albums && Array.isArray(albums)) {
//             ranking = track.listeners.reduce((total, listener) => {
//                 if (listener.plays && Array.isArray(listener.plays)) {
//                     return total + listener.plays.length
//                 }
//                 return total;
//             }, 0);
//         }
//         trackCompletionPercentage(track) / totalTrackPlays(track)
//         return ranking
//     }
