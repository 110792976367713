import { Avatar, Box, Container, Modal, Typography } from '@mui/material'
import React, { useState, useContext, useEffect } from 'react'
import Header from './storefrontComponents/Header'
import AboutSection from './storefrontComponents/AboutSection'
import Products from './storefrontComponents/Products'
import Copyright from '../components/Copyright'
import CartModal from './storefrontComponents/CartModal'
import InterestWidget from './storefrontComponents/InterestWidget'

import { StorefrontContext, StorefrontProvider }  from "../context/StorefrontProvider";
import { QRCodeContext } from "../context/QRCodeProvider";

import CollectionView from './CollectionView'
import AboutView from './AboutView'
import ContactView from './ContactView'

import { useParams } from "react-router-dom";
import * as API from '../utils/APICalls'
import ProductView from './storefrontComponents/ProductView'
import ReviewSection from './storefrontComponents/ReviewSection'
import { HowItWorks } from './storefrontComponents/HowItWorks'
import BuildShirt from './storefrontComponents/BuildShirt'

import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PreviousViewButton from './storefrontComponents/PreviousViewButton'
import CheckoutModal from './storefrontComponents/CheckoutModal'
import CompleteOrder from './storefrontComponents/CompleteOrder'
import CollectNametagText from './storefrontComponents/CollectNametagText';
import ShopShareModal from './storefrontComponents/ShopShareModal'
import ClosedModal from '../components/ClosedModal'




const Storefront = (props) => {
    let param_id =  useParams()?.id?.toString()
    let email =  useParams()?.email?.toString()
    let product_id =  useParams()?.product_id?.toString()
    const _id = param_id ? param_id : props.id
    const { isRexPlayer } = props
    // const [cartOpen, setCartOpen ] = useState(true)
   
    // const [ modalOpen, setModalOpen ] = useState(false)
    // const [cart, setCart ] = useState(false)
    const { modalOpen, setModalOpen,cart,setCart,createCart,
         generateOrder,createOrder,selectedSize,
        setCustomer, closedModalOpen, setClosedModalOpen ,
        order, view, setView, shop, setShop, currentOrder,
        storefrontData, setStorefrontData, currentProduct,
        setCurrentProduct,framingOpen,setFramingOpen,
        items, setItems,owner, setOwner,cartOpen, setCartOpen,
        subView, shopShareModalOpen
    } = useContext(StorefrontContext);
        const [ initialProductViewed, setInitialProductViewed ] = useState(false)
        const { qrCodeData, updateQRCodeData } = useContext(QRCodeContext);
    // const [closedModalOpen, setClosedModalOpen ] = useState(false)

    const fetchStorefront = async (id) =>{
        try{
            console.log('fetchStorefront() :',_id)
            const data = await API.fetchShop({_id})
            if (data) {
                await setShop(data)
                // await setItems(data?.items)
                await setItems(data?.items?.filter((item)=> item?.isActive === true))
                await setOwner(data?.owner)
                await setStorefrontData({...storefrontData, shop,items,owner})
                console.log('fetchStorefront() :',data)
            }
        }catch(err){console.log(err)}
    }
   
     const handleAdd = async(obj)=>{
      // e.preventDefault()
      try{
        if( !cart?._id ){
          await createCart(currentProduct,'merch')
        }else{
            await generateOrder(null,shop,currentProduct,selectedSize)
           
          }
          // }else{console.log('[Not Reset 🤷]')}
        }catch(err){console.log('[handleAdd] err:',err)}
      }

    const layout = [
        {name: 'header',component:<Header conf={{...shop?.config}} shop={shop} isRexPlayer={isRexPlayer}/>},
        {name: 'about',component:<AboutSection conf={{...shop?.config}}/>},
        {name: 'spread',component:<CollectionView conf={{...shop?.config}} shop={shop} items={shop?.items?.filter((item)=> item?.isActive == true)} collection={shop?.collections?.filter((collection)=> collection?._id?.toString() === shop?.config?.featured_collection)[0]} isFeatured={true}/>},
        {name: 'Products',component:<Products conf={{...shop?.config}} items={shop?.items?.filter((item)=> item?.isActive == true)} setModalOpen={setModalOpen} 
        cartOpen={cartOpen}/>},
        {name: 'reviews',component:<ReviewSection conf={{...shop?.config}} items={shop?.items?.filter((item)=> item?.isActive == true)} 
        setModalOpen={setModalOpen} view={'shop'}/>},
        {name: 'spread',component:<CollectionView conf={{...shop?.config}} shop={shop} items={shop?.items?.filter((item)=> item?.isActive == true)} collection={shop?.collections?.filter((collection)=> collection?._id?.toString() === shop?.config?.highlight_collection)[0]}/>},
    ]
    useEffect(() =>{
        if( !shop){
            fetchStorefront()
        }
    },[shop])
    useEffect(() =>{
        const findProduct = async ()=>{
            try{
                if(product_id){
                // console.log('[Product_id to be viewed]',product_id)
                let prd = await items?.find(item=>item?._id?.toString() == product_id?.toString())
                // console.log('[Product to be viewed]',prd)
                if(prd){
                    //  console.log('[Product to be viewed]',prd)
                     await setCurrentProduct(prd)
                     await setView('product')
                     setInitialProductViewed(true)
                    //  setStorefrontData({
                    //      ...storefrontData,
                    //      currentProduct,
                    //      view
                    //  })
                 }
                }
            }catch(err){console.log(err)}
        }
        if(!initialProductViewed){
            findProduct()
        }else{return}
    },[currentProduct,items])

    useEffect(() =>{
   
    },[storefrontData,qrCodeData,currentOrder])
    useEffect(() =>{
   
    },[currentProduct])

    
  return (
    <Container sx={{
        display:'flex',
        flexDirection:'column',
        position:'relative',
        padding:'0.5rem',
        width: '100%',
        // minWidth: '350px'
        }}>
            <div id="scroll-container" style={{paddingTop:'0.25rem', backgroundColor:'rgba(0, 0, 0, 0.9)',
            borderBottom:'4px ridge rgba(255,247,123,0.7)', //boxShadow:'inset 0px 0px 5px 3px rgba(255,255,255,0.5)', 
            boxShadow:`0px -4px 10px 5px rgba(0, 0, 0, 0.5),inset 0px 0px 10px 4px rgba(255,247,123,${0.3})`,
            borderTop:'2px ridge rgba(255,247,123,0.7)', marginTop:'0.25rem', backdropFilter:'blur(10px)', transition:'2s ease-in-out'}}>  
        <div id={!modalOpen?"scroll-text":''} style={{padding:'0',textAlign:'center'}}>
            <Typography sx={{fontSize:'0.75rem',color:"#fff", whiteSpace:'no-wrap'}}// dangerouslySetInnerHTML={{__html:scrollComp}}>
                >
               Welcome! Be sure to check back or subscribe for updates!
            {/* <img src='/assets/MedMan_Flat.png' height='20rem' width={'auto'} style={{background:' rgb(255,255,255)',
                background: 'radial-gradient(circle, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.3) 35%, rgba(255,255,255,0) 81%)'}}/> */}
                </Typography>
        </div>
        </div>
              {/* <InterestWidget ModalOpen={cartOpen} 
                setModalOpen={setModalOpen} modalView={'cart'}  
                subView={'cart'}
                cart={cart} shop={shop} 
                setCart={setCart}
                />
             */}
              <BuildShirt ModalOpen={modalOpen} 
                setModalOpen={setModalOpen} modalView={'cart'}  
                subView={'build'}
                item={currentProduct}
                cart={cart} shop={shop} 
                setCart={setCart}
                />
                
                <CheckoutModal 
                    cartOpen={cartOpen} 
                    setCartOpen={setCartOpen} 
                    modalView={'cart'}  
                    subView={'cart'}
                    cart={cart} shop={shop} setCart={setCart}
                />
                
                <ClosedModal
                    closedModalOpen={closedModalOpen} 
                    setClosedModalOpen={setClosedModalOpen} 
                    shop={shop} 
                    setShop={setShop} 
                />
                <ShopShareModal
                    setShareModalOpen={shopShareModalOpen} 
                    shareModalOpen={shopShareModalOpen} 
                    // validateEmail={validateEmail}
                    // modalView={'cart'}  
                    // subView={'cart'}
                    cart={cart} shop={shop} setCart={setCart}
                />
                {/* <Avatar sx={{ 
                        position: 'absolute',
                        padding:'0.5rem',
                        left:'50%',top:'33%',
                        transform: 'translate(-50%,-50%)',
                        bgcolor: '#868686', 
                        width:'150px', 
                        height:'150px',
                        border:'3px solid #000',
                        // marginBottom:'-1rem', 
                        zIndex:9999,
                        backgroundColor:'#fff',
                        borderRadius:'10px',
                        boxShadow:'0px 0px 15px 5px rgba(0,0,0,0.8)'}} 
                        src={config?.avatar_pic}>
                        </Avatar> */}
            <Box sx={{
                display:'flex', 
                flexDirection:'column',
                marginBottom:'0.75rem', 
                zIndex:0,
                gap:'0.5rem'}}>
                
        {view === 'home' && layout?.map((e) => {
            return (
                e?.component
            )
        }
        )}
        {view !== 'home' &&<Header conf={{...shop?.config}} shop={shop} email={email} isRexPlayer={isRexPlayer} />}
        {/* {view !== 'home' && <ArrowBackIcon style={{fontSize:'2rem',position:'absolute',left:'2rem',top: shop?.config?.headerHeight, marginTop:'3.25rem'}}/>} */}
        {(view !== 'home' && !isRexPlayer) && <PreviousViewButton config={shop?.config} isRexPlayer={isRexPlayer}/>}
        {view === 'collection' && <CollectionView conf={{...shop?.config}} shop={shop} isRexPlayer={isRexPlayer}  
            items={shop?.items?.filter((item)=> item?.isActive == true)} collection={shop?.collections?.filter((collection)=> collection?.name?.toString() === subView)[0]}/>}
        {view === 'thank you' && <CompleteOrder isRexPlayer={isRexPlayer}/>}
        {view === 'about' && <AboutView isRexPlayer={isRexPlayer}/>}
        {view === 'how it works' && <HowItWorks isRexPlayer={isRexPlayer}/>}
        {view === 'contact' && <ContactView isRexPlayer={isRexPlayer}/>}
        {view === 'product' && <ProductView isRexPlayer={isRexPlayer} />}
            </Box>
        <Modal 
            open={framingOpen} 
            onClose={()=>setFramingOpen(false)}
            >
              <Container 
              className='center'
              sx={{p:1, 
                fontFamily:'Gilroy, sans-serif'
              // width:'80%', height:'60%',
              }}>
               <Box sx={{ position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%',
                    height:'fit-content',
                    minHeight:'50vh',
                    overflow: 'hidden',
                    overflowY:'scroll',
                    maxHeight:'70vh',
                    // maxHeight:'90vh',
                    maxWidth:'60vw',
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    borderRadius:'10px',
                    boxShadow: 24,
                    p: 1,}}>
                        <CloseIcon onClick={()=>setFramingOpen(false)}  color='#000'sx={{position:'absolute',right:'0.5rem',top:'0.5rem'}}/>
                    <h1>Enter Name Tag Text</h1>
          
                    <CollectNametagText view='order' //qr_pic={currentOrder?.qr_pic } 
                      handleAdd={handleAdd}
                      createCart={createCart}
                      generateOrder={generateOrder}
                      item={currentProduct}
                      // setFramingOpen={setFramingModalOpen}
                      />
                    {/* <CreateHMNIStickerAsset view='order' qr_pic={currentOrder?.qr_pic } setFramingOpen={setFramingModalOpen}/> */}
                
             </Box>
             </Container>
             </Modal>
        <Copyright view='signupSheet' />
    </Container>
  )
}

export default Storefront

// const shop = {
    //     owner:'65f056671267a2925b3f6354', 
    //     admin:['65f056671267a2925b3f6354'], 
    //     employees:['65f056671267a2925b3f6354'], 
    //     operating_name:'Parallaxr', 
    //     owner_email:'aj@rexentropy.com', 
    //     support_email:'parallaxr.brand@rexentropy.com', 
    //     campaingName:'Parallaxr.Shop',
    //     items: ['0','1','2'],
    //     collections: [
    //         {
    //             name:'basics',
    //             items:['1']
    //         },
    //         {
    //             name:'unique QR',
    //             items:['1']
    //         },
    //         {   
    //             name:'personalized QR',
    //             items:['0','2']
    //         },
    //     ],
    //     subscribers:['aj@rexentropy.com']

    // }
    

    // const items = [
    //     {
    //         _id:'1',
    //         name:'Porthole To My Heart', 
    //         subtitle:'w/ dynamic QR code', 
    //         description: 'Filler product information-station of generation elation and pereceptual dialation, rooted in straight up syncapation...and stuff.',
    //         images: ['https://res.cloudinary.com/dzxzdsnha/image/upload/v1714336713/Shirt-MedMan_Porthole-Front-Angled-Left_rksqqa.png'], 
    //         price:'$35.00',
    //         details: ['1st detail','Yet another detail','& once more a detail'],
    //         tags: ['tshirt','unisex','uniqueQR']
    //     },
    //     {
    //         _id:'0',
    //         name:'The Gridwalker', 
    //         subtitle:'w/ dynamic QR code & customizable name tag', description: 'Filler product information-station of generation elation and pereceptual dialation, rooted in straight up syncapation...and stuff.',images: ['https://res.cloudinary.com/dzxzdsnha/image/upload/v1714338172/Shirt-Gridwalker-Front-Angled-Left_Fit_hewpmn.png'], 
    //         price:'$35.00',
    //         details: ['1st detail','Yet another detail','& once more a detail'],
    //         tags: ['tshirt','unisex','uniqueQR','nameTag']
    //     },
    //     {
    //         _id:'2',
    //         name:'Sage', 
    //         subtitle:'w/ dynamic QR code & customizable name tag', description: 'Filler product information-station of generation elation and pereceptual dialation, rooted in straight up syncapation...and stuff.',images: ['https://res.cloudinary.com/dzxzdsnha/image/upload/v1714337622/Shirt-Sage-Front-Angled-Left_Fit_wcxvpd.png'], 
    //         price:'$35.00',
    //         details:['1st detail','Yet another detail','& once more a detail'],
    //         tags:['tshirt','unisex','uniqueQR','nameTag']
    //     },
       
    // ]
    // const config = {
    //     headerHeight: '25vh',
    //     header_pic: 'https://res.cloudinary.com/dzxzdsnha/image/upload/v1714322177/ShirtHeaderImage-Crop.jpg',
    //     bgYPos:'0',
    //     avatar_pic: 'https://res.cloudinary.com/dzxzdsnha/image/upload/v1713992003/h7gqpgoknqs8ijweazub.jpg',
    //     aboutHeight: '25vh',
    //     tagline: '( par·al·lax·er )',
    //     aboutContent: `<div style="position:relative; padding:0.5rem"> <img style="float: left; width: 1.5rem; height:auto; left: 1rem;" src= 'https://res.cloudinary.com/dzxzdsnha/image/upload/v1698192201/Parallaxr_Distressed_White_BG_kvsoiz.png' /> brand focused on actively seeing and embracing, through multiple perspectives.<br/><br/> e.g., <em>Such as it may appear, and those that may exist.<em/></div>`,
    //     productsHeight: 'fit-content',
    //  "featured_items:":[],
    // "featured_collection":""
    // }