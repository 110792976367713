import React, { useState, useContext, useEffect } from 'react'
import { AppContext } from "../context/AppProvider";
import useWindowDimensions from "../hooks/windowDimensions";
import ThemeColorPicker from './ThemeColorPicker';
import { Container } from '@mui/material';
import CloseButton from './CloseButton';

const EditUserInfo = (props) => {
   const { userData, appTheme,updateAppData} = useContext(AppContext);
    const { height, width } = useWindowDimensions();
   const [showThemePicker, setShowThemePicker ] = useState(true)

  return (
     <Container 
    //  className=''
        style={{display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                minHeight:'13rem',
                // minWidth: '170px',
                borderRadius:'10px',
                backgroundColor:'rgba(256,256,256,0.3)',
                backdropFilter: 'blur(5px)',
                // boxShadow: '0px 0px 10px 5px rgba(250, 250, 250, 0.4)',
                boxShadow: '0px 0px 5px 2px rgba(0, 0, 0, 0.3)',
                border:'3px solid #fff',
                padding:'0.5rem',
                overflow:'hidden',
                alignItems:'center',
                justifyContent:'center'
                    // margin:'1rem 1rem 1rem 1rem'

                // margin:'1rem 1rem 0rem 0.25rem'
        }}>
          <div style={{position:'absolute',top:'0.25rem', right:'0.25rem'}}>

             <CloseButton func={updateAppData} view='dash' val={{
                            modalOpen:false,
                            modalView:''
                        }} />
          </div>
            
            <div style={{display:'flex', flexDirection:'column', justifyContent:'center',textAlign:'center',gap:'0.5rem'}}>
                <p>edit user info</p>
                <h6>(coming soon 🤫)</h6>
                
                <div style={{display:'flex', 
                  flexDirection: width<=600?'column':'row', gap: '0.5rem',justifyContent:'space-between', alignItems:'center', alignSelf:'center'}}>

                <img src={userData?.pic}
                      style={{width:'5rem', borderRadius:'10px'}}
                    />
                <ul style={{
                      display:'flex',
                      flexDirection:'column',
                      listStyle:'none',
                      justifyContent: 'left',
                      textAlign: 'left', 
                      gap:'0.5rem'}}>
                {userData && Object.keys(userData).map((k,i)=>{
                // if( k !== '_id' && k !=='createdAt' && k !== 'updatedAt' && k !=='__v' && k !=='token'&&  k !=='isAdmin' &&  k !=='isOnline'){
                if( k === 'firstName' || k === 'lastName' ||k === 'preferredName' || k === 'email' || k === 'phone'){

                  return(<>
                    {k!=='pic' && <li style={{cursor:'pointer',fontSize:'0.75rem'}} key={i}>
                     <b> {k} </b> :{userData[k]}
                    </li>
                    
                    }
                  </>
                  )
                }
                })}
                </ul>
                <div style={{display:'flex', flexDirection: width>=600?'column':'column', gap:'0.25rem',margin:'0.5rem auto'}}>

                <b>theme:</b>
                <ThemeColorPicker />
                </div>
              
                 {/* {!showThemePicker && <div style={{
              zIndex:9999,
            // position:'relative',
            // bottom:'0.25rem',
            right:'1.25rem',
            // borderLeft:`1px solid ${appTheme?.TXcolorMain}`,
            paddingLeft:'0.5rem'}}>
            

         
          <span style={{
            display:'flex', 
            flexDirection:'row',
            color:appTheme?.TXcolorAccent,
            alignItems: 'center',
            fontSize:'0.75rem',
            gap:'0.5rem',
            cursor: 'pointer',
            }}
            onClick={()=>{
                setShowThemePicker(!showThemePicker)
            }}
            >
            <img 
                src={`/assets/${showThemePicker?'close':'arrow-down'}.svg`} 
                style={{height:showThemePicker?'0.5rem':'0.25rem'}}/>
                 theme
           </span>
        </div>} */}
        
        {/* {showThemePicker && <div style={{
            position:'relative',
            display:'flex',
            flexDirection:'row',
            gap:'0.5rem',
            // bottom:'0.25rem',
            // right:'1.25rem',
            // borderLeft:`1px solid ${appTheme?.TXcolorMain}`,
            paddingLeft:'0.5rem'}}>
            

         
          <span style={{
            display:'flex', 
            flexDirection:'row',
            color:appTheme?.TXcolorAccent,
            alignItems: 'center',
            fontSize:'0.75rem',
            gap:'0.5rem',
            cursor: 'pointer',
             zIndex:9999,
            }}
            onClick={()=>{
                setShowThemePicker(!showThemePicker)
            }}
            >
            <img 
                src={`/assets/${showThemePicker?'close':'arrow-down'}.svg`} 
                style={{height:showThemePicker?'0.5rem':'0.25rem',
                
                }}/>
           </span> */}
                </div>

            </div>
    </Container>
  )
}

export default EditUserInfo