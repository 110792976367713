import { Container, Modal,Box, Button,
     Input, Typography, TextField, Stack, 
     TabPanel,TabList,
     Tab,
     Tabs,
     Tooltip,Pagination
    } from '@mui/material'
import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';

import CloseButton from '../components/CloseButton'
import RefreshIcon from '@mui/icons-material/Refresh';
import * as API from '../utils/APICalls'
import * as helpers from '../utils/helpers'



const CartDataGrid = (props) => {
    const {carts,handleCartSelect} = props 
    // const handleCartSelect = props.handleCartSelect
    const shareColumns = [
        { field: '_id', headerName: 'id', minWidth: 2 },
        { field: 'customer?.email', headerName: 'Email', minWidth: 120,maxWidth:200 },
      {
        field: 'createdAt',
        headerName: 'Date',
        width: 80,

        // editable: true,
        renderCell: (params) => {
    const date = new Date(params.value)
    let formatted_date = date.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: '2-digit'
            })
        return formatted_date
          
        },
      },
      {
        field: 'items',
        headerName: 'items',
        minWidth: 60,
        maxWidth: 70,
        renderCell: (params) => {
   
          return  `${params?.value?.length}`
          
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 60,
        renderCell:(params) =>{
            return(
                <span  style={{color: helpers.orderColorCheck(params.value)}}
                >
                    {params?.value}
                </span>
            )
        }
        // renderCell: (params) => {
        // //   if (params.value === 'comp') {
        //     return (
        //       <Button
        //         variant="contained"
        //         color="primary"
        //         size="small"
        //         // onClick={() => handleButtonClick(params.row)}
        //       >
        //         review
        //       </Button>
        //     );
        //   } 
    
        //   return (
        //     <div style={{ color: params.value !== 'resend' ? '#FF00FF' : '#00ff00', display:'flex',fontSize:'0.85rem',
        //             alignContent:'center',
        //             alignItems:'center',
        //             fontFamily:'LCD2', //className='blink'
        //             justifyContent:'center'
        //             }}>
        //       {params.value}  <img style={{ alignSelf:'center'}} className='coin-rotate'
        //                  src={'/assets/RexCoin.png'} width='20px' height='auto'/>
        //     </div>
        //   )
        //   return params.value;
        },
      {
        // field: 'isComplete',
        headerName: 'Action',
        width: 80,
        // editable: true,
        renderCell: (params) => {
        //   if (params.value === 'resend') {
            return (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                    console.log('row:',params.row)
                    const obj = params.row
                    console.log('obj:',obj)
                    handleCartSelect(obj)}}
              >
                review
              </Button>
            );
          } 
    
      },


      ];
    console.log('carts:', carts)
    return (
    <>
        <DataGrid
            rows={carts}
            columns={shareColumns}
             getRowId={(row) => row._id}
             
            initialState={{
               sorting: {
              sortModel: [{ field: 'createdAt', sort: 'desc' }],
            },
            pagination: {
                    paginationModel: {
                    pageSize: 15,
                    // color:'#00ff00'
                    },
                },
                }}
                components={{
                Pagination: (props) => (
                <Pagination {...props} className="custom-pagination" />
                ),}}
            // getRowClassName={() => 'share-row-text-color'}
            pageSizeOptions={[5]}
            // checkboxSelection
            disableRowSelectionOnClick
            // sx={{
            //     '& .MuiTablePagination-root': {
            //         color: '#FF00FF', // Main text color
            //     },
            //     '& .MuiTablePagination-selectLabel': {
            //         color: '#00ff00', // "Rows per page" label color
            //     },
            //     '& .MuiTablePagination-select': {
            //         color: '#00FF00', // Dropdown text color
            //     },
            //     '& .MuiTablePagination-displayedRows': {
            //         color: '#FF00FF', // Displayed rows text color
            //     },
            //     }}
            />
    </>
  )
}

export default CartDataGrid