import { Container, Modal, Box, Button, Input, Typography, TextField, Stack, IconButton, InputAdornment, Chip, FormControlLabel, Slider, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CloseButton from '../../../components/CloseButton'
import * as API from '../../../utils/APICalls'

const CommentModal = (props) => {
    const { setCommentModalOpen, commentModalOpen, item,
        playAudio, playAlbum, timestamp, trkNum,
        validateEmail, listener, setListener } = props

    const [comment, setComment] = useState('')
    const [actionError, setActionError] = useState('')

    const inputStyles = {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#00FF00',
                boxShadow: '0px 0px 5px 3px rgba(0, 255, 0 ,0.5),inset 0px 0px 3px 1px rgba(0, 255, 0 ,0.2)',
            },
            '&:hover fieldset': {
                borderColor: '#00FF00',
                boxShadow: '0px 0px 5px 3px rgba(0, 255, 0 ,0.5),inset 0px 0px 3px 1px rgba(0, 255, 0 ,0.2)',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#00FF00',
                boxShadow: '0px 0px 5px 3px rgba(0, 255, 0 ,0.5),inset 0px 0px 3px 1px rgba(0, 255, 0 ,0.2)'
            },
        },
        '& .MuiInputLabel-root': {
            color: '#FF00FF',
            boxShadow: '0px 0px 5px 3px rgba(0, 0, 0 ,0.5),inset 0px 0px 5px 3px rgba(0,0, 0 ,0.5)',
            background: '#000'
        },
        '& .MuiInputBase-input': {
            color: '#00FF00',
        },

    }
    const handleCommentSave = async () => {
        try {

            const comOBJ = {
                track: playAlbum?._id,
                listener: listener?._id,
                name: listener?.name ? listener?.name : 'Anonymous',
                time: timestamp,
                comment: comment,
            }
            const resp = await API.saveComment({
                _id: playAudio?._id,
                comment: comOBJ
            })
            if (resp) {
                console.log('Comment saved!')
                setComment('')
                setActionError('')
            }
        } catch (err) {
            setActionError('Error saving comment')
            console.log(err)
        }
        setCommentModalOpen(false)
    }

    const secsToMins = (duration) => {
        let mins = duration / 60
        let secs = duration
        mins = mins?.toString()?.split(".")[0]
        return `${duration > 60 ? mins : '00'} : ${duration % 60 < 10 ? '0' + Math.round(duration % 60) : Math.round(duration % 60)}`
    }

    useEffect(() => {

        // console.log('[Comment Modal]:', playAudio)
    }, [playAlbum, playAudio])

    return (
        <>
            <Modal
                open={commentModalOpen}
                // open={shareModalOpen}
                onClose={() => setCommentModalOpen(false)}
            >
                {/* <Container sx={{ zIndex: 999 }}> */}
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    maxWidth: '400px',
                    height: '375px',
                    backgroundColor: 'rgba( 0, 0, 0,0.7)',
                    backdropFilter: ' blur(5px)',
                    borderRadius: '5px',
                    border: '2px solid #000',
                    fontFamily: 'Gilroy, sans-serif',
                    justifyContent: 'center',
                    textAlign: 'center',
                    // alignItems: 'center',
                    gap: '0.5rem',
                    // padding: '0.5rem'

                }}>

                    <Box sx={{ position: 'absolute', top: '-1rem', right: '0', zIndex: 99999999 }}>
                        <CloseButton func={setCommentModalOpen} value={commentModalOpen}
                        />
                    </Box>
                    <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '1.5rem', background: '#F6F6F6' }}>
                        <Typography sx={{
                            color: '#858585', //fontSize: '0.85rem'
                        }}>Leave a Comment!</Typography>
                    </Box>
                    <Stack sx={{
                        position: 'relative', gap: '0.5rem',
                        width: '100%', color: '#00FF00'
                    }}>
                        {/* <h3 style={{fontWeight:'bold'}}>
                           Leave a comment!
                            </h3>  */}

                        {/* <FormControlLabel
                         sx={{display:'flex', flexDirection:'row', margin:'auto', gap:'0.5rem'}}
                          control={
                             <CheckBox 
                                checked={updatePreferredName}
                                onClick={()=>{
                                    setUpdatePreferredName(!updatePreferredName)
                                    console.log('Check click',updatePreferredName )
                                }}/>
                         }label='update preferred name'  /> */}
                        <Stack sx={{

                            textAlign: 'left', gap: '0.5rem', margin: 'auto', width: '95%'
                        }}>

                            <span><b style={{ color: '#FF00FF' }}>Track: &nbsp;</b>{playAlbum ? `${playAlbum?.artist} - ${playAudio?.subtitle ? playAudio?.name + ' ' + playAudio?.subtitle : playAudio?.name}` : `Select a track!`}</span>
                            <span><b style={{ color: '#FF00FF' }}>Timestamp: &nbsp;</b><span style={{ fontFamily: "LCD2,sans-serif", }}>{secsToMins(timestamp)}</span></span>
                            <Slider
                                aria-label="time-indicator"
                                size="small"
                                value={timestamp}
                                min={0}
                                step={1}
                                max={playAudio?.duration}
                                // onChange={(_, value) => setPosition(value)}
                                sx={(t) => ({
                                    color: 'rgba(0,255,0,0.87)',
                                    height: 4,
                                    '& .MuiSlider-thumb': {
                                        width: 8,
                                        height: 8,
                                        transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                                        '&::before': {
                                            boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
                                        },
                                        '&:hover, &.Mui-focusVisible': {
                                            boxShadow: `0px 0px 0px 8px ${'rgb(0 0 0 / 16%)'}`,
                                            // ...t.applyStyles('dark', {
                                            //     boxShadow: `0px 0px 0px 8px ${'rgb(255 255 255 / 16%)'}`,
                                            // }),
                                        },
                                        '&.Mui-active': {
                                            width: 20,
                                            height: 20,
                                        },
                                    },
                                    '& .MuiSlider-rail': {
                                        opacity: 0.28,
                                    },
                                    // ...t.applyStyles('dark', {
                                    //     color: '#fff',
                                    // }),
                                })}
                            />
                        </Stack>

                        <TextField
                            required
                            sx={{ ...inputStyles, width: '95%', margin: 'auto', color: '#00FF00', border: '1px solid #00FF00' }}
                            multiline={true}
                            rows={4}
                            label='Comment'
                            // defaultValue={'leave a comment'}
                            onChange={(e) => { setComment(e.target.value) }}
                        />


                        {actionError ? actionError : ''}
                        <Tooltip title='submit'>
                            <Button variant='outlined'
                                sx={{ position: 'fixed', bottom: '0.5rem', right: '0.5rem', color: '#FF00FF' }}
                                onClick={() => {
                                    if (comment) {
                                        handleCommentSave()
                                    } else {
                                        setActionError('Please enter comment to share!')
                                    }
                                }}
                            >
                                {listener?.preferredName ? `-${listener?.preferredName}` : 'save'}
                            </Button>
                        </Tooltip>
                    </Stack>


                </Box>

                {/* </Container> */}
            </Modal>

        </>
    )
}

export default CommentModal