import React,{ useEffect, useRef, useState, useContext } from  'react'
import { AppBar, Button, CardMedia, Container, Divider, List, ListItem, ListItemIcon, ListItemText, Modal, Stack, Tooltip } from '@mui/material';

import Box from '@mui/material/Box';

import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import CustomerInfoForm from '../../components/CustomerInfoForm';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import * as API from '../../utils/APICalls'
import { StorefrontContext, StorefrontProvider }  from "../../context/StorefrontProvider";
import { AppContext }  from "../../context/AppProvider";
import CustomerBilling from './CustomerBilling';
import CustomerShipping from './CustomerShipping';
import CartReview from './CartReview';
import Payment from '../../components/Payment';



const CheckoutModal = (props) => {
       const { modalOpen, setModalOpen,//cart,
        setCart,
        order, view, setView, shop, setShop,cart,
        storefrontData, setStorefrontData, currentProduct,
        items, setItems,owner, setOwner,cartOpen, setCartOpen,
        // cartView, setCartView ,
        cartView, 
        setCartView,
        isFollower
    } = useContext(StorefrontContext);
       const {  setActionError, actionError } = useContext(AppContext);

    // const cartOpen = props.cartOpen
    // const setCartOpen = props.setCartOpen
    // var cart = props.cart
    // var cart = props.cart
    // var setCart = props.setCart
    // const shop = props.shop
    const [ modalView, setModalView ] = useState(props.modalView)
    const [ cartItems, setCartItems ] = useState(cart?.items? cart?.items :[])
    // const [ cartView, setCartView ] = useState('cart')


    const handleCheckout = async  () =>{
        console.log('[Checkout Click] cartView:', cartView)
        console.log('[Checkout Click] cart:', cart)
        console.log('[Checkout Click] shop:', storefrontData)
  await API.updateCart(cart)
        if(isFollower){
            let temp = shop?.subscribers
            temp.push(cart?.billing_info?.email)
            const res = await API.updateShop({
                ...shop,
                subscribers: temp
            })
        }
        if(cartView ==='cart'){
            setCartView('shipping')
        }
        else if(cartView ==='shipping'){
            setCartView('billing')
        }
       else  if(cartView ==='billing'){
            setCartView('review')
        }
        else if(cartView ==='review' && cart?.billing_info && cart?.shipping_addresses.length >0){

            try{
                console.log('[CheckoutModal] pre-send Cart:',cart)

                const res = await API.sendCart({
                    cart:{
                        ...cart,
                        isComplete:true, 
                        status:'pending'
                    },
                    shop: shop
                    
                })
                if(res){
                    console.log('[send cart]:',res)
                    setCart({})
                    handleCartClose()
                    setView('thank you')
                    setStorefrontData({
                        ...storefrontData,
                        cart
                    })
                }
            }catch(err){console.log(err)}
        }
    }
    // const locCart = localStorage.getItem('cart')
    // let cart =[]
    // if (locCart) cart = JSON.parse(locCart)
    
    const handleRemoveClick = async (arr,obj,i) => {
        const index = arr.indexOf(obj)
        
        try{
            if(index > -1){
                    arr.splice(index,1)
                    // setCartView('cart')
                    console.log('[Remove Click]:',arr)
                    // cart = arr

                    await setCartItems(arr)
                    await setCart({
                        ...cart,
                        items:arr
                    })
                    const {res} = await API.updateCart({...cart,items:arr})
                    if (res){
                        await API.deleteOrder(obj)
                        localStorage.setItem('cart',JSON.stringify(res))
                        await setStorefrontData({
                            ...storefrontData,
                            cart: res
                        })
                        // await setCartItems(cart)
                    }
                    console.log('[Remove Click] cartItems:',cartItems)
                }
       
        //    if(resp){
        //     console.log('[Remove Click] deleteResponse:',resp)
        //    }
        }catch(err){
             setActionError('Error removing from cart')
                        arr?.push(obj)
                        setCart({
                            ...cart,
                            items:arr})
                        setCartItems(arr)
                        await setStorefrontData({
                            ...storefrontData,
                            cart:cart
                        })
            console.log('Error removing from cart',err)
        
        }
    };
    
    const handleCartClose = () => {
        setCartOpen(false);
        // setModalView('')
        // setCartView('cart')
    };

    const cartTotal = () =>{
        let total = 0
        // if (cart){
            cart?.items?.forEach((e,i)=>{
                total = ( 1* e?.price ) + total
            })
            // console.log('[Cart Total]:', total)
         return total
        // } else {return}

    }

    useEffect(()=>{
        //  if (locCart) cart = JSON.parse(locCart)
        // console.log('[Cart Modal] cart :', cart)
        // if (!cartItems || !cartItems[0]){
        //     setCartItems(cart?.items)
        // }
        if ( cartItems !== cart?.items ){
            setCartItems(cart?.items)
        }
      setActionError('')
    },[cart?.items,storefrontData,cartItems ])
    useEffect(()=>{
        //  if (locCart) cart = JSON.parse(locCart)
        //   setCartItems(cart?.items)
        console.log('[Cart Modal] cartItems :', cart?.items)
    },[cartItems ])
    
    useEffect(()=>{
        setActionError('')
    },[])

    // if(init_cart !== cart){
            // setCartView('checkout')
            // .then()
            // setCartView('cart')
        // }
  return (
      <Modal
        open={cartOpen}
        onClose={handleCartClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
                <Container sx={{p:1}}>

                <Box sx={{ position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    height:'90%',
                    minHeight:'60vh',
                    overflow: 'hidden',
                    overflowY:'scroll',
                    maxHeight:'90vh',
                    // maxHeight:'90vh',
                    maxWidth:'98vw',
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    borderRadius:'10px',
                    boxShadow: 24,
                    p: 1,}}>
                        <CloseIcon onClick={handleCartClose}  color='#000'sx={{position:'absolute',right:'0.5rem',top:'0.5rem'}}/>
                   {/* <Stack direction="row" spacing={1} alignItems="center" justifyContent='center'>
                    <Typography>Map</Typography>
                    <Switch defaultChecked inputProps={{ 'aria-label': 'ant design' }} onChange={(e)=>{
                        if(modalView ==='map'){
                            setModalView('menu')
                        }
                        if(modalView ==='menu'){
                            setModalView('map')
                        }
                    }} checked={modalView ==='menu'} />
                    <Typography>Menu</Typography>
                </Stack> */}
                <Container >

                </Container>
              {(props.modalView ==='cart' ) && (  
                    <Box sx={{p:1}}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" style={{fontFamily:'Sofia',fontWeight:'bold'}}>
                           Cart
                        </Typography>
                        {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                          
                        </Typography> */}
                        {/* <Box sx={{border:'1px solid #000'}}> */}
                        
                        <Box sx={{display:'flex',flexDirection:'column',border:'1px solid #000',borderRadius:'10px',
                            overflow:'hidden',overflowY:'scroll',minHeight:'100%', height:'100%',p:1}}>
                            {cartItems?.length>0 && (cartView !== 'shipping' && cartView !== 'billing') && cartItems?.map((p,i)=>{
                                return( 
                                    <Box sx={{position:'relative',display:'flex', flexDirection:'column',p:1, gap:'0.5rem'}} key={i}>
                                        <RemoveCircleIcon className='button-woddle'
                                            sx={{cursor:'pointer'}}
                                            onClick={(e)=>{handleRemoveClick(cartItems,p,i)}}/>
                                        <Stack sx={{display:'flex', flexDirection:'row',fontSize:'1rem', justifyContent:'baseline', gap: '0.5rem'}}>
                                            <img src={p?.proof}  width='50px'height='auto'/>
                                            <b style={{width:'30%',maxWidth:'150px'}}>{p?.itemName}</b>
                                            <Stack sx={{display:'flex',flexDirection:'row', flex:1,justifyContent:'space-between'}}>
                                             
                                              <span style={{width:'30%', maxWidth:'100px'}}>
                                                &nbsp;( {p?.size?.toUpperCase()} ) 
                                                </span>  
                                                <b style={{width:'30%',maxWidth:'50px',textAlign:'center'}}> 

                                                {/* {p.quantity} */}
                                                </b>
                                                <span style={{width:'30%',maxWidth:'50px',textAlign:'center'}} >
                                                ${p?.price}
                                                </span>
                                                &nbsp;=&nbsp;
                                                
                                                <span style={{width:'30%',maxWidth:'50px',textAlign:'center'}}>
                                                 ${p?.price }
                                                </span>
                                            </Stack>
                                        </Stack>
                                        <Divider />
                                    </Box>
                                    )
                                })
                            }
                       {cartTotal(cart)>0 ?
                        <Typography sx={{margin:'auto'}}><b>Total:</b>&nbsp;${cartTotal(cart)}</Typography>
                      :<Typography sx={{margin:'auto'}}>
                            Cart currently empty
                            </Typography> } 
                  
                        {

                        cartView === 'shipping' &&
                        <Container sx={{display:'flex', flexDirection:'column',
                            justifyContent:'center'
                        }}>
                             <Typography id="modal-modal-title" variant="h6" component="h2" style={{fontFamily:'Sofia',fontWeight:'bold'}}>
                          Shipping Information
                        </Typography>
                     
                            <CustomerShipping shop={shop} 
                                cart={cart}
                                setCartView={setCartView} 
                                cartView={cartView}/>

                        </Container>
                     }

                     {
                        cartView === 'billing' &&
                        <Container sx={{display:'flex', flexDirection:'column',
                            justifyContent:'center'
                        }}>
                             <Typography id="modal-modal-title" variant="h6" component="h2" style={{fontFamily:'Sofia',fontWeight:'bold'}}>
                                Billing Information
                                </Typography>
                     
                            {/* <CustomerBilling shop={shop} 
                                cart={cart}
                                setCartView={setCartView} 
                                cartView={cartView}/> */}

                            <Payment shop={shop} 
                                cart={cart}
                                setCartView={setCartView} 
                                cartView={cartView}
                                type='merch'
                                />
                  
                        </Container>
                     }
                     {
                        cartView === 'review' &&
                        <Container sx={{display:'flex', flexDirection:'column',
                            justifyContent:'center'
                        }}>
                             <Typography id="modal-modal-title" variant="h6" component="h2" style={{fontFamily:'Sofia',fontWeight:'bold'}}>
                          Review
                        </Typography>
                     
                            <CartReview shop={shop} 
                                cart={cart}
                                setCartView={setCartView} 
                                cartView={cartView}/>
                  
                        </Container>
                     }
                        </Box> 
                        {/* </Box> */}

                            {actionError &&<Box sx={{margin:'0.5rem auto',textAlign:'center'}}><b>{actionError}</b></Box>}
                        
                           {/* {(cartView ==='review' || cartView ==='cart' ) && */}
                            <Button variant='contained' disabled={cartTotal(cart)<=0}
                            sx={{display:'flex',position:'relative',margin:'1rem auto'}}
                            onClick={()=>{
                            //     if (!cart?.shipping_addresses[0] ){
                            //         setCartView('shipping')
                            //     }
                            //    else if(cart?.shipping_addresses[0] && cart?.billing_info){
                                    handleCheckout()
                                // }
                            }}
                            >
                            {cartView !=='shipping' && cartView !=='billing' &&
                            cartView !=='review'
                             ?  'Checkout': ''}
                                
                            {/* {cartView ==='cart' ? 'Checkout' :''} */}
                            {cartView ==='shipping' ?  'Billing': ''}
                            {cartView ==='billing' ?  'Review' :' '}
                            {cartView ==='review' ?  'Submit Order' :''}
                        </Button>
                        {/* }  */}
                        </Box>
                     )}
                     
                     
                </Box>
                </Container>
            </Modal>
  )
}

export default CheckoutModal