export const vertexshader = ` vec3 mod289(vec3 x)
      {
        return x - floor(x * (1.0 / 289.0)) * 289.0;
      }

      vec4 mod289(vec4 x)
      {
        return x - floor(x * (1.0 / 289.0)) * 289.0;
      }

      vec4 permute(vec4 x)
      {
        return mod289(((x*34.0)+10.0)*x);
      }

      vec4 taylorInvSqrt(vec4 r)
      {
        return 1.79284291400159 - 0.85373472095314 * r;
      }

      vec3 fade(vec3 t) {
        return t*t*t*(t*(t*6.0-15.0)+10.0);
      }

      float pnoise(vec3 P, vec3 rep)
      {
        vec3 Pi0 = mod(floor(P), rep); // Integer part, modulo period
        vec3 Pi1 = mod(Pi0 + vec3(1.0), rep); // Integer part + 1, mod period
        Pi0 = mod289(Pi0);
        Pi1 = mod289(Pi1);
        vec3 Pf0 = fract(P); // Fractional part for interpolation
        vec3 Pf1 = Pf0 - vec3(1.0); // Fractional part - 1.0
        vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
        vec4 iy = vec4(Pi0.yy, Pi1.yy);
        vec4 iz0 = Pi0.zzzz;
        vec4 iz1 = Pi1.zzzz;

        vec4 ixy = permute(permute(ix) + iy);
        vec4 ixy0 = permute(ixy + iz0);
        vec4 ixy1 = permute(ixy + iz1);

        vec4 gx0 = ixy0 * (1.0 / 7.0);
        vec4 gy0 = fract(floor(gx0) * (1.0 / 7.0)) - 0.5;
        gx0 = fract(gx0);
        vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
        vec4 sz0 = step(gz0, vec4(0.0));
        gx0 -= sz0 * (step(0.0, gx0) - 0.5);
        gy0 -= sz0 * (step(0.0, gy0) - 0.5);

        vec4 gx1 = ixy1 * (1.0 / 7.0);
        vec4 gy1 = fract(floor(gx1) * (1.0 / 7.0)) - 0.5;
        gx1 = fract(gx1);
        vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
        vec4 sz1 = step(gz1, vec4(0.0));
        gx1 -= sz1 * (step(0.0, gx1) - 0.5);
        gy1 -= sz1 * (step(0.0, gy1) - 0.5);

        vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
        vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
        vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
        vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
        vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
        vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
        vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
        vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);

        vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
        g000 *= norm0.x;
        g010 *= norm0.y;
        g100 *= norm0.z;
        g110 *= norm0.w;
        vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
        g001 *= norm1.x;
        g011 *= norm1.y;
        g101 *= norm1.z;
        g111 *= norm1.w;

        float n000 = dot(g000, Pf0);
        float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
        float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
        float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
        float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
        float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
        float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
        float n111 = dot(g111, Pf1);

        vec3 fade_xyz = fade(Pf0);
        vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
        vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
        float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x);
        return 2.2 * n_xyz;
      }
        uniform float u_frequency;

      uniform float u_time;
      void main() {
        float noise = 2.0 * pnoise(position + u_time, vec3(10.));
         float displacement = (u_frequency / 30.) * (noise / 10.);

        vec3 newPosition = position + normal * displacement;

        gl_Position = projectionMatrix * modelViewMatrix * vec4(newPosition, 1.0);
      }`

export const fragmentshader = `uniform float u_red;
uniform vec2 u_resolution;
  uniform float u_blue;
  uniform float u_green;    
  void main() {
    vec2 st = gl_FragCoord.xy /u_resolution;
            gl_FragColor = vec4(vec3(u_red   ,u_green  , u_blue), 1.0 );
            }`

export const fragmentshader2 = `
uniform vec2 u_resolution;
 
  void main() {
    vec2 st = gl_FragCoord.xy /u_resolution;
            gl_FragColor = vec4(vec3(st.x   , st.y ,1.0), 1.0 );
            }`
// gl_FragColor = vec4(vec3(u_red , u_green , u_blue), 1. );

export const GlitchShader = {

    vertex: `
    vec3 lerp (vec3 a, vec3 b, float t) {
        return (1. - t) * a + t * b;
    }
    float lerp (float a, float b, float t) {
        return (1. - t) * a + t * b;
    }
    float noise_hash_alt(vec3 p)
    {
        p = fract(p * 0.3183099 + .1);
        p *= 17.0;
        return fract(p.x * p.y * p.z * (p.x + p.y + p.z));
    }

    float noise_alt (vec3 x)
    {
        vec3 p = floor(x);
        vec3 f = fract(x);
        f = f * f * (3. - 2. * f);

        return lerp(lerp(lerp(noise_hash_alt(p), noise_hash_alt(p + vec3(1.0, 0, 0)), f.x),
            lerp(noise_hash_alt(p + vec3(0, 1.0, 0)), noise_hash_alt(p + vec3(1.0, 1.0, 0)), f.x), f.y),
            lerp(lerp(noise_hash_alt(p + vec3(0, 0, 1.0)), noise_hash_alt(p + vec3(1.0, 0, 1.0)), f.x),
                lerp(noise_hash_alt(p + vec3(0, 1.0, 1.0)), noise_hash_alt(p + vec3(1.0, 1.0, 1.0)), f.x), f.y), f.z);
    }


      varying float glitchColorAmt;
      uniform float time;
      uniform vec3 mousePos;
      void main() {
        float glitchNoise = noise_alt(position.xyz + 10. * vec3(sin(time), cos(time), -sin(time))) - 0.5;
        vec3 newPos = position;
        float radialFalloff = 2.- min(length(mousePos - newPos)/.3, 2.);
        glitchColorAmt = step(fract(sin(0.1 * time) + 2. * cos(0.1 * time)), 0.03);
        glitchColorAmt = max(radialFalloff, glitchColorAmt);
        newPos.x += glitchColorAmt * glitchNoise;
        gl_Position = projectionMatrix * modelViewMatrix * vec4( newPos, 1.0 );
      }
    `,

    fragment: `
      varying float glitchColorAmt;
      uniform float time;

      vec3 lerp (vec3 a, vec3 b, float t) {
          return (1. - t) * a + t * b;
      }
      void main() {
        float rgbAmount = glitchColorAmt;
        vec3 fractBy3 = vec3(
            floor(fract(7. * time) + 0.5),
            floor(fract(7. * time+0.3) + 0.5),
            floor(fract(7. * time+0.6) + 0.5)
        );
        gl_FragColor = vec4(0.,0.,0.,0.);
        gl_FragColor.rgb = lerp(gl_FragColor.rgb, fractBy3, rgbAmount);
      }
    `,
};

// export default GlitchShader;