import colors from '../defaults/ThemeDefaults'
import React, { useState, createContext, useContext, useEffect } from "react";
// import { QRCodeContext } from "./QRCodeProvider";

const defaultPersona = require('../defaults/PersonaDefault.json')
const defaultAsset = require('../defaults/AssetDefault.json')
const defaultQR = require('../defaults/QRRough.json')
const defaultProduct = require('../defaults/ProductDefault.json')

// const Default_QR = require('../options_02.json')
// Create a new context
export const AppContext = createContext();

// Create a context provider component
export const AppProvider = ({ children }) => {
  // const { qrCodeData, updateQRCodeData } = useContext(QRCodeContext);
  const [appData, setAppData] = useState(false)
  const [userData, setUserData] = useState()
  const defaultTheme = 'aura'

  const [oauthUser, setOauthUser] = useState(null)
  const [actionError, setActionError] = useState(null)
  const [actionText, setActionText] = useState(null)

  const [geoData, setGeoData] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [closedModalOpen, setClosedModalOpen] = useState(false)
  const [editUser, setEditUser] = useState(false)
  const [appTheme, setAppTheme] = useState({})
  const [currentPersona, setCurrentPersona] = useState(false)
  const [personas, setPersonas] = useState(false)
  const [assets, setAssets] = useState(false)
  const [LSAsset, setLSAsset] = useState(false)
  const [QRs, setQRs] = useState([])
  const [scans, setScans] = useState([])
  const [scanHeaders, setScanHeaders] = useState([])
  const [follows, setFollows] = useState([])
  const [previewQR, setPreviewQR] = useState(false)
  const [selectedQR, setSelectedQR] = useState(false)
  const [scanQR, setScanQR] = useState(false)
  const [rawQR, setRawQR] = useState(false)
  const [assetQR, setAssetQR] = useState(false)
  const [currentScanSet, setCurrentScanSet] = useState([])
  const [currentAsset, setCurrentAsset] = useState(false)
  const [shops, setShops] = useState(false)
  const [orders, setOrders] = useState(false)
  const [currentShop, setCurrentShop] = useState(false)
  const [currentOrder, setCurrentOrder] = useState(false)
  const [currentProduct, setCurrentProduct] = useState(false)
  const [carts, setCarts] = useState(false)
  const [currentCart, setCurrentCart] = useState(false)

  const [newPersona, setNewPersona] = useState({
    ...defaultPersona,
  })
  const [tempPersona, setTempPersona] = useState(false)
  const [newAsset, setNewAsset] = useState({
    ...defaultAsset,
    owner: userData?._id
  })

  const [subView, setSubView] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)


  const updateAppData = (newData) => {
    setAppData((prevState) => ({
      appTheme,
      editUser,
      userData,
      geoData,
      modalOpen,
      currentPersona,
      personas,
      assets,
      QRs,
      scans,
      scanHeaders,
      currentScanSet,
      follows,
      previewQR,
      rawQR,
      assetQR,
      defaultQR,
      scanQR,
      currentAsset,
      newAsset,
      LSAsset,
      newPersona,
      tempPersona,
      actionError,
      actionText,
      shops,
      currentShop,
      orders,
      currentOrder,
      currentProduct,
      defaultProduct,
      closedModalOpen,
      carts,
      currentCart,
      subView,
      ...userData,
      ...prevState,
      ...newData,
    }));

  };
  useEffect(() => {
    setNewPersona({
      ...defaultPersona,
      owner: {
        on: true,
        data: userData?._id,
      },
      firstName: {
        on: true,
        data: userData?.firstName,
      },
      lastName: {
        on: true,
        data: userData?.lastName,
      },
      preferredName: {
        on: true,
        data: userData?.preferredName,
      },
      email: {
        on: true,
        data: userData?.email
      }
    })
  }, [userData])

  return (
    <AppContext.Provider value={{
      appData,
      updateAppData,
      appTheme,
      setAppTheme,
      userData,
      setUserData,
      geoData,
      setGeoData,
      actionError,
      setActionError,
      actionText,
      setActionText,
      oauthUser,
      setOauthUser,
      personas,
      setPersonas,
      currentPersona,
      setCurrentPersona,
      newPersona,
      setNewPersona,
      tempPersona,
      setTempPersona,
      assets,
      setAssets,
      currentAsset,
      setCurrentAsset,
      newAsset,
      setNewAsset,
      LSAsset,
      setLSAsset,
      rawQR,
      setRawQR,
      scanQR,
      setScanQR,
      assetQR,
      setAssetQR,
      QRs,
      setQRs,
      selectedQR,
      setSelectedQR,
      previewQR,
      setPreviewQR,
      defaultQR,
      scans,
      setScans,
      scanHeaders,
      setScanHeaders,
      currentScanSet,
      setCurrentScanSet,
      follows,
      setFollows,
      modalOpen,
      setModalOpen,
      editUser,
      setEditUser,
      isLoggedIn,
      setIsLoggedIn,
      shops,
      setShops,
      orders,
      setOrders,
      currentShop,
      setCurrentShop,
      currentOrder,
      setCurrentOrder,
      currentProduct,
      setCurrentProduct,
      defaultProduct,
      carts,
      setCarts,
      currentCart,
      setCurrentCart,
      subView,
      setSubView,

    }}>
      {children}
    </AppContext.Provider>
  );
};
export const AppState = () => {
  return useContext(AppContext);
};