import ContactPhone from '@mui/icons-material/ContactPhone'
import { Container, Modal, Box, Button, Input, Typography, TextField, Stack, IconButton, InputAdornment, Chip, FormControlLabel } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CloseButton from '../../../components/CloseButton'
import { Add, AddCircle, CheckBox, Link } from '@mui/icons-material'
import SendIcon from '@mui/icons-material/Send';
import * as API from '../../../utils/APICalls'

const ShareModal = (props) => {
    const { setShareModalOpen, shareModalOpen, item, track,
        album, artist,
        validateEmail, listener, setListener } = props
    const [currentEmail, setCurrentEmail] = useState('')
    const defaultMessage = `Hey! Peep this!`
    const [message, setMessage] = useState(defaultMessage)
    const [emails, setEmails] = useState([])
    // const [ shares, setShares ] = useState(listeners?.shares?.length > 0 ? listeners?.shares : [])
    const [preferredName, setPreferredName] = useState('')
    const [updatePreferredName, setUpdatePreferredName] = useState(false)
    const [actionError, setActionError] = useState('')
    const [isSending, setIsSending] = useState(false)
    let name = listener?.preferredName ? listener?.preferredName : listener?.email
    const sharePic = track ? album?.album_pic[0] : artist?.pics[0] ? artist?.pics[0] : artist?.pics[0]
    const inputStyles = {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#00FF00',
                boxShadow: '0px 0px 5px 3px rgba(0, 255, 0 ,0.5),inset 0px 0px 3px 1px rgba(0, 255, 0 ,0.2)',
            },
            '&:hover fieldset': {
                borderColor: '#00FF00',
                boxShadow: '0px 0px 5px 3px rgba(0, 255, 0 ,0.5),inset 0px 0px 3px 1px rgba(0, 255, 0 ,0.2)',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#00FF00',
                boxShadow: '0px 0px 5px 3px rgba(0, 255, 0 ,0.5),inset 0px 0px 3px 1px rgba(0, 255, 0 ,0.2)'
            },
        },
        '& .MuiInputLabel-root': {
            color: '#FF00FF',
            boxShadow: '0px 0px 5px 3px rgba(0, 0, 0 ,0.5),inset 0px 0px 5px 3px rgba(0,0, 0 ,0.5)',
            background: '#000'
        },
        '& .MuiInputBase-input': {
            color: '#00FF00',
        },

    }
    const handleShare = async () => {
        if (emails.length < 1) {
            setActionError('Please enter email(s) to share with')
            return
        }
        try {
            setIsSending(true)
            // console.log('[Share] listener:',listener)
            let shares = listener?.shares?.length > 0 ? listener?.shares : []

            if (updatePreferredName) {
                name = preferredName
            }

            const shareObj = track ?
                {
                    sharedById: listener?._id,
                    share_pic: sharePic,
                    track: {
                        _id: track?._id,
                        track_title: track ? `${album?.artist} - ${track?.subtitle ? track?.name + ' ' + track?.subtitle : track?.name}` : 'n/a',
                    },
                    // track_title: track? `${album?.artist} - ${track?.subtitle ?  track?.name +' '+ track?.subtitle : track?.name }`:'n/a',
                    name: name,
                    message: message,
                    emails: emails
                } :
                {
                    sharedById: listener?._id,
                    share_pic: sharePic,
                    // track_title: track? `${album?.artist} - ${track?.subtitle ?  track?.name +' '+ track?.subtitle : track?.name }`:'n/a',
                    name: name,
                    message: message,
                    emails: emails
                }

            if (shares?.length < 1) {
                shares = [shareObj]
            } else {
                shares.push(shareObj)
            }

            const emailRes = await API.shareMusic(listener?.email, shareObj)
            if (emailRes) {
                const data = await API.updateListener({
                    ...listener,
                    shares,
                    preferredName: (updatePreferredName && preferredName) ? preferredName : listener?.preferredName ? listener?.preferredName : ''
                })
                // console.log('handleShare data:', data)
                if (data) {
                    setListener(data)
                    setEmails([])
                    setShareModalOpen(false)
                } else { setActionError('an error occurred') }
                setIsSending(false)
            } else {
                setActionError('an error occurred')
                setIsSending(false)
            }
        } catch (err) {
            setIsSending(false)
            console.log(err)

        }
    }


    const removeEmailFromList = (emailToRemove) => {
        setEmails((prevEmails) => prevEmails.filter(email => email !== emailToRemove));
    };


    const emailDisplay = () => {
        const list = emails?.map((email, i) => {
            return email
        })

        return list
    }

    // useEffect(()=>{
    //     try{
    //         const l = JSON.parse(localStorage.getItem('listener'))
    //         if(!listener?.email && l?.email){
    //             setListener(l)
    //         }
    //     }catch(err){console.log(err)}
    // },[listener])

    useEffect(() => {

    }, [emails])
    // useEffect(()=>{
    //     console.log('[SHAREPIC]:', sharePic)
    // },[])

    return (
        <>
            <Modal
                open={shareModalOpen}
                // open={shareModalOpen}
                onClose={() => setShareModalOpen(false)}
            >
                <Container sx={{ zIndex: 999 }}>
                    <Box sx={{ position: 'absolute', top: '0rem', right: '0rem' }}>
                        <CloseButton func={setShareModalOpen} value={shareModalOpen}
                        />
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '350px',
                        minHeight: '550px',

                        backgroundColor: '#000',
                        // borderRadius:'10px',
                        border: '2px solid #000',
                        fontFamily: 'Gilroy, sans-serif',
                        // justifyContent:'center',
                        textAlign: 'center',
                        // alignItems:'center',
                        gap: '0.5rem',
                        // padding:'0.5rem'
                        boxShadow: '0 0 10px rgba(0, 255, 0 ,0.5)',
                        color: '#00FF00', borderRadius: '5px'
                    }}>



                        <Box sx={{ position: 'sticky', top: 0, left: 0, zIndex: 999999999, width: '100%', height: '1.5rem', background: '#F6F6F6' }}>
                            <Typography sx={{ color: '#858585' }} >
                                Thank You For Sharing!
                            </Typography>
                        </Box>
                        <Container sx={{
                            display: 'flex', justifyContent: 'center', width: '100%', overflow: 'hidden',
                            padding: '0.5rem', margin: 'auto'
                        }}>

                            <Stack sx={{
                                gap: '0.75rem', maxHeight: '95vh',

                                margin: 'auto',
                                overflowY: 'scroll'
                            }}>
                                {/* <Box sx={{borderRadius:'5px'}}> */}
                                <img src={sharePic} style={{
                                    border: '1px solid #00FF00', borderRadius: '5px', margin: 'auto',
                                    width: '200px', height: 'auto',
                                    boxShadow: '0px 0px 10px 5px rgba(0, 255, 0 ,0.5),inset 0px 0px 5px 3px rgba(0, 255, 0 ,0.2)',
                                }} />
                                {/* {!track && <img src={artist?.pics[0]}/>} */}
                                {/* </Box> */}
                                <h3>  {track ? `${album?.artist}:` : ''}</h3>
                                <h3 style={{ color: '#FF00FF' }}>  {track ? `${track?.subtitle ? track?.name + ' ' + track?.subtitle : track?.name}` : ''}</h3>
                                <TextField label='preferred name'
                                    variant="outlined"

                                    sx={inputStyles}
                                    value={!preferredName ? name : preferredName}
                                    onChange={(e) => { setPreferredName(e.target.value) }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="add email"
                                                //   onClick={handleClicK}
                                                //   onMouseDown={handleClicK}
                                                edge="end"
                                            >
                                                <SendIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                {/* <FormControlLabel
                            sx={{display:'flex', flexDirection:'row', margin:'auto', gap:'0.5rem'}}
                            control={
                                <CheckBox 
                                    checked={updatePreferredName}
                                    onClick={()=>{
                                        setUpdatePreferredName(!updatePreferredName)
                                        console.log('Check click',updatePreferredName )
                                    }}/>
                            }label='update preferred name'  /> */}

                                <TextField
                                    multiline={true}
                                    rows={3}
                                    label='personal message'
                                    defaultValue={message}
                                    onChange={(e) => { setMessage(e.target.value) }}
                                    variant="outlined"
                                    sx={inputStyles} />
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
                                    {emails?.map((email, i) => {
                                        return (
                                            <Chip sx={{
                                                color: '#00FF00',
                                                borderColor: '#00FF00',
                                                '& .MuiChip-label': {
                                                    color: '#00FF00',
                                                },
                                                '&:hover': {
                                                    backgroundColor: 'rgba(0, 255, 0, 0.1)',
                                                },
                                                '& .MuiChip-deleteIcon': {
                                                    color: '#00FF00',
                                                    '&:hover': { color: '#00AA00' },
                                                }
                                            }}
                                                variant="outlined"
                                                key={i} label={email} onDelete={() => removeEmailFromList(email)} />
                                        )
                                    })}
                                </Box>

                                <TextField label='email'
                                    value={currentEmail}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end' sx={{ color: '#00FF00' }}>
                                                <IconButton
                                                    onClick={(e) => {
                                                        if (validateEmail(currentEmail)) {
                                                            if (!emails) {
                                                                setEmails([
                                                                    currentEmail
                                                                ])
                                                            }
                                                            setEmails([
                                                                ...emails,
                                                                currentEmail
                                                            ])
                                                            setCurrentEmail('')
                                                        }
                                                    }}
                                                    sx={{ width: 'fit-content', color: '#00FF00' }}
                                                >
                                                    <AddCircle />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                    sx={inputStyles}
                                    onChange={(e) => { setCurrentEmail(e.target.value) }}
                                />
                                {/* <p style={{fontSize:'0.8rem'}}><b>*Heads up:</b> It might go to the their spam folder!</p> */}
                                <span style={{
                                    display: 'flex', color: '#00FF00', fontSize: '0.85rem',
                                    alignContent: 'center', margin: 'auto',
                                    alignItems: 'center'
                                }} //className='blink'
                                >Receive&nbsp;
                                    <b style={{ color: 'magenta' }}>25</b>
                                    <img style={{ alignSelf: 'center' }}
                                        className='coin-rotate'
                                        src={'/assets/RexCoin.png'} width='25px' height='auto' />
                                    <b>RexCoins</b>&nbsp;per<b style={{ color: 'magenta' }}>&nbsp;<u>returned</u></b>&nbsp;referral
                                </span>
                                {!validateEmail(currentEmail) && currentEmail ? 'please enter valid email' : ''}
                                {actionError ? actionError : ''}
                                <IconButton sx={{
                                    width: 'fit-content', borderRadius: '10px', margin: 'auto', marginTop: '1rem',
                                    fontSize: '0.85rem', alignContent: 'center', alignItems: 'center', color: '#00FF00'
                                }}
                                    onClick={() => {
                                        let link = `https://music.rexentropy.com/share/${listener?._id}`
                                        if (track !== 'n/a') {
                                            link += `/${track?._id}`
                                        }
                                        navigator.clipboard.writeText(link)
                                        setActionError('Copied to clipboard')
                                        document.addEventListener('mousemove', () => setActionError(''))
                                    }}
                                >
                                    <Link />
                                    <Typography>copy link</Typography>
                                </IconButton>
                                <Button variant='outlined'
                                    className="blink"
                                    disabled={isSending}
                                    sx={{
                                        position: 'fixed', bottom: '0.5rem', right: '0.5rem',
                                        color: '#FF00FF'
                                    }}
                                    onClick={() => {
                                        handleShare()
                                    }}
                                >
                                    send{isSending ? 'ing' : ''}
                                </Button>
                            </Stack>
                        </Container>


                    </Box>


                </Container>
            </Modal>

        </>
    )
}

export default ShareModal