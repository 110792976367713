import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './css/flipCard.css';
import './css/LandingPage.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { AppProvider, AppContext, AppState } from "./context/AppProvider";
// import { GoogleOAuthProvider } from '@react-oauth/google';
import { QRCodeProvider } from "./context/QRCodeProvider";
import { BrowserRouter as Router } from "react-router-dom";
import { SignupProvider } from './context/SignupProvider';
import { StorefrontProvider } from './context/StorefrontProvider';
import { HMSRoomProvider } from "@100mslive/react-sdk";

//  $(document).ready(function(){
//       $('.your-class').slick({
//         setting-name: setting-value
//       });
//     });
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  //  <GoogleOAuthProvider clientId="298766433226-02vu972ht62dpcpeggcb9chbcab2c531.apps.googleusercontent.com">
  <React.StrictMode>
    <Router>
      <AppProvider>
        <QRCodeProvider>
          <StorefrontProvider>
            <SignupProvider>
              <HMSRoomProvider>
                <App />
              </HMSRoomProvider>
            </SignupProvider>
          </StorefrontProvider>
        </QRCodeProvider>
      </AppProvider>
    </Router>
  </React.StrictMode>
  //  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
