import { Container, Modal,Box, Button,
     Input, Typography, TextField, Stack, 
     Checkbox} from '@mui/material'
import React, { useState,useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';

import CloseButton from '../../../components/CloseButton'
import * as API from '../../../utils/APICalls'
import * as helpers from '../../../utils/helpers'

const SubsModal = (props) => {
    const {artist,setArtist,subsModalOpen,setSubsModalOpen, listener, setListener } = props
    const [isSubscribed, setIsSubscribed] = useState(artist?.subscribers?.includes(listener?._id));
    const [isSubmitting, setIsSubmitting] = useState(false);
    const activeSub = listener?.subscriptions?.filter(sub=>sub?.isActive === true)
    const [ selectedTier, setSelectedTier ] = useState(activeSub? activeSub: artist?.subscriptions[0])
    const tiers = artist?.subscriptions
    // const tiers = [
    //     {
    //     id: '0',
    //     title: 'GloAun',
    //     sub_title: 'Intro',
        
    //     icon:<img src='https://res.cloudinary.com/dzxzdsnha/image/upload/v1731970728/GLOAUN_001_BL-01-SML_otxefk.png' width="50px" height="auto" style={{margin:'auto'}}/>,
    //     description:'TBD',
    //     price:'0',
    //     rate:'mo',
    //     sku:'SBS-TR-01-GLN'
        
    // },
    // {
    //         id: '1',
    //         title: 'Gridwalker',
    //         sub_title: 'Street Team',
    //         icon:<img src='https://res.cloudinary.com/dzxzdsnha/image/upload/v1715795257/PARALLAXR_PRINTFILES/THE-GRIDWALKER_FINAL_TRNSPRNT_Crop_lelsqt.png' width="50px" height="auto" style={{margin:'auto'}}/>,
    //         description:'TBD',
    //         price:'5',
    //         rate:'mo',
    //         sku:'SBS-TR-02-GRD'
    //     },
    //     {
    //         id: '2',
    //         title: 'Parallaxr',
    //         sub_title: 'Premium',
    //         icon:<img src='/assets/MedMan_Flat.png' width="50px" height="auto" style={{margin:'auto'}}/>,
    //         description:'TBD',
    //         price:'15',
    //         rate:'mo',
    //         sku:'SBS-TR-03-LLXR'    
    //     },
    //     {
    //         id: '3',
    //         title: 'Rex',
    //         sub_title: 'V.I.P',
    //         icon:<img src='/assets/Rex_Entropy_Icon_Glow.png' width="50px" height="auto" style={{margin:'auto'}}/>,
    //         description:'TBD',
    //         price:'25',
    //         rate:'mo',
    //         sku:'SBS-TR-04-RX'

    //     },
    // ]

    const handleListenerRefresh =async () =>{
        try{
    
            const data = await API.fetchListener(listener?.email)
            if(data){
                setListener(data)
            }else{
                console.log('Error',data)
            }
        }catch(err){
            console.log('[ handleListenerRefresh ]Error:',err)
        }
    
      }

    function ImageComponent({ iconHtml }) {
    return (
        <div dangerouslySetInnerHTML={{ __html: iconHtml }} />
    );
    }
    const handleSubscribe = async (tier)=>{
        tier = artist?.subscriptions[0]
        const subObj = {
            sub_id: tier?._id?.toString(),
            artist_id: artist?._id,
            artist_name: artist?.name,
            title: tier?.title,
            start_date:  Date.now ,
            price: tier?.price,
            rate: tier?.rate,
            sku: tier?.sku,
        }

      
      try{
         setIsSubmitting(true)
          const subs = listener?.subs?listener?.subs:[]
          const list_tiers = listener?.subscriptions? listener?.subscriptions :[]

          let listSubIndex = subs?.indexOf(artist?._id)
          if (listSubIndex > -1) { subs?.splice(listSubIndex, 1); }
          else{subs.push(artist?._id)}

        
        const tierCheck = list_tiers.find((t) => t?.sub_id === tier?._id)
        const l = listener
        const subTierFactor = 
            artist?.subscriptions?.findIndex((s)=> s?.sku === tier?.sku)
    
        console.log('tierCheck:', tierCheck)
        console.log('subTierFactor:', subTierFactor)
        const res2 = await API.updateListener({
            ...l, 
            subs:subs,
            subscriptions: !tierCheck? [tier] : tierCheck,
            wallet:{
                ...l?.wallet,
                coins: l?.wallet?.coins + 25 * ( subTierFactor + 1),  
                }
        })
          if(res2){
            // setIsSubscribed(res2?.subs?.includes(artist?._id))
            
            setListener(res2)

    //-----Add to artist's subscribers--------//
          const subscribers = artist?.subscribers
          let index = subscribers?.indexOf(listener?._id)
        //   if (index > -1) { subscribers?.splice(index, 1); }
        //   else{subscribers.push(listener?._id)}
          if (index <= -1){subscribers.push(listener?._id)}
    
        const res = await API.updateArtist({...artist, subscribers:subscribers})
          if(res){
            setIsSubscribed(res?.subscribers?.includes(listener?._id))
            setArtist(res)
          }else{
            console.log('[Error Subscribing]')
            setIsSubmitting(false)
        }
          }else{
            console.log('[Error Subscribing]')
            setIsSubmitting(false)
        }
        handleListenerRefresh()
        setSubsModalOpen(false)
        setIsSubmitting(false)
        }catch(err){console.log(err)
            setIsSubmitting(false)

        }
      }

      useEffect(()=>{

      },[selectedTier])
      
  return (
     <>
        <Modal 
            // open={true}
            open={subsModalOpen}
            onClose={()=>setSubsModalOpen(!subsModalOpen)}
            >
            <Container sx={{zIndex:999}}>
                <Box sx={{ 
                    display:'flex',
                    flexDirection:'column',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    height:'100%',
                    backgroundColor:'rgba( 0, 0, 0, 0.8)',
                    backdropFilter:'blur(5px)',
                    borderRadius:'10px',
                    border:'2px solid #000',
                    fontFamily: 'Gilroy, sans-serif',
                    justifyContent:'center',
                    textAlign:'center',
                    alignItems:'center',
                    gap:'0.5rem',
                    padding:'0.5rem',
                    color:'#FFF'
                    }}>
                        <Box sx={{position:'absolute', top:'0.5rem', right:'0.5rem'}}>
                            <CloseButton func={setSubsModalOpen} val={subsModalOpen}
                            />
                        </Box>
                         
                         {tiers &&  <Box sx={{display:'flex',flexDirection:'column',  background:'#000',
                                boxShadow:'0 0 10px rgba(0, 255, 0 ,0.5)',
                                overflow:'hidden',overflowY:'scroll',
                                width:'340px', height:'fit-content', gap:'0.5rem', color:'#00FF00', borderRadius:'5px'}}>
                           <Box sx={{position:'sticky',top:0, left:0, zIndex:999999999,width:'100%', height:'1.5rem',background:'#F6F6F6'}}>
                            <Typography sx={{color: '#858585'}}>Subscriptions</Typography>
                           </Box>
                        <Box sx={{padding:'0.5rem', margin:'auto',width:'80%'}}>
                      <b style={{color:'magenta',alignSelf:'start'}} >
                        Select a Tier</b>
                        <br/>
                      <em style={{display:'flex',color:'#00FF00',fontSize:'0.8rem',margin:'auto',justifyContent:'center'}} >
                        Only "Intro" available at the moment.</em>
                      {/* <p style={{fontSize:'0.75rem'}}>
                       Segments of a larger tier concept in the form of mini-tiers, centered around my personal journeys with <b><Link  href="https://tourette.org/about-tourette/overview/what-is-tourette/"  target="_blank" style={{color:'magenta'}}>Tourette Syndrome</Link></b>  and what they have to offer. 
                      </p> */}
                      {/* <p style={{fontSize:'0.75rem'}}>
                       I would love the opportunity to pan out the concept with a team to create an engaging experience that provides an informative perspective 
                      </p> */}
                        <Box sx={{display:'flex', flexDirection:'column',
                            flexWrap:'wrap', alignItems:'center',
                            alignContent:'center',// border: '1px solid #FFF',
                            margin:'auto',
                            width:'100%', gap:'0.5rem', padding: '0.5rem',}}>
                            {tiers?.map((tier,i)=>{
                                const tierCheck = listener?.subscriptions?.find((s) => (s?.sku?.toString() === tier?.sku?.toString() && s?.isActive ))
                                // console.log('tierCheck:',tierCheck)
                                return(
                                    <Box
                                    key={i}
                                    className={(i+1)%2 ?'blink':'blink-2'}
                                    style={{textDecoration:'none',
                                        position:'relative',
                                        boxShadow: !tier?.isActive?'0px 0px 15px 5px rgba(0, 255, 0 ,0.5),inset 0px 0px 5px 3px rgba(0, 255, 0 ,0.2)':'0px 0px 15px 5px rgba(255, 255, 255 ,0.5)',
                                        border:  !tier?.isActive?'1px solid #00FF00':'1px solid #FFFFFF',
                                        borderRadius:'5px',
                                        display:'flex',
                                        textAlign:'left',
                                        gap:'0.5rem',
                                        padding:'0.5rem',
                                        color:'#00FF00', 
                                        width:'100%',
                                        height:'100px',
                                        opacity: tier?.title !== tiers[0].title ?'0.3':'1',
                                        }}>

                                       {!tier?.isActive && <Box sx={{position:'absolute', left:0, top:0,
                                            width:'100%',
                                            height:'1rem',
                                            opacity:'0.5',
                                            textAlign:'center',
                                            alignItems:'center',
                                            alignContent:'center',
                                            margin:'0 margin',
                                            background:'#00FF00', color:'#000'}}>
                                            <b style={{alignSelf:'center',alignContent:'center',textAlign:'center',fontSize:'0.8rem'}}>
                                                currently unavailable
                                            </b>
                                        </Box>}

                                            {tierCheck ?<span style={{display:'flex',gap:'0.25rem',position:'absolute', top:'0.25rem', right:'0.5rem',alignItems:'center'}}>
                                                <div //className='blink-2'
                                                style={{backgroundColor:"#00FF00",
                                                    boxShadow:'0px 0px 15px 5px rgba(0, 255, 0 ,0.5)',
                                                    margin:0,
                                                    borderRadius:'50px',width:'0.5rem',height:'0.5rem'}}>&nbsp;</div>
                                                <span>
                                                active
                                                </span> 
                                                </span>:''}
                                            {<Checkbox 
                                            // checked={activeSub?._id  === tier?.id}
                                            defaultChecked={tierCheck}
                                           
                                            onChange={()=>{
                                                try{

                                                    setSelectedTier(tier)
                                                    console.log('[SUB CLICK]---> tier:', tier)
                                                    // console.log('[SUB CLICK]---> ,selectedTier:',selectedTier)
                                                    // handleSubscribe(tier)
                                                }catch(err){
                                                    console.log(err)
                                                }

                                            }}
                                            sx={{color:"#FFF"}}
                                            
                                            disabled={!tier?.isActive}
                                            />
                                            }
                                        <Box sx={{display:'flex',
                                            width:'20%',
                                            height:'50%',
                                            flexDirection: 'column',
                                            border:'3px solid #00FF00', 
                                            boxShadow:'0px 0px 5px 2px rgba(0, 255, 0 ,0.5),inset 0 0 3px 2px rgba(0, 255, 0 ,0.2)',
                                            borderRadius:'8px', 
                                            background:'rgba(255,255,255,1)',
                                            padding: '0.25rem',
                                            cursor:'pointer',
                                            textAlign:'center',
                                            justifyContent:'center',
                                            alignSelf:'center',
                                            '&:hover': {background:'rgba(144, 254, 195,0.5)'},
                                        
                                        }}
                                            // onClick={()=>{
                                            //     settier(tier)
                                            // }}
                                            >
                                                <ImageComponent iconHtml={tier?.icon} />
                                            {/* {tier?.icon} */}
                                        </Box>
                                    <Stack sx={{ alignSelf:'center',}}>
                            <b style={{fontSize:'0.9rem',color:'magenta' }}>{tier?.title}</b>
                            <span style={{fontSize:'0.9rem',color:'#00FF00' }}>{tier?.sub_title}</span>
                                       <p style={{ width:'90%', padding:'0.25rem',fontSize:'0.75rem'}}>
                                        {tier?.description}
                                        </p> 
                                    <span>
                                        <b>price: </b>
                                        ${tier?.price}/{tier?.rate}.
                                    </span>
                                    </Stack>
                                </Box>
                                )
                            }) }
                         </Box>
                        </Box>
                       <Button 
                        variant="contained"
                        disabled={isSubmitting || !selectedTier?true:false}
                        sx={{margin:'auto', marginBottom:'0.75rem'}} 
                        onClick={()=>{
                            handleSubscribe()
                        }}>
                            {isSubmitting?'Sending':'Select'}
                        </Button>
                       </Box>}
                       {/* <iframe src="https://subearth.rexentropy.com" title="Embedded React App" /> */}
                       {/* <iframe src="https://subearth.rexentropy.com" title="Embedded React App" /> */}
                      {/* {tier && <iframe src={tier?.src} style={{width:'100%', height:'100%'}}
                       title="Embedded React App" />}
                   */}
                    </Box>                   
            </Container>
        </Modal>
        
    </>
  )
}

export default SubsModal

 
