import { Container, Modal,Box, Button,
     Input, Typography, TextField, Stack, 
     Avatar} from '@mui/material'
import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import CloseButton from '../../../components/CloseButton'
import { Favorite, FavoriteBorderOutlined } from '@mui/icons-material';

const TipModal = (props) => {
    const { tipModalOpen,setTipModalOpen,listener } = props
  function stringAvatar(name) {
  return {
    
    sx: {
      bgcolor:'#000', //stringToColor(name),
      border:'1px solid #00FF00',
        boxShadow:'0px 0px 10px 3px rgba(0, 255, 0 ,0.5)',
        position:'absolute',
        fontWeight:'bold',
        color:'#00FF00',
        left:'-1rem', bottom:'-2rem'
    },
    children: `${name[0]?.toUpperCase()}`,
  };
}

    return (
     <>
        <Modal 
            open={tipModalOpen}
            onClose={()=>setTipModalOpen(!tipModalOpen)}
            >
            <Container sx={{zIndex:999}}>
                <Box sx={{ 
                    display:'flex',
                    flexDirection:'column',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    height:'100%',
                    backgroundColor:'rgba( 0, 0, 0, 0.8)',
                    backdropFilter:'blur(5px)',
                    borderRadius:'10px',
                    border:'2px solid #000',
                    fontFamily: 'Gilroy, sans-serif',
                    justifyContent:'center',
                    textAlign:'center',
                    alignItems:'center',
                    gap:'0.5rem',
                    padding:'0.5rem',
                    color:'#FFF'
                    }}>
                        <Box sx={{position:'absolute', top:'0.5rem', right:'0.5rem'}}>
                            <CloseButton func={setTipModalOpen} val={tipModalOpen}
                            />
                        </Box>
                         <Box sx={{
                            position:'relative',
                                display:'flex',flexDirection:'column',  background:'#000',
                                boxShadow:'0 0 10px rgba(0, 255, 0 ,0.5)',
                                overflow:'hidden',overflowY:'scroll',
                                width:'340px', minHeight:'450px', gap:'0.5rem', color:'#00FF00', borderRadius:'5px'}}>
                            <Box sx={{position:'sticky',top:0, left:0, zIndex:999999999,width:'100%', height:'1.5rem',background:'#F6F6F6'}}>
                         
                                <Typography sx={{color: '#858585'}} className='blink'>
                                   <b>Send Some Love!</b> 
                                </Typography>
                                </Box>
                            
                        <Stack sx={{position:'relative',
                            height:'100%',width:'100%',
                            padding:'0.5rem',
                            // flex:1
                                margin:'auto', 
                            }} >

                        <Stack 
                            sx={{
                                // position:'absolute',
                                // top:'2.5rem',
                                // left:'0.75rem',
                                width:'200px', height:'200px', 
                                backgroundColor:'rgba(0,0,0,0.3)', 
                                backdropFilter:'blur(5px)',
                                padding:'0.25rem',justifyContent:'center',
                                borderRadius:'25px 25px 25px 5px',  
                                gap:'0.5rem', alignItems:'center',
                                marginLeft:'1rem',
                                alignContent:'center', 
                                border:'1px solid #00FF00',
                                boxShadow:'0px 0px 15px 5px rgba(0, 255, 0 ,0.5),inset 0px 0px 5px 3px rgba(0, 255, 0 ,0.2)',
                            }}>


                            <Link to='https://cash.app/$AJPHNX' target='_blank'>
                             <Button variant='outlined' sx={{
                                width:'100px',
                                backgroundColor:'rgba(0,214,78)',color:'#fff'}}>
                               <b>CashApp</b>  
                            </Button>  
                            </Link>
                           
                            <Link to='https://venmo.com/code?user_id=842007039705088035&created=1727462433' target='_blank'>
                             <Button variant='outlined' sx={{
                                width:'100px',
                                backgroundColor:'rgba(0,140,255)',color:'#fff'}}>
                       
                               <b>Venmo</b> 
                            </Button>  
                            </Link>

                            {/* <Link to='https://venmo.com/code?user_id=842007039705088035&created=1727462433' target='_blank'> */}
                             <Button variant='outlined' 
                             disabled={true}
                             sx={{
                                width:'100px',
                                backgroundColor:'rgba(84,	51,	255)',color:'#fff'}}>
                       
                               <b>Stripe</b> 
                            </Button>  

                            <Avatar //sx={{}} 
                                {...stringAvatar(listener?.preferredName?listener?.preferredName:listener?.email)}
                                // src={`https://res.cloudinary.com/dzxzdsnha/image/upload/v1686761978/Rex_Entropy_Icon_Glow3_mxyw45.png`}
                                />
                             <Favorite sx={{color:'red', position:'absolute', 
                                left:'2.25rem', bottom:'-1.25rem', fontSize:'0.85rem'}}/>
                            </Stack>
                            {/* <hr style={{borderColor:'#00FF00', width: '90%', margin:'auto',
                                boxShadow:'0px -2px 15px 5px rgba(0, 255, 0 ,0.5),inset 0px 0px 5px 3px rgba(0, 255, 0 ,0.2)',}}/> */}
                            <Stack sx={{
                                // position:'absolute',
                                // bottom:'0.75rem',
                                // right:'0.75rem',
                                border:'1px solid #00FF00',
                                borderRadius:'25px 25px 5px 25px',
                                padding:'0.25rem 1rem',
                                height:'4rem',
                                width:'60%',
                                alignSelf:'end',
                                margin:'1rem 2rem',
                                // marginTop:'1rem',
                                backgroundColor:'rgba(0,0,0,0.5)',
                                backdropFilter:'blur( 5px)',
                                 boxShadow:'0px -2px 15px 5px rgba(0, 255, 0 ,0.5),inset 0px 0px 5px 3px rgba(0, 255, 0 ,0.2)',
                                 display:'flex',flexDirection:'column', //border:'1px solid red',
                                textAlign:'end',justifyContent:'center'}}>
                                <Typography sx={{ fontSize:'1rem'}} className='blink'>
                                   "<b>✊🔥🙏🙌🥳🙌🙏🔥✊.</b>"
                                </Typography>
                                <span sx={{margin:'auto', fontSize:'1em',textAlign:'right',
                                     alignSelf:'end', }} className='blink-2'>
                                   <b>- Rex Entropy 🤘❤️</b> 
                                </span>
                                <Avatar sx={{border:'1px solid #00FF00',
                                boxShadow:'0px 0px 10px 3px rgba(0, 255, 0 ,0.5)',
                                    position:'absolute',
                                right:'-1rem', bottom:'-2rem'}} src={`https://res.cloudinary.com/dzxzdsnha/image/upload/v1686761978/Rex_Entropy_Icon_Glow3_mxyw45.png`}/>
                           
                            </Stack>
                                <Box sx={{
                                    position:'absolute', 
                                    bottom:'0.5rem',
                                    left:'0.5rem',
                                    border:'1px solid #00FF00', 
                                    borderRadius:'20px',
                                    width:'75%',
                                    height:'1.5rem',
                                    alignItems:'center',
                                    alignContent:'center',
                                    textAlign:'start',
                                    marginBottom:'0.5rem',
                                    padding:'0.5rem'}}>
                                    <p>Love</p>
                                    <SendIcon sx={{
                                        alignSelf:'right',
                                        position:'absolute',
                                        right:'0.5rem',
                                        top:'0.5rem',
                                        }}/>
                                </Box>
                <FavoriteBorderOutlined sx={{color:'#00FF00', 
                    fontSize:'1.5rem',
                    position:'absolute', right:'1.75rem', bottom:'1.5rem'}}/>
                        </Stack>
                            {/* </Link> */}
                            {/* <Typography  variant='caption'>
                                
                                ( Check back soon! )
                            </Typography> */}
                  </Box>
                    </Box>                   
            </Container>
        </Modal>
        
    </>
  )
}

export default TipModal