import { Container, Modal,Box, Button,
     Input, Typography, TextField, Stack, 
     TabPanel,TabList,
     Tab,
     Tabs,
     Tooltip,Pagination
    } from '@mui/material'
import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';

import CloseButton from '../../../components/CloseButton'
import RefreshIcon from '@mui/icons-material/Refresh';
import * as API from '../../../utils/APICalls'


const WalletModal = (props) => {
    const { walletModalOpen, setWalletModalOpen,setShareModalOpen, listener,setListener } = props
  const [ walletView, setWalletView ] = useState('coins')
  const [ shareView, setShareView ] = useState('shares')
  const handleButtonClick = (row) => {
  // Perform action with the row data
  console.log('Button clicked for row:', row);
};

  const shareColumns = [
  { field: 'email', headerName: 'Email', width: 120 },
  {
    field: 'date',
    headerName: 'Date',
    width: 80,
    // editable: true,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 80,
    // editable: true,
    renderCell: (params) => {
      if (params.value === 'resend') {
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleButtonClick(params.row)}
          >
            resend
          </Button>
        );
      } 

      return (
        <div style={{ color: params.value !== 'resend' ? '#FF00FF' : '#00ff00', display:'flex',fontSize:'0.85rem',
                alignContent:'center',
                alignItems:'center',
                fontFamily:'LCD2', //className='blink'
                justifyContent:'center'
                }}>
          {params.value}  <img style={{ alignSelf:'center'}} className='coin-rotate'
                     src={'/assets/RexCoin.png'} width='20px' height='auto'/>
        </div>
      )
      return params.value;
    },
  },
  ];
  let fullList = []
  let arr=[]
   let totalShares =[]
  listener?.shares?.forEach((s,i)=>{
    s.emails?.forEach((e,i)=>{
        totalShares.push({
            email: e,
            date: s?.date,
        })
    })

  })
//   console.log('totalShares',totalShares)
  const shareRows = totalShares?.map((s,i)=>{
    const isRedeemed = listener?.returned_shares?.find( rs=>rs?.email === s?.email)
    const date = new Date(s?.date)
    let formatted_date = date.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: '2-digit'
            })
    const statusValue = isRedeemed? isRedeemed?.value: 'resend'//<div style={{borders:'1px solid magenta', borderRadius:'5px'}}>resend</div>
    return {
        id: i+1,
        email: s?.email,
        date: formatted_date,
        status: statusValue
    }
  })
  

  const handleListenerRefresh =async () =>{
    try{

        const data = await API.fetchListener(listener?.email)
        if(data){
            setListener(data)
        }else{
            console.log('Error',data)
        }
    }catch(err){
        console.log('[ handleListenerRefresh ]Error:',err)
    }

  }

    return (
     <>
        <Modal 
            open={walletModalOpen}
            onClose={()=>setWalletModalOpen(!walletModalOpen)}
            >
            <Container sx={{zIndex:999}}>
                <Box sx={{ 
                    display:'flex',
                    flexDirection:'column',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    height:'100%',
                    backgroundColor:'rgba( 0, 0, 0, 0.8)',
                    backdropFilter:'blur(5px)',
                    borderRadius:'10px',
                    border:'2px solid #000',
                    fontFamily: 'Gilroy, sans-serif',
                    justifyContent:'center',
                    textAlign:'center',
                    alignItems:'center',
                    gap:'0.5rem',
                    padding:'0.5rem',
                    color:'#FFF',
                     minHeight:'450px', 
                    }}>
                        <Box sx={{position:'absolute', top:'0.5rem', right:'0.5rem'}}>
                            <CloseButton func={setWalletModalOpen} val={walletModalOpen}
                            />
                        </Box>
                         <Box sx={{position:'relative',display:'flex',flexDirection:'column',  background:'#000',
                                    boxShadow:'0 0 10px rgba(0, 255, 0 ,0.5)',
                                    overflow:'hidden',overflowY:'scroll',
                                    width:'340px', minHeight:'400px', gap:'0.5rem', color:'#00FF00', borderRadius:'5px'}}>
                            <Box sx={{position:'sticky',top:0, left:0, zIndex:999999999,width:'100%', height:'1.5rem',background:'#F6F6F6'}}>
                         
                                <Typography sx={{color: '#858585'}} className='blink'>
                                  💰 <b>Rex Wallet</b> 💰
                                </Typography>
                                </Box>

                               
                            {/* <Box sx={{width:'100%',height:'100%',padding:'0.5rem',margin:'0'}}> */}
                               <Tooltip label='refresh'>
                                <RefreshIcon className='button-accent-hover'
                                 sx={{position:'absolute', left:'0.5rem', top:'2rem', cursor:'pointer'}}
                                 onClick={()=>{
                                    handleListenerRefresh()
                                 }}
                                 />
                               </Tooltip>

                                <span style={{color: '#00FF00',textAlign:'center', width:'100%',// borderBottom:'1px solid #00FF00'

                                }} //className='blink'
                                    >
                               <span style={{color:'magenta'}}>
                                account:  
                                </span>
                                &nbsp;
                                 {listener?.email}
                                </span>
                               
                               
                              <Box sx={{ borderBottom: 1, borderColor: '#00FF00',color:'#00FF00',margin:'0 auto',}}>
                                <Tabs sx={{display:'flex',}}
                                    textColor= "#00FF00"
                                    indicatorColor="#00FF00"
                                    // onChange={(e)=>setWalletView(e.target.value)}
                                    >
                                    <Tab label="RexCoins" value="coins" 
                                    sx={{color:walletView === 'coins'? 'magenta':"#00FF00"}}
                                    onClick={(e)=>setWalletView('coins')}
                                    />
                                    <Tab label="Tracking" value="shares" 
                                    sx={{color:walletView === 'shares'? 'magenta':"#00FF00"}}
                                    onClick={(e)=>setWalletView('shares')}
                                    />
                                    <Tab label={`promos ${listener?.wallet?.promos?.length ? listener?.wallet?.promos?.length:'0'}`} 
                                    value='promos'
                                     sx={{color:walletView === 'promos'? 'magenta':"#00FF00"}}
                                    onClick={(e)=>setWalletView('promos')}
                                     />
                                </Tabs>
                                </Box>
                                {walletView === 'shares'&&
                              <Box sx={{display:'flex', borderBottom: 1, borderColor: '#00FF00', width:'100%',
                                color:'#00FF00',margin:'0 auto',
                                justifyContent:'center',
                                boxShadow:'inset 0px -5px 5px 3px rgba(0, 255, 0 ,0.2)',
                                }}>
                                 <Tabs sx={{display:'flex', //margin:'auto', 
                                    // border:'1px solid red',
                                    fontSize:'0.5rem',
                                    // justifyContent:'center',
                                }}
                                    textColor= "#00FF00"
                                    indicatorColor="#00FF00"
                                    // onChange={(e)=>setWalletView(e.target.value)}
                                    >
                                        <Tab label={`Stats`} value='stats'
                                         sx={{color:shareView === 'stats'? 'magenta':"#00FF00"}}
                                        onClick={(e)=>setShareView('stats')}
                                         />
                                    <Tab label={`Shares`} value="shares" 
                                    sx={{color:shareView === 'shares'? 'magenta':"#00FF00"}}
                                    onClick={(e)=>setShareView('shares')}
                                    />
                                    <Tab label="Gifts" value="gifts" 
                                    sx={{color:shareView === 'gifts'? 'magenta':"#00FF00"}}
                                    onClick={(e)=>setShareView('gifts')}
                                    />
                                    {/* <Tab label="Plays" value="all" 
                                    sx={{color:shareView === 'all'? 'magenta':"#00FF00"}}
                                    onClick={(e)=>setShareView('all')}
                                    /> */}
                                </Tabs>
                                    </Box>
                                
                                }

                         {/* <TabPanel value="coins"> */}

                           {walletView  === 'coins' && <Stack 
                                sx={{margin:'0 auto', width:'85%', height:'225px', 
                                // backgroundColor:'rgba(255,255,255,0.3)', 
                                    backdropFilter:'blur(2px)',
                                    padding:'0.5rem',justifyContent:'center',
                                    borderRadius:'10px',  gap:'0.5rem', alignItems:'center',
                                    alignContent:'center', border:'2px solid #00FF00',
                                    boxShadow:'0px 0px 15px 5px rgba(0, 255, 0 ,0.5),inset 0px 0px 5px 3px rgba(0, 255, 0 ,0.2)',
                                }}>

                                <Stack sx={{gap:'0.5rem'}}>
                                    <Box  style={{
                                        justifyContent:'center',
                                        alignContent:'center',
                                        alignItems:'center'}}>

                                    <Typography style={{ fontWeight:'bold',fontSize:'2.5rem', 
                                            fontFamily:'LCD2,sans-serif',marginBottom:'0rem',
                                            color:'magenta',alignSelf:'center'}}>
                                        {listener?.wallet?.coins}
                                    </Typography>
                                    <img className='coin-rotate'
                                    style={{ alignSelf:'center', marginTop:'-0.5rem'}}
                                    src={'/assets/RexCoin.png'} width='80px' height='auto'/>
                                    </Box>
                                    <span style={{display:'flex',color: '#00FF00',fontSize:'0.85rem',
                                    alignContent:'center',
                                    alignItems:'center'}} //className='blink'
                                        >
                                    <b style={{color:'magenta'}}>50</b>
                                        <img style={{ alignSelf:'center'}}
                                    src={'/assets/RexCoin.png'} width='25px' height='auto'/>
                                        RexCoins =<b style={{color:'magenta'}}>&nbsp;$1</b>&nbsp;US
                                    </span>
                                     <Tooltip title='currently unavailable'
                                              > 
                                    <Button variant='outlined' 
                                        // disabled={true}
                                        sx={{
                                        // width:'100px',
                                        backgroundColor:'rgba(84,	51,	255)',color:'#fff'}}
                                        onClick={()=>{setShareModalOpen(true)}}
                                        >
                                
                                        {/* <b> */}
                                            Load Wallet
                                            {/* </b>  */}
                                    </Button>  
                                    </Tooltip>
                                </Stack>
                             {/* <hr style={{borderColor:'#00FF00', width: '90%', margin:'auto',
                                boxShadow:'0px -2px 15px 5px rgba(0, 255, 0 ,0.5),inset 0px 0px 5px 3px rgba(0, 255, 0 ,0.2)',}}/> */}
                            <Box>
                           
                            </Box>
                            </Stack>}

                          {walletView  === 'coins' &&   <Typography
                            sx={{fontSize:'0.65rem',width:'80%',margin:'0 auto',marginBottom:'0.5rem'}}
                                variant='caption'
                            >
                            ( RexCoins may only be exchanged for goods and services relating to Rex Entropy. RexCoins are not redeemable for cash )
                            </Typography>}

                            
                           {walletView  === 'promos' && <Stack 
                                sx={{margin:'0 auto', width:'85%', height:'225px', 
                                // backgroundColor:'rgba(255,255,255,0.3)', 
                                    backdropFilter:'blur(2px)',
                                    marginBottom:'0.5rem',
                                    padding:'0.5rem',justifyContent:'center',
                                    borderRadius:'10px',  gap:'0.5rem', alignItems:'center',
                                    alignContent:'center', border:'2px solid #00FF00',
                                    boxShadow:'0px 0px 15px 5px rgba(0, 255, 0 ,0.5),inset 0px 0px 5px 3px rgba(0, 255, 0 ,0.2)',
                                }}>
                                    {listener?.wallet?.promos?.length > 0 ? listener?.wallet?.promos?.map((p,i)=>{
                                        return(
                                            <>
                                            
                                            </>
                                        )
                                    }):'currently empty'}

                                </Stack>}

                           {walletView  === 'shares' && <Stack 
                                sx={{position: 'relative',
                                    margin:'0 auto', width:'85%', minHeight:'200px', 
                                    marginBottom:'0.5rem',
                                // backgroundColor:'rgba(255,255,255,0.3)', 
                                    backdropFilter:'blur(2px)',
                                    padding:'0.5rem',justifyContent:'center',
                                    borderRadius:'10px',  gap:'0.5rem', 
                                    // alignItems:'center',
                                    // alignContent:'center', 
                                    border:'2px solid #00FF00',
                                    boxShadow:'0px 0px 15px 5px rgba(0, 255, 0 ,0.5),inset 0px 0px 5px 3px rgba(0, 255, 0 ,0.2)',
                                }}>
                                    
                                    {shareView === 'gifts' &&
                                        <Box>

                                            {listener?.gifts?.length > 0 ? listener?.gifts?.map((s,i)=>{
                                               const date = new Date(s?.date)
                                               let formatted_date = date.toLocaleDateString('en-US', {
                                                        month: '2-digit',
                                                        day: '2-digit',
                                                        year: '2-digit'
                                                        })
                                               return(
                                                    <Box style={{display:'flex',fontSize:'0.75rem', gap:'0.25rem', width:'100%'}}>
                                                    {/* <span>
                                                        {s?.email}
                                                    </span>
                                                    <span>
                                                    {formatted_date}
                                                    </span> */}
        
                                                    </Box>
                                                )
                                            }):'currently empty'}
                                        </Box>
                                    }
                                    {shareView === 'shares' &&
                                        <Box sx={{ height: '100%', width: '100%' ,color:'#00FF00'}}>
                                                <DataGrid
                                                    rows={shareRows}
                                                    columns={shareColumns}
                                                    initialState={{
                                                    pagination: {
                                                        paginationModel: {
                                                        pageSize: 5,
                                                        // color:'#00ff00'
                                                        },
                                                    },
                                                    }}
                                                     components={{
                                                        Pagination: (props) => (
                                                        <Pagination {...props} className="custom-pagination" />
                                                        ),}}
                                                   getRowClassName={() => 'share-row-text-color'}
                                                    pageSizeOptions={[5]}
                                                    // checkboxSelection
                                                    disableRowSelectionOnClick
                                                    sx={{
                                                '& .MuiTablePagination-root': {
                                                    color: '#FF00FF', // Main text color
                                                },
                                                '& .MuiTablePagination-selectLabel': {
                                                    color: '#00ff00', // "Rows per page" label color
                                                },
                                                '& .MuiTablePagination-select': {
                                                    color: '#00FF00', // Dropdown text color
                                                },
                                                '& .MuiTablePagination-displayedRows': {
                                                    color: '#FF00FF', // Displayed rows text color
                                                },
                                                }}
                                                />
                                                </Box>
                                        // <Box sx={{dispay:'flex',flexDirection:'column'}}>
                                        //     <span style={{alignSelf:'start'}}>
                                        //     Total: { listener?.returned_shares?.length }
                                        //     </span>
                                        //     {listener?.returned_shares?.length > 0 ? listener?.returned_shares?.map((s,i)=>{
                                        //        const date = new Date(s?.date)
                                        //        let formatted_date = date.toLocaleDateString('en-US', {
                                        //                 month: '2-digit',
                                        //                 day: '2-digit',
                                        //                 year: '2-digit'
                                        //                 })
                                        //        return(
                                        //             <Box style={{display:'flex',justifyContent:'space-evenly',fontSize:'0.75rem', gap:'0.25rem', width:'100%'}}>
                                        //             <span>
                                        //                 {s?.email}
                                        //             </span>
                                        //             <span>
                                        //             {formatted_date}
                                        //             </span>
                                        //             <span style ={{color:'magenta'}}>
                                        //             {s?.value} <span style={{fontSize:'0.65rem'}}>RC</span>
                                        //             </span>
        
                                        //             </Box>
                                        //         )
                                        //     }):'currently empty'}
                                        // </Box>
                                    }
                                    {shareView === 'stats' &&
                                        <Box>

                                            {listener?.returned_shares?.length > 0 ? listener?.returned_shares?.map((s,i)=>{
                                               const date = new Date(s?.date)
                                               let formatted_date = date.toLocaleDateString('en-US', {
                                                        month: '2-digit',
                                                        day: '2-digit',
                                                        year: '2-digit'
                                                        })
                                               return(
                                                    <Box style={{display:'flex',fontSize:'1rem', 
                                                        gap:'0.25rem', margin:'auto',
                                                        width:'100%',padding:'0.25rem',textAlign:'left'}}>
                                                   <Stack sx={{position:'absolute', left:'0.5rem', minWidth:'100px',
                                                   border:'1px solid #00FF00', padding:'0.5rem', borderRadius:'5px',
                                                    top:'0.5rem',gap:'0.25rem' ,color:'#00FF00'}}>
                                                        {/* <Typography sx={{margin:'auto',}}>
                                                            Coming Soon.
                                                        </Typography> */}
                                                       <b style={{borderBottom:'1px solid #00FF00'}}>shares:&nbsp;<span style={{color:'#FF00FF', alignSelf:'end',textAlign:'end',fontFamily:'LCD2, sans-serif'}}>
                                                            &nbsp;{listener?.shares?.length}
                                                            </span></b>
                                                        {/* <span >
                                                            total: 
                                                            <b style={{color:'#FF00FF'}}>
                                                            &nbsp;{listener?.shares?.length}
                                                            </b>
                                                        </span> */}
                                                        <span style={{width:'100%'}}>
                                                            redeemed: 
                                                            <b style={{color:'#FF00FF',textAlign:'end',alignSelf:'end',justifyContent:'end',flex:1,fontFamily:'LCD2, sans-serif'}}>
                                                            &nbsp;{listener?.returned_shares?.length}
                                                            </b>
                                                        </span>
                                                        <span>
                                                            conversion: 
                                                            <b style={{color:'#FF00FF',textAlign:'right',fontFamily:'LCD2, sans-serif'}}>
                                                            &nbsp;{listener?.shares?.length > 0 ?((listener?.returned_shares?.length/listener?.shares?.length) *100).toFixed(2) :0}
                                                            <span style={{fontSize:'0.85rem'}}>
                                                                &nbsp;%
                                                            </span>
                                                            </b>
                                                        </span>
                                                        <span style={{display:'flex', alignItems:'center',alignContent:'center'}}>
                                                           <span>
                                                            earnings: 
                                                           </span>

                                                            <b style={{color:'#FF00FF',textAlign:'right',alignSelf:'end',fontFamily:'LCD2, sans-serif'}}>
                                                            &nbsp;{listener?.returned_shares?.reduce((acc,rs)=>{
                                                                return acc + rs?.value
                                                            },0)}  
                                                            </b>
                                                            <img style={{ alignSelf:'end'}} className='coin-rotate'
                     src={'/assets/RexCoin.png'} width='20px' height='auto'/>
                                                        </span>
                                                   </Stack>
                                                    </Box>
                                                )
                                            }):'currently empty'}
                                        </Box>
                                    }

                                </Stack>}
                          
                  </Box>
                    </Box>                   
            </Container>
        </Modal>
        
    </>
  )
}

export default WalletModal