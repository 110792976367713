import React, { useEffect, useRef, useState, useContext } from 'react'
import { AppBar, Button, CardMedia, Container, Divider, List, ListItem, ListItemIcon, ListItemText, Modal, Stack, TextField, Tooltip } from '@mui/material';

import Box from '@mui/material/Box';

import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
// import CustomerInfoForm from '../../components/CustomerInfoForm';
// import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import * as API from '../utils/APICalls'
import * as helpers from '../utils/helpers'


const ClosedModal = ({ closedModalOpen, setClosedModalOpen, shop, setShop }) => {

    const [email, setEmail] = useState('')
    const [shopSubs, setShopSubs] = useState()


    const handleModalClose = () => {
        setClosedModalOpen(false);

    };

    const handleSubscribe = async (e) => {
        console.log('subscriber:', email)
        e.preventDefault()
        let subs
        if (email) {
            if (!shop?.subscribers) {
                subs = [email]
            } else {
                subs = shop?.subscribers
                subs.push(email)
                setShopSubs(subs)
            }
            console.log('handleSubscribe subs:', subs)
            try {
                const res = await API.updateShop({
                    ...shop,
                    subscribers: shopSubs
                })
                if (res) {
                    setShop(res)
                }

            } catch (err) {
                console.log('[handleSubscribe]', err)
            }
        };
        setEmail('')
        setShopSubs()
        setClosedModalOpen(false);

    }
    useEffect(() => {

    }, [shopSubs])


    return (
        <Modal
            open={closedModalOpen}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Container sx={{
                // p: 1,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                padding: '1rem',
            }}>

                <Box sx={{


                    width: '100%',
                    height: '50%',
                    minHeight: '60vh',
                    overflow: 'hidden',
                    overflowY: 'scroll',
                    maxHeight: '60vh',
                    // maxHeight:'90vh',
                    // maxWidth: '98vw',
                    maxWidth: '600px',
                    // bgcolor: 'background.paper',
                    bgcolor: 'rgba(255, 255, 255, 1)',
                    backdropFilter: 'blur(10px)',
                    border: '2px solid #000',
                    borderRadius: '10px',
                    boxShadow: 24,
                    // p: 1,
                    margin: 'auto',


                }}>
                    <CloseIcon
                        className='button-woddle'
                        onClick={handleModalClose} color='#000' sx={{
                            position: 'absolute',
                            right: '0.5rem', top: '0.5rem', zIndex: 99, cursor: 'pointer'
                        }} />
                    {/* <Stack direction="row" spacing={1} alignItems="center" justifyContent='center'>
                    <Typography>Map</Typography>
                    <Switch defaultChecked inputProps={{ 'aria-label': 'ant design' }} onChange={(e)=>{
                        if(modalView ==='map'){
                            setModalView('menu')
                        }
                        if(modalView ==='menu'){
                            setModalView('map')
                        }
                    }} checked={modalView ==='menu'} />
                    <Typography>Menu</Typography>
                </Stack> */}
                    <Box
                        component='img'
                        src={shop?.config?.avatar_pic}
                        sx={{
                            position: 'absolute',
                            top: '0px',
                            left: '0px',
                            height: '100%',
                            width: '100%',
                            opacity: '0.07',
                            zIndex: 0
                        }}
                    >

                    </Box>

                    <Stack sx={{
                        position: 'absolute',
                        top: '30%',
                        left: '50%',
                        transform: 'translate(-50%, -30%)',
                        // display: 'flex',
                        // flexDirection: 'column',
                        width: '100%',
                        alignContent: 'center',
                        alignItems: 'center',
                        alignSelf: 'center',
                        // p: 1,
                        alignSelf: 'center',
                        justifyContent: 'center',
                        gap: '1rem', textAlign: 'center',
                        padding: '0.75rem',
                        fontFamily: 'Gilroy, sans-serif'


                    }}>
                        <h1 id="modal-modal-title" //variant="h3"
                            style={{ fontFamily: 'Gilroy, sans-serif', fontWeight: 'bold', width: '75%' }}>
                            {/* Apologies! */}
                            Thank you for your interest & support!
                        </h1>
                        {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                          
                          </Typography> */}
                        {/* <Box sx={{border:'1px solid #000'}}> */}
                        <Box sx={{
                            display: 'flex', flexDirection: 'column', border: '1px solid #000', borderRadius: '10px',
                            overflow: 'hidden', overflowY: 'scroll',
                            width: '90%',
                            minHeight: '100%', height: '100%', p: 1,
                            gap: '1rem', padding: '0.5rem',
                            backgroundColor: 'rgba( 255,255,255, 0.5)',
                            backdropFilter: 'blur(10px)',
                            padding: '1rem',
                            boxShadow: '12'
                        }}>


                            <Typography id="modal-modal-title" variant="h4" style={{ fontFamily: 'Gilroy', fontWeight: 'bold' }}>
                                Ordering is currently unavailable.
                            </Typography>
                            <Typography variant="p" style={{ fontFamily: 'Gilroy', }}>
                                Subscribe below to receive updates on promos and opening!
                            </Typography>
                            <Stack sx={{ gap: '1rem', alignItems: 'end', margin: 'auto', marginTop: '1rem', width: '60%' }}>

                                <TextField
                                    label='email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    sx={{ width: '100%', maxWidth: '350px' }}
                                />
                                {!helpers.validateEmail(email) && email ? 'please enter valid email' : ''}
                                <Button variant='contained' //disabled={}
                                    sx={{ //display: 'flex', position: 'relative', margin: '1rem auto'

                                    }}
                                    onClick={handleSubscribe}
                                >
                                    Subscribe
                                    {/* {subView ==='cart' ?'Checkout':'Review Order'} */}
                                </Button>
                            </Stack>
                        </Box>
                        {/* </Box> */}
                    </Stack>



                </Box>
            </Container >
        </Modal >
    )
}

export default ClosedModal