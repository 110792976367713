import React, { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';
import * as mediasoupClient from 'mediasoup-client';
import EnvRoute from '../../../middleware/envRoute';
import { Box, Button, Stack, Typography,Container } from '@mui/material';
import * as API from '../../../utils/APICalls'
import * as helpers from '../../../utils/helpers'

const ENDPOINT = EnvRoute('prod');

const WebRTCComponent = (props) => {
  const { listener,artist,hostList } = props;
  const [currentCameraIndex, setCurrentCameraIndex] = useState(0);
  const [iceConfig, setIceConfig] = useState(null);
  const [routerRTPCaps, setRouterRTPCaps] = useState(null);
  const [videoDevices, setVideoDevices] = useState([]);
  const socketRef = useRef();
  const deviceRef = useRef();
  const producerTransportRef = useRef();
  const consumerTransportRef = useRef();
  const producerRef = useRef();
  const consumersRef = useRef({});
  const [isConnected, setIsConnected] = useState(false);
  const [isJoining, setIsJoining] = useState(false);
  const localVideoRef = useRef();
  const [remoteStreams, setRemoteStreams] = useState({});

  const [retryCount, setRetryCount] = useState(0);
const [isRetrying, setIsRetrying] = useState(false);
const maxRetries = 5; // Maximum number of retry attempts
const retryInterval = 3000; // 3 seconds between retries
const isHost = hostList?.includes(listener?._id )

let  iceCon2 
let  rtpCaps ={}

const connectWithRetry = async () => {
  try{

    setIsRetrying(true);
    if( !routerRTPCaps || !iceConfig ){
        // const { routerRtpCapabilities, iceConfiguration } = await fetch(`${ENDPOINT[0]}/rtc-config`).then(res => res.json());
        const { routerRtpCapabilities, iceConfiguration }= await API.fetchRTPConfig()
        // if(data){
          iceCon2 = iceConfiguration
          rtpCaps = routerRtpCapabilities
          setIceConfig(iceConfiguration);
          setRouterRTPCaps(routerRtpCapabilities);

          localStorage.setItem('rtpCaps',JSON.stringify(routerRtpCapabilities))
          console.log('[iceConfiguration]:',iceCon2)
          // console.log('[iceConfig]:',iceConfig)
          // console.log('[routerRTPCaps]:',routerRTPCaps)
          console.log('[routerRtpCapabilities]:',rtpCaps)
        // }
    }
    const SOCKET_ENDPOINT = ENDPOINT[0].slice(0, -4);
    socketRef.current = io(`${SOCKET_ENDPOINT}/chat` //)
    , {
      transports: ['websocket'],
      upgrade:false,    //?-------
      reconnectionAttempts: maxRetries,
      reconnectionDelay: retryInterval,
    },(res)=>console.log('websocket',res));
  
    socketRef.current.on('connect', () => {
      console.log('Connected to server');
      console.log('Socket ID:', socketRef.current.id);
      console.log('Namespace:', socketRef.current.nsp);
      setIsConnected(true);
      setIsRetrying(false);
      setRetryCount(0);
    });
  
    socketRef.current.on('connect_error', (error) => {
      console.error('Connection error:', error);
      setRetryCount((prevCount) => prevCount + 1);
      if (retryCount >= maxRetries) {
        console.error('Max retries reached. Unable to connect.');
        setIsRetrying(false);
      }
    });
  
    socketRef.current.on('disconnect', () => {
      console.log('Disconnected from server');
      setIsConnected(false);
    });
  
    // Other socket event listeners...
  }catch(err){console.log(err)}
};

useEffect(() => {
  if(artist?.stream?.isStreaming){

    connectWithRetry();
    getVideoDevices();
  }

  return () => {
    if (socketRef.current) {
      socketRef.current.disconnect();
    }
  };
}, []);

useEffect(() => {
 
  
}, [rtpCaps]);

useEffect(() => {
  if (isRetrying && retryCount < maxRetries && artist?.stream?.isStreaming) {
    const timer = setTimeout(() => {
      console.log(`Retrying connection (${retryCount + 1}/${maxRetries})...`);
      connectWithRetry();
    }, retryInterval);

    return () => clearTimeout(timer);
  }
}, [isRetrying, retryCount]);


//   useEffect(() => {
    
//     socketRef.current = io(ENDPOINT[0]);

//     socketRef.current.on('connect', () => {
//       console.log('Connected to server');
//       setIsConnected(true);
//     });

//     socketRef.current.on('disconnect', () => {
//       console.log('Disconnected from server');
//       setIsConnected(false);
//     });

//     getVideoDevices();

//     return () => {
//       socketRef.current.disconnect();
//     };
//   }, []);

  const getVideoDevices = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const video_devices = devices.filter(device => device.kind === 'videoinput');
    setVideoDevices(video_devices);
  };

  const joinRoom = async (roomId, userId) => {
    
    try {
      setIsJoining(true)
    //  rtpCaps = {} = JSON.parse(localStorage.getItem('rtpCaps'))
    //   console.log('Local rtpCaps:',rtpCaps)
      console.log('routerRTPCaps:',routerRTPCaps)

      deviceRef.current = new mediasoupClient.Device();
      await deviceRef.current.load({ routerRtpCapabilities:routerRTPCaps });
      // await deviceRef.current.load(rtpCaps );
      

      socketRef.current.emit('join-room', roomId, userId, isHost)
      //   , (response) => {
      //   console.log('join-room event emitted', roomId, userId);
      //   console.log('Server response:', response);
      // });
      // socketRef.current.on('transportParameters', (params) => {
      //     const transport = deviceRef.current.createSendTransport(params);
      //     console.log('transportParameters transport:', response);
      //   });
     
      socketRef.current.on('transport-created', async (params) => {
        // if(isHost){
          
        // }
        // console.log('transport-created params:',params)
        producerTransportRef.current = deviceRef.current.createSendTransport(params.params);
        
        producerTransportRef.current.on('connect', ({ dtlsParameters }, callback, errback) => {
          socketRef.current.emit('connect-transport', { dtlsParameters, role: 'producer' }, callback);
        });
        producerTransportRef.current.on('produce', async ({ kind, rtpParameters }, callback, errback) => {
          try {
            const { producerId } = await new Promise((resolve) => {
              socketRef.current.emit('produce', { roomId, userId, kind, rtpParameters }, resolve);
           
            });
            callback({ id: producerId });
          } catch (err) {
            errback(err);
          }
        });

        

        const stream = await navigator.mediaDevices.getUserMedia({
          video: { deviceId: videoDevices[currentCameraIndex].deviceId },
          audio: true
        });
        if (localVideoRef.current) {
            localVideoRef.current.srcObject = stream;
          }

        producerRef.current = await producerTransportRef.current.produce({ track: stream.getVideoTracks()[0] });
      });

      socketRef.current.on('new-consumer', async ({ producerId, id, kind, rtpParameters, producerUserId }) => {
        const consumer = await consumerTransportRef.current.consume({
          id,
          producerId,
          kind,
          rtpParameters,
        });

        // consumersRef.current[producerId] = consumer;

        // const stream = new MediaStream([consumer.track]);
        // setRemoteStreams(prev => ({...prev, [producerUserId]: stream}));

         const stream = new MediaStream([consumer.track]);
  
        // Display the stream in a video element
        displayStream(stream, 'video-element');
      });

      setIsJoining(false)
    } catch (error) {
      console.error('Error joining room:', error);
      setIsJoining(false)
    }
  };
function displayStream(stream, elementId) {
  const videoElement = document.getElementById('video-element');
  videoElement.srcObject = stream;
  videoElement.play();
}

  const leaveRoom = (roomId, userId) => {
    socketRef.current.emit('leave-room', { roomId, userId });
    if (producerRef.current) {
      producerRef.current.close();
      producerRef.current = null;
    }
    Object.values(consumersRef.current).forEach(consumer => consumer.close());
    consumersRef.current = {};
    if (producerTransportRef.current) {
      producerTransportRef.current.close();
      producerTransportRef.current = null;
    }
    if (consumerTransportRef.current) {
      consumerTransportRef.current.close();
      consumerTransportRef.current = null;
    }
    setRemoteStreams({});
    if (localVideoRef.current && localVideoRef.current.srcObject) {
      localVideoRef.current.srcObject.getTracks().forEach(track => track.stop());
      localVideoRef.current.srcObject = null;
    }
    // videoTracks.forEach(track => {
    //   localStream.removeTrack(track);
    // });
    // videoElement.srcObject = null;
    
// if (peerConnection) {
//   peerConnection.close();
// }



  };

  const toggleCamera = async () => {
    const newIndex = (currentCameraIndex + 1) % videoDevices.length;
    setCurrentCameraIndex(newIndex);

    if (producerRef.current) {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: videoDevices[newIndex].deviceId },
        audio: true
      });
      const track = stream.getVideoTracks()[0];
      await producerRef.current.replaceTrack(track);
      localVideoRef.current.srcObject = stream;
    }
  };

  const renderRemoteVideos = () => {
    return Object.entries(remoteStreams).map(([userId, stream]) =>{
      if (!isHost){
        return(
  
        <Box key={userId} sx={{ width: '200px', margin: '10px' }}>
          <video
            ref={el => { if (el) el.srcObject = stream; }}
            autoPlay
            playsInline
            style={{ width: '100%', height: 'auto' }}
          />
          <Typography variant="caption">{userId}</Typography>
        </Box>
      )

      }else{return<>hmmmmm</>}
  }
  );
  };
  useEffect(()=>{
    socketRef?.current?.on('host-stream-ended', () => {
    const videoElement = document.getElementById('video-element');
    if (videoElement.srcObject) {
      videoElement.srcObject.getTracks().forEach(track => track.stop());
    }
    videoElement.srcObject = null;
  });
  },[socketRef])
  useEffect(() => {
  const startStream = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: videoDevices[currentCameraIndex].deviceId },
        audio: true
      });
      if (localVideoRef.current) {
        localVideoRef.current.srcObject = stream;
      }
    } catch (error) {
      console.error('Error accessing media devices:', error);
    }
  };

  startStream();
}, [currentCameraIndex]);

  return (
    <>
        {/* <Box sx={{position:'absolute',top:'0px',left:'0px',alignText:'center', justifyContent:'center',width: '100%', maxWidth: '600px',height:'100%'}}> */}
        {/* </Box> */}
      <Stack sx={{position:'relative',gap:'0.5rem', padding:'0.5rem 0'}}>
          <video ref={localVideoRef.isHost? localVideoRef: producerRef} id='video-element' autoPlay muted playsInline style={{width: '100%', maxWidth: '100vw',height:'100vh',minHeight:'600px'}} />
          {/* <video ref={localVideoRef} autoPlay muted playsInline style={{width: '100%', maxWidth: '100vw',height:'100vh',minHeight:'600px'}} /> */}
       
        {/* <Typography>Welcome {listener?.preferredName || 'listener' + listener?._id}</Typography>
       <Typography>
            Connection Status: {isConnected ? 'Connected' : isRetrying ? 'Retrying...' : 'Disconnected'}
            </Typography> */}

       
       {artist?.stream?.isStreaming && <Typography sx={{color:isConnected?'#00FF00':'orange'}}>
            {isConnected && Object.entries(remoteStreams).map.length ? <b>viewers: {Object.entries(remoteStreams)?.map((s)=>s)}</b>:''}
            </Typography>}
       
       {artist?.stream?.isStreaming && <Typography sx={{color:isConnected?'#00FF00':'orange'}}>
            {(isConnected && isHost ) ?'Hosting' :(isConnected && !isHost ) ? 'Connected':isRetrying ? 'Retrying...' : 'Disconnected'} as <b>{listener?.preferredName || 'listener' + listener?._id}</b>
            </Typography>}

        {isConnected  ? <Box sx={{display:"flex", margin:'auto', gap:'0.5rem'}}>
          <Button disabled={isJoining} variant='outlined' onClick={() => joinRoom('room1',  listener?._id)}>Join{isJoining?'ing':''} Room</Button>
          <Button variant='outlined' onClick={() => leaveRoom('room1',  listener?._id)}>Leave Room</Button>
         
          {videoDevices?.length > 1 && (
            <Button variant='outlined' onClick={toggleCamera}>Switch Camera</Button>
          )}
        </Box>:!artist?.stream?.isStreaming ?'':'Refresh to reconnect'}

        <Box sx={{position:'sticky',bottom:'200px', left:'0rem',
          border:'1px solid #00FF00',
          display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width:'100%',height:'100px'}}>
          {renderRemoteVideos()}
        </Box>
      </Stack>
    </>
  );
};

export default WebRTCComponent;