import {
    Box, Container, Stack, Tooltip, Typography,
    List, ListItem, ListItemText,
    IconButton,
    Button,
} from '@mui/material'
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import React, { useEffect, useState, useRef, useContext } from 'react'
import { Link, useParams } from 'react-router-dom';
import Copyright from '../../../components/Copyright';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import useWindowDimensions from "../../../hooks/windowDimensions";
import InfoIcon from '@mui/icons-material/Info';
import CloseButton from '../../../components/CloseButton';
import ShareIcon from '@mui/icons-material/Share';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomModal from './ZoomModal';
import { Favorite, FavoriteBorder, PointOfSaleSharp, SkipNext, SkipPrevious } from '@mui/icons-material';
import LyricsIcon from '@mui/icons-material/Lyrics';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import CustomProgressBar from './CustomProgressBar';
import StreamLinks from './StreamLinks';
import * as API from '../../../utils/APICalls'
import * as helpers from '../../../utils/helpers'
import AddComment from './AddComment';
import Visualizer from '../../../components/Visualizer';

const RexPlaylist = () => {
    const { id } = useParams()
    const playerRef = useRef(null);
    const iframeRef = useRef(null);
    const audioRef = useRef(null);

    const [isLoading, setIsLoading] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const [hideCopy, setHideCopy] = useState(true)
    const [currentComment, setCurrentComment] = useState('')
    const [trkNum, setTrkNum] = useState(0)
    const [playlist, setPlaylist] = useState()
    const [playAudio, setPlayAudio] = useState()
    const [currentTime, setCurrentTime] = useState(0);
    const [timestamp, setTimestamp] = useState(0)
    const [comments, setComments] = useState('')
    const [showLyrics, setShowLyrics] = useState(false)
    const [selectedLyrics, setSelectedLyrics] = useState()

    const [showComments, setShowComments] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false)
    const [albums, setAlbums] = useState()
    const [rankedTracks, setRankedTracks] = useState([])
    const [artist, setArtist] = useState({})
    const [isMuted, setIsMuted] = useState(false)
    const [zoomVisual, setZoomVisual] = useState(false)
    const artistName = 'Rex Entropy'
    const artist_id = "66f5e536da93fc184974748c"
    var albs

    // useEffect(() => {

    //     const iframe = iframeRef.current;

    //     const handleIframeLoad = () => {
    //         try {

    //             const audioSource = playerRef?.current?.src;
    //             console.log('posting message', audioSource)
    //             console.log('posting message to', iframeRef?.current)
    //             iframe.contentWindow.postMessage({ type: 'audioData', audioSource }, '*');
    //         } catch (err) { console.log(err) }
    //     };

    //     // playerRef?.current.addEventListener('load', () => {
    //     if (isPlaying) {

    //         iframe.addEventListener('load', handleIframeLoad);
    //     }
    // }

    // );

    //     return () => {
    //         iframe.removeEventListener('load', handleIframeLoad);
    //     };
    // }, [playerRef, iframeRef, isPlaying]);


    const demoPlaylistIds = [
        '67799f0dc64df3a4f409ba2e',
        '67204636896cc1c832fa9e76',
        '66e46bf97cfbc33ce5538d4a',
        '66e46bf97cfbc33ce5538d4b',
        '66e46bf97cfbc33ce5538d4c',
        '66e46bf97cfbc33ce5538d4d',
        '66e46bf97cfbc33ce5538d4f',
        '66e46bf97cfbc33ce5538d59',
        '66e46bf97cfbc33ce5538d55',
    ]


    function calculateTrackRanking(albums, recentDays = 30) {
        const now = new Date();
        const recentThreshold = new Date(now.setDate(now.getDate() - recentDays));

        const rankedTracks = albums?.flatMap(album => {
            return album.tracks.map(track => {
                const uniqueListeners = track.listeners.length;
                const totalPlays = track.listeners.reduce((sum, listener) => sum + listener.plays.length, 0);

                let completionSum = 0;
                let recentPlays = 0;

                track.listeners.forEach(listener => {
                    listener.plays.forEach(play => {
                        const completionPercentage = (play.length / track.duration) * 100;
                        completionSum += completionPercentage;

                        if (new Date(play.date) >= recentThreshold) {
                            recentPlays++;
                        }
                    });
                });

                const avgCompletionPercentage = totalPlays > 0 ? completionSum / totalPlays : 0;
                const recencyFactor = totalPlays > 0 ? recentPlays / totalPlays : 0;

                // Calculate ranking score
                const rankingScore = (
                    (uniqueListeners * 0.3) +
                    (totalPlays * 0.3) +
                    (avgCompletionPercentage * 0.2) +
                    (recencyFactor * 0.2)
                ) * 100; // Scale up for readability

                return {
                    albumId: album._id,
                    albumName: album.title,
                    trackId: track._id,
                    trackName: track.name,
                    uniqueListeners,
                    totalPlays,
                    avgCompletionPercentage,
                    recencyFactor,
                    rankingScore: Math.round(rankingScore * 100) / 100 // Round to 2 decimal places
                };
            });
        }).sort((a, b) => b.rankingScore - a.rankingScore);

        return rankedTracks;
    }
    // var rankedTracks

    const handleSeek = (time) => {
        console.log('[Seek Click]', time)
        const playerElement = playerRef?.current?.audio;
        console.log('[Seek Click] playerRefRef.current.audio.current.currentTime ', playerRef?.current)
        if (playerRef.current) {
            playerRef.current.audio.current.currentTime = time;
        }
    };

    useEffect(() => {
        const fetchArtist = async () => {
            try {
                const result = await API.fetchArtist(artist_id)
                if (result) {
                    console.log("[fetchArtist]:", result?.albums)
                    albs = [] = result?.albums
                    setArtist(result)
                    setAlbums(albs)
                    if (albs) {

                        setRankedTracks(calculateTrackRanking(albs))
                        console.log("[fetchArtist] rankedTracks:", rankedTracks)
                    }
                    //  console.log("[fetchArtist] albums:", albs) 
                    // setLoading(false)
                }
            } catch (err) { console.log(err) }
        }

        fetchArtist()

    }, [])

    useEffect(() => {
        const generatePlaylist = async () => {
            try {

                const res = await API.fetchTracks(demoPlaylistIds)
                if (res) {
                    setPlaylist(res)
                    setPlayAudio(res[trkNum])
                } else {
                    console.log('Error Loading Tracks')
                }
            } catch (err) {
                console.log('Error Generating Playlist', err)
            }
        }
        generatePlaylist()
    }, [])

    useEffect(() => {
        const checkForComment = () => {
            playAudio?.comments?.forEach((c, i) => {
                if (Math.round(currentTime) == Math.round(c.time) && currentTime <= Math.round(c.time) + 15) {
                    setShowComments(true)
                    setCurrentComment(c)
                    console.log('Comment Popup', c)
                    return c
                } else {
                    setCurrentComment('')
                    setShowComments(false)

                }
            })

        }
        checkForComment()
    }, [currentTime]);

    const handlePlay = () => {
        setIsPlaying(true);

    }
    const handlePause = () => {
        try {

            setIsPlaying(false);
            if (audioRef.current) {
                console.log('audioRef.current', audioRef.current)
                // console.log('audioRef', audioRef)
                audioRef.current.stop();
                audioRef.current = null;
                // audioRef.stop();

            }
        } catch (err) {
            throw new Error(err)
        }
    }

    const togglePlay = () => {
        // playerRef.current.audio.current.muted = true
        playerRef.current.audio.current.play();

    }
    const togglePause = () => {
        playerRef.current.audio.current.pause();
    }


    const handleListen = () => {
        if (playerRef.current) {
            setCurrentTime(playerRef.current.audio.current.currentTime);
        }
    };

    const handleLikeToggle = async (item) => {
        console.log('like click')
    }

    const handleTrackNext = async (click) => {
        try {

        } catch (err) {
            console.log(err)
        }
    }
    const handleTrackBack = async (click) => {
        try {

        } catch (err) {
            console.log(err)
        }
    }

    const handleWaiting = () => {
        setIsLoading(true);
        setIsLoaded(false);
    };

    const handleCanPlay = () => {
        setIsLoading(false);
        setIsLoaded(true);
    };
    useEffect(() => {

        // console.log('playAudio:', playAudio)
    }, [playAudio, trkNum, rankedTracks, selectedLyrics, isPlaying])

    const containerStyle = {
        position: 'relative',
        margin: 'auto',
        width: '95%',
        height: '95vh',
        width: '100%',
        maxWidth: '400px',
        backgroundColor: 'rgba( 0, 0, 0, 0.6)',
        backgroundImage: 'url("/assets/BodyBG2.png")',
        // backgroundImage:'url("/assets/Gradient_01_Frame2.png")',
        backgroundAttachment: 'cover',
        backgroundRepeat: 'no-repeat',
        border: '2px solid #000',
        boxShadow: 'inset 0px 0px 60px 40px rgba(4, 16, 42, 0.6)',
        borderRadius: '5px',
        overflow: 'hidden',
        overflowY: 'scroll',
    }
    const itemStyles = {
        display: 'flex',
        flexDirection: 'column',
        // position: item?._id === playAudio?._id ? 'sticky' : 'relative',
        position: 'relative',
        top: 0,
        padding: '0.5rem',
        width: '99%',
        cursor: 'pointer',
        gap: '0.5rem',
        // height: showInfo ?'fit-content':'4.5rem',

        // minHeight:'50px',
        color: '#00FF00',
        fontFamily: 'Gilroy, sans-serif',
        margin: '0.5rem auto',
        outline: '1px solid #757575',
        boxShadow: '0px 0px 3px 1px #00FF00',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba( 0, 0, 0, 0.5)',

        // boxShadow: playAudio?._id === item?._id ? '0px 0px 8px #00ff04' : '',
        borderRadius: '5px',
        '&:hover': {
            // borderRadius: '10px',
            boxShadow: 'inset 0px 0px 5px 3px rgba( 0, 255, 0, 0.5)',
            // outline: '1px solid rgba(144, 254, 195,0.5)',
            outline: '1px solid rgba( 0, 255, 0,0.5)',
            // background: 'rgba(144, 254, 195,0.5)'
        }
    }
    const closedVisualStyle = {
        backgroundColor: 'rgba(0 ,0, 0,0.5)',
        width: '100%',
        height: 'auto',
        minHeight: '300px',
        // maxHeight: '300px',
        margin: 'auto'
    }
    const openVisualStyle = {
        position: 'absolute',
        top: '-1.5rem',
        left: '-1rem',
        zIndex: 999999,
        backgroundColor: '#000',
        width: '100vh',
        height: '100vh',
        overflow: 'scroll'
    }
    useEffect(() => {

    }, [playAudio, trkNum])

    return (
        <Container style={containerStyle}>

            <Stack sx={{
                position: 'sticky', top: '2rem',
                zIndex: 99999,

            }}>
                {/* <CustomProgressBar className='custom-progress-bar'
                    setTimestamp={setTimestamp}
                    setCommentModalOpen={setCommentModalOpen}
                    currentTime={currentTime}
                    markers={playAudio?.comments}
                    duration={playAudio?.duration}
                    onSeek={handleSeek}
                /> */}
                {/* <CustomProgressBar className='custom-progress-bar-2'
                    setTimestamp={setTimestamp}
                    // setCommentModalOpen={setCommentModalOpen}
                    currentTime={currentTime}
                    markers={playAudio?.comments}
                    duration={playAudio?.duration}
                    onSeek={handleSeek}
                /> */}

                <Box className='playlist-title-bar' id='scroll-container-3' sx={{
                    margin: 'auto',
                    width: '99%',
                    backgroundColor: 'rgba(0, 0, 0, 1)',
                    borderRadius: '10px 10px 0px 0px',
                    // borderRadius: '0px',
                    fontSize: '0.9rem',
                    fontFamily: 'LCD2,sans-serif',
                    alignContent: 'center',

                }}
                >
                    <div id='scroll-text'>

                        {playlist ? `${artistName} - ${playAudio?.subtitle ? playAudio?.name + ' ' + playAudio?.subtitle : playAudio?.name}` : `Select a track!`}
                    </div>
                </Box>
                <Box sx={{ position: 'relative', height: '100%', width: '100%', overflow: 'hidden', zindex: 0 }}>

                    {!isPlaying && <Box
                        id='cover-frame'
                        component={'img'}

                        ref={iframeRef}
                        // src={!isPlaying ? playAudio?.images[0] : 'http://localhost:5173/'}
                        src={playAudio?.images[0]}
                        sx={zoomVisual ? openVisualStyle : closedVisualStyle}
                    // onClick={() => { setZoomVisual(!zoomVisual) }}
                    >
                        {/* &nbsp; */}
                    </Box>}

                    {/* {(isPlaying && playAudio) && <iframe
                        id='visualizer-frame'
                        // component={'iframe'}
                        ref={iframeRef}
                        src={'/visualizer/index.html'}
                        frameBorder="0"
                        width="100%"
                        height="100%"
                        style={zoomVisual ? openVisualStyle : closedVisualStyle}
                        onClick={() => { setZoomVisual(!zoomVisual) }}
                        onLoad={() => {
                            try {

                                const audioSource = playAudio?.track_url;
                                console.log('posting message', audioSource)
                                console.log('posting message to', iframeRef?.current)

                                iframeRef.current.contentWindow.postMessage({ type: 'audioData', audioSource }, '*');
                            } catch (err) {
                                console.log(err)
                                throw new Error(err)
                            }
                        }}
                    >
                     
                    </iframe>} */}
                    {(isPlaying && playAudio) && <Visualizer
                        audioURL={playAudio?.track_url}
                        isPlaying={isPlaying}
                        playerRef={playerRef}
                        currentTime={currentTime}
                        audioRef={audioRef}
                        style={zoomVisual ? openVisualStyle : closedVisualStyle}
                    // onClick={() => { setZoomVisual(!zoomVisual) }}

                    />}
                    {showComments && <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        display: 'flex', flexDirection: 'column', background: 'rgba(0,0,0,0.8)',
                        backdropFilter: 'blur(5px)',
                        boxShadow: '0px 0px 10px 5px rgba(0, 255, 0 ,0.5)', zIndex: 999999999,
                        overflow: 'hidden', overflowY: 'scroll',
                        width: '340px', height: 'fit-content', gap: '0.5rem', color: '#00FF00', borderRadius: '5px',

                    }}>
                        <Box sx={{ position: 'sticky', top: 0, left: 0, width: '100%', height: '1rem', background: '#F6F6F6' }}>
                            <Typography sx={{ color: '#858585', fontSize: '0.75rem' }}>Comments</Typography>
                        </Box>

                        <Box sx={{
                            position: 'relative', minHeight: '100px',
                            // padding: '0.5rem',
                            // margin: 'auto', width: '80%', border: '1px solid red',
                            width: '100%'
                        }}>

                            <Box sx={{ display: 'flex', position: 'absolute', left: '0.5rem', top: '0.25rem', fontFamily: "source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace" }}>
                                <Typography // variant="caption" 
                                    sx={{ textAlign: 'left', fontFamily: "source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace" }}
                                >
                                    <p style={{ color: '#FF00FF' }}>{currentComment?.listener?.name ? currentComment?.listener?.name : 'Anonymous'}:&nbsp; </p>
                                </Typography>

                                <Typography // variant="body1"
                                    sx={{ fontFamily: "source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace" }}
                                >
                                    "{currentComment.comment}"
                                </Typography>
                            </Box>
                            <br />
                            <Stack //variant="caption"
                                sx={{ textAlign: 'right', position: 'absolute', right: '0.5rem', bottom: '0.25rem', fontFamily: "LCD2,sans-serif", }}>
                                <b style={{ fontSize: '0.7rem', }}>@ {helpers.secsToMins(currentComment?.time)}</b>
                                <Typography style={{ fontSize: '0.8rem', marginTop: '0', fontFamily: "LCD2,sans-serif", }}>

                                    {currentComment?.date ? helpers.getDisplayDate(currentComment?.date) : '-'}
                                </Typography>
                            </Stack>

                        </Box>
                    </Box>}
                </Box>

                <div className={`audio-player-container ${isLoaded ? 'loaded' : ''}`}
                    id='playlistPlayer'
                    style={{ marginTop: '0rem', position: 'relative' }}
                >

                    {/* {playlist &&  */}
                    <AudioPlayer
                        ref={playerRef}
                        onListen={handleListen}
                        listenInterval={1000}
                        volume={0}
                        className="player"
                        // crossorigin="anonymous"
                        onPause={handlePause}
                        onEnded={() => handleTrackNext({ click: false })}
                        onClickNext={() => handleTrackNext({ click: true })}
                        onClickPrevious={() => handleTrackBack({ click: true })}
                        onCanPlay={handleCanPlay}
                        onWaiting={handleWaiting}
                        onPlay={handlePlay}

                        autoPlayAfterSrcChange
                        src={playAudio?.track_url}
                        muted={true}
                        // src={playlist?.length ? playlist[trkNum]?.track_url : ''}
                        showJumpControls={false}
                        showProgressBar={false}
                        // layout="stacked-reverse"
                        layout="stacked"

                        customControlsSection={[
                            // <Box sx={{
                            //     display: 'flex',
                            //     alignContent: 'center', alignItems: 'center',
                            //     textAlign: 'center', justifyContent: 'center',
                            // }}

                            //     onClick={() => handleLikeToggle(playAudio)}>

                            //     <Tooltip title='like'>
                            //         <FavoriteBorder />
                            //     </Tooltip>

                            // </Box>,
                            // <Box onClick={() => {
                            //     try {
                            //         console.log('[current Time]:', currentTime)
                            //         setTimestamp(currentTime)
                            //         // setCommentModalOpen(true)
                            //     } catch (err) { console.log(err) }
                            // }}>
                            //     <AddComment />
                            // </Box>
                            // ,
                            // "LOOP",
                            // "MAIN_CONTROLS",
                            // "VOLUME_CONTROLS"
                        ]}

                        // muted={isMuted}
                        showSkipControls
                        showDownloadProgress={true}
                        // crossOrigin = {'anonymous'}
                        crossOrigin={window?.location?.href?.includes('://localhost:') ? false : 'anonymous'}
                        onError={e => console.log("[ -----Audio------- ]Error: ", e)}
                        // header={playlist ? `${artistName} - ${playAudio?.subtitle ? playAudio?.name + ' ' + playAudio?.subtitle : playAudio?.name}` : `Select a track!`}
                        // header={audioObj?.name ? headerText : album?.artist}
                        style={{
                            color: '#00FF00',
                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            backdropFilter: 'blur(5px)',
                            borderRadius: '0 0 10px 10px',
                            boxShadow: '0px 0px 5px 3px rgba(0, 255, 0, 0.5),inset 0px 0px 10px 5px rgba( 0,255,0,0.5)'//, 0px 2px 5px 1px rgba(0,0,0,0.2) '
                            ,
                            // padding: '0.5rem',
                            width: '100%',
                            outline: '1px solid #00FF00',
                            position: 'sticky', top: '0px', zIndex: 999
                        }}
                    />
                    {/* } */}
                    <Box sx={{
                        position: 'absolute',
                        bottom: '0.5rem', left: '50%',
                        transform: 'translate(-50%,0%)',
                        width: '84px', height: '8px',
                        backgroundColor: 'rgba(0,0,0,0.8)',
                        borderRadius: '5px',
                        boxShadow: '0px 5px 5px 3px rgba(0, 0, 0, 0.5)',
                        // border: '1px solid red',
                        zIndex: 999999
                    }}>
                        &nbsp;
                    </Box>
                    <Box style={{
                        display: 'flex',
                        alignContent: 'center',
                        alignItems: 'center',
                        padding: '0.5rem',
                        color: '#00FF00',
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        backdropFilter: 'blur(5px)',
                        borderRadius: '0 0 10px 10px',
                        boxShadow: '0px 5px 5px 3px rgba(0, 255, 0, 0.5),inset 0px 0px 5px 3px rgba( 0,255,0,0.5)'//, 0px 2px 5px 1px rgba(0,0,0,0.2) '
                        ,
                        padding: '0.25rem',
                        height: '1.5rem',
                        width: '75px',
                        border: '1px solid #00FF00',
                        position: 'absolute',
                        bottom: '-1.25rem', left: '50%',
                        transform: 'translate(-50%,0%)',
                        zIndex: 999,
                        borderTop: 'none',
                        // borderTop: '0px solid rgba(0,0,0,1)',
                        margin: 'auto'
                    }}>
                        <Tooltip title='back'>

                            <SkipPrevious
                                className='button-woddle'
                                sx={{
                                    cursor: 'pointer',
                                    color: '#FF00FF',
                                    // color: '#00FF00',
                                    height: '1rem',
                                    alignSelf: 'left'
                                }}
                                onClick={(e) => {
                                    try {
                                        // if (duration >= 5) {
                                        //     saveListen(true, 'other track')
                                        // }
                                        setPlayAudio(playlist[trkNum - 1])
                                        setTrkNum(trkNum - 1)
                                        // setPlayAlbum(albums[albumNav])
                                        // setAudioObj(albums[albumNav]?.tracks[index])

                                        togglePlay()
                                        // togglePause()

                                    } catch (err) { console.log('Track Clkic]:', err) }
                                }}
                            />
                        </Tooltip>
                        {!isPlaying ? <Tooltip title='play'>

                            <PlayCircleIcon
                                className={`button-woddle ${isPlaying ? '' : 'blink'}`}
                                sx={{
                                    cursor: 'pointer',
                                    color: '#FF00FF',
                                    // color: '#00FF00',
                                    height: '1rem',
                                    alignSelf: 'left'
                                }}
                                onClick={(e) => {
                                    try {
                                        // if (duration >= 5) {
                                        //     saveListen(true, 'other track')
                                        // }
                                        // setTrkNum(index)
                                        // setPlayAlbum(albums[albumNav])
                                        // setAudioObj(albums[albumNav]?.tracks[index])
                                        // setPlayAudio(playlist[trkNum])

                                        togglePlay()
                                        // togglePause()

                                    } catch (err) { console.log('Track Clkic]:', err) }
                                }}
                            />
                        </Tooltip>
                            :
                            <Tooltip title='plause'>

                                <PauseCircleIcon
                                    className='button-woddle'
                                    sx={{
                                        cursor: 'pointer',
                                        color: '#FF00FF',
                                        // color: '#00FF00',
                                        height: '1rem',
                                        alignSelf: 'left'
                                    }}
                                    onClick={(e) => {
                                        try {

                                            togglePause()

                                        } catch (err) { console.log('Track Clkic]:', err) }
                                    }}
                                />
                            </Tooltip>
                        }
                        <Tooltip title='next'>

                            <SkipNext
                                className='button-woddle'
                                sx={{
                                    cursor: 'pointer',
                                    color: '#FF00FF',
                                    // color: '#00FF00',
                                    height: '1rem',
                                    alignSelf: 'left'
                                }}
                                onClick={(e) => {
                                    try {
                                        // if (duration >= 5) {
                                        //     saveListen(true, 'other track')
                                        // }
                                        // setTrkNum(index)
                                        // setPlayAlbum(albums[albumNav])
                                        // setAudioObj(albums[albumNav]?.tracks[index])
                                        setPlayAudio(playlist[trkNum + 1])
                                        setTrkNum(trkNum + 1)
                                        // setPlayAlbum(albums[albumNav])
                                        // setAudioObj(albums[albumNav]?.tracks[index])
                                        togglePlay()
                                        // togglePause()

                                    } catch (err) { console.log('Track Clkic]:', err) }
                                }}
                            />
                        </Tooltip>
                    </Box>
                </div>


                <List sx={{
                    position: 'relative', overflow: 'hidden',
                    overflowY: 'scroll', maxHeight: '45vh',
                    padding: '0.5rem'
                }}>
                    {showLyrics && <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        display: 'flex', flexDirection: 'column', background: 'rgba(0,0,0,0.8)',
                        backdropFilter: 'blur(5px)',
                        boxShadow: '0px 0px 10px 5px rgba(0, 255, 0 ,0.5)', zIndex: 99999999999,
                        overflow: 'hidden', overflowY: 'scroll',
                        width: '340px', height: 'fit-content',
                        maxHeight: '250px', gap: '0.5rem', color: '#00FF00', borderRadius: '5px',

                    }}>
                        <Box sx={{ position: 'sticky', top: 0, left: 0, width: '100%', height: '1.5rem', background: '#F6F6F6' }}>
                            <Box sx={{ position: 'absolute', left: '0.25rem', top: '0.125rem', display: 'flex', width: '1rem', height: '1rem', fontSize: '0.8rem', borderRadius: '20px', cursor: 'pointer', color: '#000', background: '#f66969', textAlign: 'center', justifyContent: 'center' }}
                                onClick={() => {
                                    setShowLyrics(false)
                                }}>
                                x
                            </Box>
                            <Typography sx={{ color: '#858585', fontSize: '0.85rem' }}>{selectedLyrics?.name} {selectedLyrics?.subtitle ? selectedLyrics?.subtitle : <>&nbsp;</>}&nbsp;-&nbsp;Lyrics</Typography>
                        </Box>

                        <Box sx={{ position: 'relative', width: '100%', height: '100%', overflow: 'hidden', overflowY: 'scroll', padding: '0.5rem' }}>

                            <Typography
                                //  variant='p' 
                                sx={{
                                    position: 'relative',
                                    maxWidth: '400px', width: '100%',
                                    //  border:'1px solid red',
                                    textAlign: "left", whiteSpace: 'pre-wrap', padding: '0.5rem',
                                    fontSize: '0.8rem'
                                }}>
                                {/* <Box sx={{ height: '1rem' }}>
                                    &nbsp;
                                </Box> */}
                                {/* <b style={{ margin: 'auto' }}>
                                    Lyrics:
                                </b>
                                <br /> */}
                                {playAudio?.lyrics}
                            </Typography>
                            {/* </Box> */}

                        </Box>
                    </Box>}
                    {playlist && playlist?.map((item, i) => {
                        return (
                            <ListItem
                                key={i}
                                sx={{
                                    ...itemStyles,
                                    height: item?._id === playAudio?._id ? '5rem' : '3rem',
                                }}>
                                {/* <Box sx={{ width: '100%', height: '0.2rem', textAlign: 'center' }}>
                                </Box> */}
                                {item?._id === playAudio?._id && <CustomProgressBar className='custom-progress-bar-2'
                                    setTimestamp={setTimestamp}
                                    // setCommentModalOpen={setCommentModalOpen}
                                    currentTime={currentTime}
                                    markers={playAudio?.comments}
                                    duration={playAudio?.duration}
                                    onSeek={handleSeek}
                                />}
                                <Box sx={{
                                    position: 'relative',
                                    display: 'flex', //gap: '0.5rem',
                                    width: '100%', textAlign: 'center', alignContent: 'center',
                                    alignItems: 'center', alignSelf: 'center',
                                    justifyContent: 'space-between'
                                }}>

                                    {(!isPlaying || playlist[i] !== playAudio) && <Tooltip title='play'>

                                        <PlayCircleIcon
                                            className='button-woddle'
                                            sx={{
                                                cursor: 'pointer',
                                                color: '#FF00FF',
                                                // color: '#00FF00',
                                                height: '0.9rem',
                                                alignSelf: 'left'
                                            }}
                                            onClick={(e) => {
                                                try {
                                                    // if (duration >= 5) {
                                                    //     saveListen(true, 'other track')
                                                    // }
                                                    // setTrkNum(index)
                                                    // setPlayAlbum(albums[albumNav])
                                                    // setAudioObj(albums[albumNav]?.tracks[index])
                                                    setPlayAudio(playlist[i])

                                                    togglePlay()
                                                    // togglePause()

                                                } catch (err) { console.log('Track Clkic]:', err) }
                                            }}
                                        />
                                    </Tooltip>

                                    }

                                    {(isPlaying && playlist[i] === playAudio) && <Tooltip title='plause'>

                                        <PauseCircleIcon
                                            className='button-woddle'
                                            sx={{
                                                cursor: 'pointer',
                                                color: '#FF00FF',
                                                // color: '#00FF00',
                                                height: '0.9rem',
                                                alignSelf: 'left',

                                            }}
                                            onClick={(e) => {
                                                try {

                                                    togglePause()

                                                } catch (err) { console.log('Track Clkic]:', err) }
                                            }}
                                        />
                                    </Tooltip>}




                                    <Box component={'img'}
                                        src={item?.images[0]}
                                        sx={{
                                            width: '30px',
                                            height: '30px',
                                            border: '1px solid #00FF00',
                                            borderRadius: '2px',
                                            boxShadow: '0px 0px 3px 1px rgba( 0, 255,0,0.6),inset 0px 0px 5px 3px rgba( 0, 255,0,0.3) ',
                                        }}>

                                    </Box>
                                    <Stack sx={{ gap: '0.25rem', textAlign: 'left', width: '120px' }}>
                                        <Typography variant='p'
                                            sx={{ fontSize: '0.7rem', }}>
                                            {item?.name}
                                        </Typography>
                                        <Typography variant='p'
                                            sx={{ fontSize: '0.7rem', }}>
                                            {item?.subtitle ? item?.subtitle : <>&nbsp;</>}
                                        </Typography>
                                    </Stack>

                                    <Tooltip className='blink' title='lyrics' sx={{ color: "#757575", }}>
                                        <LyricsIcon
                                            className='button-woddle'
                                            onClick={(e) => {
                                                setSelectedLyrics(item)
                                                setShowLyrics(!showLyrics)
                                            }}
                                        />
                                    </Tooltip>
                                    <Typography variant='p'
                                        sx={{ fontSize: '0.7rem', alignSelf: 'right', textAlign: 'end' }}>
                                        ( {helpers.secsToMins(item?.duration)} )
                                    </Typography>
                                    {rankedTracks?.length > 0 && <Tooltip title='ranking' style={{
                                        width: '2rem',
                                        display: 'flex',
                                        fontSize: '0.7rem', gap: '0.25rem', textAlign: 'center', justifyContent: 'center', alignContent: 'center', alignItems: 'center', //width: '4rem'

                                    }}>
                                        {item?.listeners?.length ? rankedTracks?.findIndex(track => track.trackId === item?._id) + 1 : '0'}
                                        <ShowChartIcon sx={{ fontSize: '0.7rem', color: '#FF00FF' }} />

                                    </Tooltip>}
                                </Box>

                            </ListItem>
                        )
                    })
                    }
                </List>




            </Stack>

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'fixed',
                bottom: '0rem',
                left: '50%',
                transform: 'translate(-50%,20%)',
                width: '300px',
                height: hideCopy ? '0' : '7.5rem',
                zIndex: 999999,
            }}>

                <Box sx={{ position: 'relative', alignSelf: 'start', width: '100%', }} onClick={() => {
                    setHideCopy(!hideCopy)
                }}>

                    {!hideCopy ?
                        <Box sx={{ position: 'absolute', left: '0.5rem', top: '0.5rem', zIndex: 999 }}>
                            <CloseButton />
                        </Box>
                        : <Box sx={{
                            display: 'flex',
                            position: 'absolute',
                            bottom: '0rem',
                            left: '50%',
                            transform: 'translate(-50%,0)',
                            border: '2px solid #858585',
                            padding: '0.25rem 1.25rem',
                            backgroundColor: '#fff', cursor: 'pointer',
                            alignItems: 'center',
                            boxShadow: '0px -1px 7px 2px rgba(0,0,0,0.2)',
                            borderRadius: '5px 5px 0px 0px'
                        }}>
                            <ArrowDropUpIcon />
                            about us
                        </Box>}
                    {!hideCopy && <Copyright view='signupSheet'
                        hide={hideCopy}
                        color={'#858585'}
                        shadow={true}
                    />}
                </Box>
            </Box>
        </Container >
    )
}

export default RexPlaylist