import ContactPhone from '@mui/icons-material/ContactPhone'
import { Container, Modal,Box, Button, Input, Typography, TextField, Stack, IconButton, InputAdornment, Chip, FormControlLabel } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CloseButton from '../../components/CloseButton'
import { Add, AddCircle, CheckBox, Link } from '@mui/icons-material'
import * as API from '../../utils/APICalls'
import * as helpers from '../../utils/helpers'

const ShopShareModal = (props) => {
    const {shopShareModalOpen, setShopShareModalOpen, item, track,
        album,
        // validateEmail,
        listener, setListener } = props
    const [ currentEmail, setCurrentEmail ] = useState('')
    const defaultMessage =`
    Hey! Peep this!`
    const [ message, setMessage ] = useState(defaultMessage)
    const [ emails, setEmails ] = useState([])
    // const [ shares, setShares ] = useState(listeners?.shares?.length > 0 ? listeners?.shares : [])
    const [ preferredName, setPreferredName ] = useState('')
    const [ updatePreferredName, setUpdatePreferredName ] = useState(false)
    const [ actionError, setActionError ]= useState('')
    let name = listener?.preferredName ? listener?.preferredName : listener?.email 
    
    const handleShare = async () =>{
        if(emails.length < 1){
            setActionError('Please enter email(s) to share with')
            return
        }
            try{
                // console.log('[Share] listener:',listener)
                let shares = listener?.shares?.length > 0 ? listener?.shares : []

                if( updatePreferredName ){
                    name = preferredName
                }

                const shareObj = {
                    item: item? item?._id :'n/a',
                    item_name: item? `${item?.title} - ${item?.subtitle ?  track?.name +' '+ track?.subtitle : track?.name }`:'n/a',
                    // track_title: track? `${album?.artist} - ${track?.subtitle ?  track?.name +' '+ track?.subtitle : track?.name }`:'n/a',
                    name: name ,
                    message: message,
                    emails: emails
                }

                if(shares?.length < 1){
                    shares = [shareObj]
                }else{
                    shares.push(shareObj)
                }

            const emailRes = await API.shareMusic(listener?.email,shareObj)
            if(emailRes){
                const data = await API.updateListener({
                        ...listener,
                        shares,
                        preferredName: ( updatePreferredName && preferredName ) ? preferredName : listener?.preferredName? listener?.preferredName:''
                    })
                    // console.log('handleShare data:', data)
                    if(data){
                       setListener(data)
                        setShopShareModalOpen(false)
                    }else{setActionError('an error occurred')}
            }else{setActionError('an error occurred')}
        }catch(err){console.log(err)}
    }


    const removeEmailFromList = (emailToRemove) => {
    setEmails((prevEmails) => prevEmails.filter(email => email !== emailToRemove));
  };


    const emailDisplay = () =>{
        const list = emails?.map((email,i)=>{
            return email
        })
        
        return list
    }
    
    // useEffect(()=>{
    //     try{
    //         const l = JSON.parse(localStorage.getItem('listener'))
    //         if(!listener?.email && l?.email){
    //             setListener(l)
    //         }
    //     }catch(err){console.log(err)}
    // },[listener])

    useEffect(()=>{

    },[emails])

  return (
    <>
        <Modal 
            open={shopShareModalOpen}
            // open={shareModalOpen}
            onClose={()=>setShopShareModalOpen(false)}
            >
            <Container sx={{zIndex:9999999}}>
                <Box sx={{ 
                    display:'flex',
                    flexDirection:'column',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '300px',
                    minHeight:'550px',
                    
                    backgroundColor:'#FFF',
                    borderRadius:'10px',
                    border:'2px solid #000',
                    fontFamily: 'Gilroy, sans-serif',
                    // justifyContent:'center',
                    textAlign:'center',
                    // alignItems:'center',
                    gap:'0.5rem',
                    padding:'0.5rem'
                    
                    }}>
                        <Box sx={{position:'absolute', top:'-0.5rem', right:'-0.5rem'}}>
                            <CloseButton func={setShopShareModalOpen} value={shopShareModalOpen}
                            />
                        </Box>
                        <Stack sx={{gap:'0.5rem', width:'100%', }}>
                            <u><h2 style={{fontWeight:'bold'}}>
                            Thank You For Sharing!
                            </h2> 
                            </u>
                          <h3>  {track? `${album?.artist} - ${track?.subtitle ?  track?.name +' '+ track?.subtitle : track?.name }`:''}</h3>
                             <TextField label='preferred name'
                                value={!preferredName? name : preferredName}
                                onChange={(e)=>{setPreferredName(e.target.value)}}
                                />
                         {/* <FormControlLabel
                         sx={{display:'flex', flexDirection:'row', margin:'auto', gap:'0.5rem'}}
                          control={
                             <CheckBox 
                                checked={updatePreferredName}
                                onClick={()=>{
                                    setUpdatePreferredName(!updatePreferredName)
                                    console.log('Check click',updatePreferredName )
                                }}/>
                         }label='update preferred name'  /> */}
                       
                            <TextField  
                                multiline={true}
                                rows={5}
                                label='personal message'
                                defaultValue={message}
                                onChange={(e)=>{setMessage(e.target.value)}}
                                />
                                <Box sx={{ display:'flex',flexWrap:'wrap', gap:'0.5rem'}}>
                                    {emails?.map((email,i)=>{
                                        return(
                                            <Chip key={i} label= {email} onDelete={() => removeEmailFromList(email)} />
                                            )
                                        })}
                                </Box>

                            <TextField label='email'
                            value={currentEmail}
                              InputProps={{
                                    endAdornment: (
                                    <InputAdornment position='end'>
                                         <IconButton
                                            onClick={(e)=>{
                                               if(helpers.validateEmail(currentEmail)){
                                                   if (!emails){
                                                        setEmails([
                                                       currentEmail
                                                       ])
                                                   }
                                                   setEmails([
                                                       ...emails,
                                                       currentEmail
                                                   ])
                                                   setCurrentEmail('')
                                               }
                                            }}
                                            sx={{width:'fit-content'}}
                                            >   
                                            <AddCircle />
                                        </IconButton>
                                    </InputAdornment>
                                    ),
                                }}
                            onChange={(e)=>{setCurrentEmail(e.target.value)}}
                            />
                            <p style={{fontSize:'0.8rem'}}><b>*Heads up:</b> It might go to the their spam folder!</p>
                            
                            {!helpers.validateEmail(currentEmail) && currentEmail ?'please enter valid email':''}
                            {actionError ? actionError :''}
                           <IconButton sx={{width:'fit-content', borderRadius:'10px', margin:'auto',marginTop:'1rem',
                                fontSize:'0.85rem',alignContent:'center',alignItems:'center'}} 
                                onClick={()=>{
                                    let link = `https://music.rexentropy.com/`
                                    if(track){
                                        link += `share/${track?._id}`
                                    }
                                    navigator.clipboard.writeText(link)
                                    setActionError('Copied to clipboard')
                                   document.addEventListener('mousemove',()=>setActionError(''))
                                }}
                                >
                               <Link />
                              <Typography>copy link</Typography>
                           </IconButton>
                            <Button variant='outlined' 
                                sx={{position:'fixed', bottom:'0.5rem', right:'0.5rem'}}
                                onClick={()=>{
                                    handleShare()
                                }}
                            >
                                send
                            </Button>
                        </Stack>

                      
                    </Box>
                    
            </Container>
        </Modal>
        
    </>
  )
}

export default ShopShareModal