import React , { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios'
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";


import UserNavBar from './UserNavBar'
import UserInfoCard from './UserInfoCard'
import MetricCard from './MetricCard'
import Auth from './Auth'
import PersonaManager from './PersonaManager'
import AssetManager from './AssetManager'
import QRManager from './QRManager'
import EditUserInfo from './EditUserInfo'
import CreatePersona from './CreatePersona';
import DeletePersona from './DeletePersona';
import CreateAsset from './CreateAsset';
import DeleteAsset from './DeleteAsset';
import CreateQR from './CreateQR';
import BuildLSAsset from './BuildLSAsset';
import DeleteQR from './DeleteQR';
import PersonaCard from './PersonaCard'
import Menu from './Menu'
import ModalComponent from './Modal'

import * as API from '../utils/APICalls'
import EnvRoute from '../middleware/envRoute';
import { AppContext } from "../context/AppProvider";
import PicView from './PicView'
import LoadingSpinner from './LoadingSpinner';
import useWindowDimensions from "../hooks/windowDimensions";
import ThemeColorPicker from './ThemeColorPicker';

import colors from '../defaults/ThemeDefaults'
import CreateCampaign from './CreateCampaign';
import { BottomNavigation, BottomNavigationAction, Box, 
  Button, Container, Drawer, Icon, Paper, SwipeableDrawer, Tooltip,Modal, Typography } from '@mui/material';
  
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';

import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import CategoryIcon from '@mui/icons-material/Category';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import CampaignIcon from '@mui/icons-material/Campaign';
import PinDropIcon from '@mui/icons-material/PinDrop';

import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import LocalFireDepartment from "@mui/icons-material/LocalFireDepartment";
import StoreIcon from '@mui/icons-material/Store';

import { Link } from 'react-router-dom';
import CampaignManager from './CampaignManager';
import HotLinks from './HotLinks';
import PinDrop from '@mui/icons-material/PinDrop';
import GeoPins from './GeoPins';
import SingleMetric from './SingleMetric';
import CurrentLocation from './CurrentLocation';
import StorefrontConsole from './StorefrontConsole';
import Storefronts from './Storefronts';
import Storefront from '../views/Storefront';
import ReviewOrder from './ReviewOrder';
import ProductInfoComponent from './ProductInfoComponent';
import Scan from './Scan';

const ENDPOINT = EnvRoute('prod'); 


const Dashboard = (props) => {
  
    const { appData,userData, setUserData, setPersonas, currentAsset,
      currentPersona,updateAppData,modalOpen ,editUser, setEditUser,scans,
      personas, assets, setAssets,selectedQR,appTheme, setAppTheme,actionText, setActionText} = useContext(AppContext);
  const defaultTheme = 'aura'
    const [cookies, removeCookie] = useCookies([]);
    const navigate = useNavigate();

    const [username, setUsername] = useState("");

    const [ navValue ,setNavValue ] = useState(0)
    const { height, width } = useWindowDimensions();
    const [ section1Top, setSection1To ] = useState(undefined);
    const [ section2Top, setSection2Top ] = useState(undefined);
    const [ section3Top, setSection3Top ] = useState(undefined);
    const [ confirm, setConfirm ] = useState(false)
    const [ topSection, setTopSection ] = useState('')
    const [ isLoading, setIsLoading ] = useState(true)
    const [ geoPin, setGeoPin ] = useState()
    const [ showThemePicker, setShowThemePicker ] = useState(true)
    const [ showMetrics, setShowMetrics ] = useState(null)
    const [ subView , setSubView ] = useState('qr-manager')
    const subViews =[
      {'qrs':['qr-manager',<QrCodeScannerIcon />]},
     {'assets':['asset-manager',<CategoryIcon />]} ,
     {'personas':['persona-manager',<SettingsAccessibilityIcon />]},
     {'campaigns':['campaign-manager',<CampaignIcon />]},
    //  {'insigts':['insight-manager',<TroubleshootIcon />]},
    //  {'':[null,<></>]}
    ]
 
      const ref = useRef(null);
  
  const toggleDrawer = (anchor, open) => (event) => {
        if (
        event &&
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
        ) {
        return;
        }
        setNavValue(null);
    };

  const handleGeoClick =async (e) => {
        e.preventDefault()
        try{

          const loc = await API.getGeoData()
          console.log('location:', loc)
          setGeoPin(loc)
          updateAppData({
                modalOpen: true,
                modalView:'geo-pins'
            })
        }catch(err){console.log(err)}

    };
  const handleMetricClick = async (e) => {
        e.preventDefault()
        
          updateAppData({
            modalOpen: true,
            modalView:'metrics'
          })
    

    };
  const handleHotLinksClick = async (e) => {
        e.preventDefault()
        
          updateAppData({
            modalOpen: true,
            modalView:'hot-links'
          })
    

    };
  const handleStorefrontClick = async (e) => {
        e.preventDefault()
        
          updateAppData({
            modalOpen: true,
            modalView:'storefronts'
          })
    

    };


       const actions = [
  { icon: <LocalFireDepartment/>, name: 'Hot Links',func: handleHotLinksClick },
  { icon: <PinDrop />, name: 'Geo Pins',func: handleGeoClick },
  { icon: <TroubleshootIcon />, name: 'Metrics',func: handleMetricClick },
  { icon: <StoreIcon />, name: 'Storefronts',func: handleStorefrontClick},
 
];

  useEffect(()=>{
      
        
            //  window.addEventListener('load', onPageLoad);
             if (document.readyState === 'complete') {
              //  onPageLoad();
              setIsLoading(false)
              }
    },[])

useEffect (()=>{
 
    },[userData])

    useEffect(()=>{

    },[selectedQR])

    useEffect(()=>{

    },[personas])

    useEffect(()=>{
      if(scans){

        setEditUser(true)
        setEditUser(false)
        return
      }
    },[scans])
    

    useEffect(() => {
    
    }, []);
    
    useEffect(() => {
      
    }, [modalOpen]);

  useEffect(() => {
    const verifyCookie = async () => {
      // const ckT = cookies.token
      // console.log('[Dash cookies]:', cookies)
      if (!userData) {
        // navigate("/");
        try{
  
          const { data } = await axios.post(
            `${ENDPOINT[0]}/auth`,{},
            { withCredentials: true }
            );
            console.log('Cookie check:',data)
          const { status, user } = data;
          setUserData({...user})
          updateAppData({...appData,userData})
          // setUsername(user);
          // if(!status) {removeCookie("token"); navigate("/")}
          return status
            // ? (setUserData(user),updateAppData({...appData,userData}))
            // : (removeCookie("token"), navigate("/"));
        }catch(err){console.log('cookie error')}
      }
    };
    verifyCookie();
  }, [cookies, removeCookie,userData]);


    //  if(!userData?.token){
      // let user = JSON.parse(localStorage.getItem('QauRaUser'))
    //  if((user?.token || userData?.token ) && user?.token !== userData?.token){
     if(!userData){
      
      // setUserData({...user})
      // updateAppData({...appData,userData})
    //  } else{

       // updateAppData({
       //         modalOpen:true,
       //         modalView:'login-signup'
       //     })
       return (
         <div
           style={{ display: 'flex',position:'absolute',width:'100%',height:"100%", margin:'auto',
              minHeight:'100%', justifyContent:'center'}}
         >
        {!isLoading ?<h3 className='center'
        
         >no user logged in.
           <u 
             style={{cursor:'pointer'}}
             onClick={()=>{
             window.location.assign('/')
           }}>
             please return home
           </u>
           </h3>:
           <div style={{alignSelf:'center'}}>
             <LoadingSpinner />
           </div>
           }
         </div>
       )
    //  }
          // window.location.assign('/')

   }else{
     return (
       <div style={{display:'flex',flexDirection:'column',position:'relative',
        gap:'0.5rem',position:'relative',height:'100%', background: 'rgb(255,255,255)',
        maxHeight: '100vh',
        width: '100%',
        maxWidth: '100vw',
        overflow:'hidden',
        overflowY:'scroll',
          // padding: '0.5rem',
           // background: 'linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6033950617283951) 18%, rgba(0,0,0,0.7160493827160495) 35%, rgba(0,0,0,0.5) 70%, rgba(0,0,0,0.0) 100%)'}}>
           background: 'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6033950617283951) 18%, rgba(255,255,255,0.7160493827160495) 35%, rgba(223,252,255,0.5) 70%, rgba(254,255,255,0.0) 100%)',
        margin:'auto'
        }}>
           {/* <div  className="navbar" style={{position:'sticky', top:' 0'}}> */}
         
            <UserNavBar setShowMetrics={setShowMetrics} showMetrics={showMetrics}/> 
            {/* <Tooltip title='create and store a GPS pin of  this location'>
              <Button className='save-button' variant='outlined' sx={{position:'sticky',left:'50%',transform:'translate(-50%,0)',top:0, width:'fit-content'}}
              onClick={handleGeoClick}
              >
              </Button>
            </Tooltip> */}
         
            <Box sx={{ position:'absolute',top:'100px', right:'0.25rem', transform: 'translateZ(0px)', flexGrow: 1, width:'100%' }}>
              <SpeedDial
                ariaLabel="SpeedDial basic example"
                sx={{ position: 'absolute', top: 16, right: 16 }}
                icon={<SpeedDialIcon />}
                direction='left'
              >
                {actions.reverse().map((action) => (
                  <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    onClick={action.func}
                    //  tooltipOpen
                     tooltipPlacement='bottom-start'
                  />
                ))}
              </SpeedDial>
            </Box>
           {/* </div> */}
            {/* {( appData.modalOpen && !confirm) &&
                 <Modal confirm={true}>
                  <h1 style={{color:"#FFFFFF", marginBottom:'0.5rem'}}>{appData?.modalView}</h1>
                  <section style={{
                      display:'flex',
                      flexDirection:'column', 
                      justifContent:'center',
                      alignItems:'center',
                      flex:1, 
                      margin:'auto', 
                      width:'100%',
                      height:'100%',
                      zIndex:999,
                      overflow:'hidden',
                      overflowY:'scroll'}}>

                    { appData?.modalView==='user-menu' && <Menu /> }
                    { appData?.modalView==='user-login' && (<Auth/> )}

                    { appData?.modalView==='create-persona' && (<CreatePersona view='create-persona' /> )}
                    { appData?.modalView==='create-asset' && (<CreateAsset  view='create-asset' /> )}
                    { appData?.modalView==='build-ls-asset' && (<BuildLSAsset  view='build-ls-asset' /> )}
                    { appData?.modalView==='create-campaign' && (<CreateCampaign  view='create-campaign' /> )}
                   
                    { appData?.modalView==='create-QR' && (<CreateQR view='create-QR'/> )}
                    { appData?.modalView==='edit-QR' && (<CreateQR view='edit-QR'/> )}

                    { appData?.modalView==='pic-view' && (<PicView /> )}
                    { appData?.modalView==='persona-card' && ( <PersonaCard view={'view-persona'}/>  )}

                    { appData?.modalView==='confirm-persona-delete' && (<DeletePersona />) }
                   { appData?.modalView==='confirm-asset-delete' && ( <DeleteAsset />) }
                   { appData?.modalView==='confirm-qr-delete' && ( <DeleteQR />) }

                  </section>
            
                 </Modal>} */}
                  
           <section style={{ maxWidth:'100vw',width:'100%', margin:'auto',overflow:'hidden'}}>
        
            <SwipeableDrawer
                onTouchStart={event => {
                  event.nativeEvent.defaultMuiPrevented = true
                }}
                anchor={'bottom'}
                open={ appData?.modalView ? appData?.modalView :false }
                onClose={() => {
                   updateAppData({
                        modalOpen:false,
                        modalView:''
                    })
                  // setShowMetrics(!showMetrics)
                } }
                sx={{ display:'flex', flex:1, 
                  fontFamily:'Gilroy',maxWidth:'350px',overflow:'hidden',
                }}
              >
             {/**/}
             <Box sx={{height:'100%',borderTop:'3px ridge black',overflow:'hidden'}}>

             { appData?.modalView==='user-menu' && <Menu /> }
             { appData?.modalView==='scan' &&  <Scan /> }
             { appData?.modalView==='metrics' &&  <MetricCard /> }
             { appData?.modalView==='single-metric' &&  <SingleMetric /> }
             { appData?.modalView==='hot-links' &&  <HotLinks /> }
             { appData?.modalView==='geo-pins' &&  <GeoPins geoPin={geoPin}/> }
             { appData?.modalView==='storefronts' &&  <Storefronts/> }
             { appData?.modalView==='storefront' &&  <StorefrontConsole /> }
             { appData?.modalView==='review-order' &&  <ReviewOrder /> }
             { appData?.modalView==='edit-product' &&  <ProductInfoComponent /> }

                    { appData?.modalView==='user-login' && (<Auth/> )}

                    { appData?.modalView==='create-persona' && (<CreatePersona view='create-persona' /> )}
                    { appData?.modalView==='create-asset' && (<CreateAsset  view='create-asset' /> )}
                    { appData?.modalView==='build-ls-asset' && (<BuildLSAsset  view='build-ls-asset' /> )}
                    { appData?.modalView==='create-campaign' && (<CreateCampaign  view='create-campaign' /> )}
                   
                    { appData?.modalView==='create-QR' && (<CreateQR view='create-QR'/> )}
                    { appData?.modalView==='edit-QR' && (<CreateQR view='edit-QR'/> )}

                    { appData?.modalView==='pic-view' && (<PicView /> )}
                    { appData?.modalView==='persona-card' && ( <PersonaCard view={'view-persona'}/>  )}

                    { appData?.modalView==='confirm-persona-delete' && (<DeletePersona />) }
                   { appData?.modalView==='confirm-asset-delete' && ( <DeleteAsset />) }
                   { appData?.modalView==='confirm-qr-delete' && ( <DeleteQR />) }
             </Box>

          </SwipeableDrawer>
              <div style={{alignItems:'center',justiifyContentL:'center',alignText:'center',zIndex: topSection === 's1'?'999':'0', flex: 1}}>
               
               {/* <div style={{position:'absolute', left:'0.75rem',top:'8rem', display:'flex', flexDirection:'row', flex:'1', maxWidth:'200px'}}>  */}
            {/* <button className="button save-button " 
                // onClick={()=>{setModalOpen(!modalOpen)}}
                onClick={()=>{
                    updateAppData({
                        modalOpen:!modalOpen,
                        modalView:'scan'
                    })
                }}
                type="submit" 
                style={{
                    fontSize:'0.75rem',
                    display:'flex', 
                    flexDirection:'column',
                    alignItems:'center',
                    color:"#000000",
                    width: '50px'}}>
                <img src={'/assets/ScanQR(plain).svg'} style={{height:'40px', alignSelf: 'center'}} alt="menu"/>
            reader
            </button> */}
            {/* <button className="button save-button  blink" type="submit"  
                style={{display: 'flex', flexDirection: 'column', 
                alignItems:'center', width: '50px'}}
                onClick={handleSave}>
                <img src={'/assets/download.svg'} style={{height:'19px'}} alt="download"/>
                <img src={'/assets/qrcode.svg'} style={{height:'19px'}} alt="QR code"/>
            </button> */}
                {/* QR Code */}
        {/* </div>  */}
                <h2 style={{width: '100%',paddingBottom:'0.75rem'}}>
                  Dashboard
                </h2>
      
                <Box sx={{}}>
                  <UserInfoCard editUser={editUser} func={updateAppData} val={
                    {
                      modalOpen:!modalOpen,
                      modalView:'user-menu'
                    }
                    } />
                </Box>
               <CurrentLocation loc={userData?.currentLocation} func={handleGeoClick} />
              </div>
               
              </section>
              {/* <SwipeableDrawer
                  anchor={'bottom'}
                  open={navValue !== null }
                  onClose={toggleDrawer('bottom', false)}
                  onOpen={toggleDrawer('bottom', true)}
                  sx={{ backgroundColor:'rgba(255,255,255,0.2)',
                  backdropFilter:'blur(5px)',
                  borderRadius:'5px',boxShadow:'0px -1px 10px 5px rgba(0, 0, 0, 0.2)',
                  maxWidth:'100vw',
                  maxHeight:'100vh',
                  height:'50%',
                  // overflow:'scroll',
                  fontFamily:'Gilroy',
                  position:'absolute',
                  // bottom:0,
                  // left:0,
                  // zIndex:10,
                  // opacity: navValue !== null ?'1':'0',
                  // display: navValue !== null ?'':'none',
                  // visibility: navValue !== null ?'':'hidden',
                  // border:'1px solid red'
              }}
                >       */}
                <Container sx={{position:'relative', display:'flex', flexDirection:'column',
                gap:'0.75rem'}}>
                <Box sx={{ position: 'relative', top: 0, left: 0, right: 0,zIndex:9999, maxWidth:'100%'}} elevation={3}>
                <BottomNavigation
                showLabels
                value={navValue}
                sx={{background:'none'}}
                onChange={(event, newnavValue) => {
                    // setCurrentItem()
                    setNavValue(newnavValue);
                    setSubView(Object.values(subViews[newnavValue])[0]?.toString().split(',')[0].toString())
                    console.log('subview:', subView)
                    ref.current.scrollIntoView({
                      behavior:"smooth",
                      block:"end"
                    })
                    // console.log('subview icon:',Object.values(subViews[newnavValue])[0][1])
                  }}
                  > 
                  {subViews.map((v,i)=>{
                    let icon = Object.values(subViews[i])[0][1]
                    // console.log('[map ]icon:', icon)
                    return ( 
                      
                        <BottomNavigationAction label={Object.keys(v)} icon={icon} key={i}/>
                        
                        // </BottomNavigationAction>
                    )
                  })}
                </BottomNavigation>
            </Box>
              <section style={{display:'flex', flexDirection:'column',
              padding:'0.5rem',
                    gap:'0.5rem'}} >
                {subView ==='qr-manager' && <div
                    className='section2'
                    id = 'qr-manager'
                    style={{
                    flex:1,
                    // zIndex:20,
                    
                    width:'100%',
                    //  position: 'sticky',top:'10vh',
                    borderRadius:'10px',
                    boxShadow: '0px 0px 10px 5px rgba(0,0,0,0.4)',
                    border: '3px solid #000',
                    backgroundColor:'rgba( 256,256,256, 0.1)',
                    backdropFilter: 'blur(5px)',
                    WebkitBackdropFilter: 'blur(5px)',

                    zIndex: topSection==='s2'?'999':'1'
                    }}
                    onClick={()=>{
                      setTopSection('s2')
                    }}
                    >
                    <QRManager />
                    {/* <AssetManager /> */}
                </div>}

               {subView ==='asset-manager' && <div
                    className='section2'
                    id = 'asset-manager'
                    style={{
                    flex:1,
                    // zIndex:20,
                    width:'100%',
                    //  position: 'sticky',top:'10vh',
                    borderRadius:'10px',
                    boxShadow: '0px 0px 10px 5px rgba(0,0,0,0.4)',
                    border: '3px solid #000',
                    backgroundColor:'rgba( 256,256,256, 0.1)',
                    backdropFilter: 'blur(5px)',
                    WebkitBackdropFilter: 'blur(5px)',
                    zIndex: topSection==='s2'?'999':'1'
                    }}
                    onClick={()=>{
                      setTopSection('s2')
                    }}
                    >
                    <AssetManager />
                </div>}
            </section>
          {subView ==='persona-manager' && <section 
                  id = 'persona-manager'
                 className='section3'
                 style={{ 
                  zIndex: topSection==='s2'?'999':'1',
                   width: "100%",
                   height:'100%', overflow: 'hidden', 
                   borderRadius:'10px',
                   border: '3px solid #000',
            // marginTop:'0.5rem',
               boxShadow: '0px 0px 10px 5px rgba(0,0,0,0.4)',
                  // flex: 1,
               // overflowY:'scroll' 
              //  zIndex: topSection==='s3'?'999':'2'
               
               }}
                onClick={()=>{
              setTopSection('s3')
            }}
               >
                
               <PersonaManager />
           
           </section>}
          {subView ==='campaign-manager' && <section 
                  id = 'campaign-manager'
                 className='section3'
                 style={{ 
                   zIndex:4000,
                   width: "100%",
                   height:'100%', overflow: 'hidden', 
                   borderRadius:'10px',
                   border: '3px solid #000',
            // marginTop:'0.5rem',
               boxShadow: '0px 0px 10px 5px rgba(0,0,0,0.4)',
                  // flex: 1,
               // overflowY:'scroll' 
              //  zIndex: topSection==='s3'?'999':'2'
               
               }}
                onClick={()=>{
              setTopSection('s3')
            }}
               >
                
               <CampaignManager />
           
           </section>}
           <div ref={ref} ></div>
            </Container>
             {/* </SwipeableDrawer>  */}
           {/* </section> */}

            
               
            <div style={{display:'flex', width:'100%', justifyContent: 'center', alignItems: 'center', color:'#000000', margin: '1rem auto'}}>
                © 2023 - {new Date().getFullYear()} RexEntropyLLC
            </div> 

       </div>
     )
   }
   
}

export default Dashboard