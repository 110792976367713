import { Box, Button, Container, Stack, Typography } from '@mui/material';
import React, { useState, useRef, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom'
import { QRCodeContext } from "../context/QRCodeProvider";
import { StorefrontContext, StorefrontProvider } from "../context/StorefrontProvider";
import QRCodeStyling from "qr-code-styling";

import { AppContext } from "../context/AppProvider";
import * as API from '../utils/APICalls'
import * as helpers from '../utils/helpers'



const QRFraming = (props) => {
  const { id } = useParams()
  const { view, handleQRImageDelete, setFramingModalOpen } = props
  // const [image1Url, setImage1Url] = useState('https://res.cloudinary.com/dzxzdsnha/image/upload/v1911336337/QauRaQR_Main-Personal_3_jageai.png'); // URL or data URI for image 1
  //   const [image1Url, setImage1Url] = useState('https://res.cloudinary.com/dzxzdsnha/image/upload/v1911336337/QauRaQR_Main-Personal_3_jageai.png'); // URL or data URI for image 1
  const image2Url = '/assets/QauRa-QR-Frame.png'; // URL or data URI for image 2
  const [savedQRLink, setSavedQRLink] = useState('')
  const [imageReady, setImageReady] = useState(false)
  const [picLoading, setPicLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const canvasRef = useRef(null);

  const { qrCodeData, updateQRCodeData } = useContext(QRCodeContext);
  const { appData, updateAppData, previewQR, setPreviewQR,
    actionError, setActionError,
    setSelectedQR, selectedQR, setCurrentOrder, currentOrder, } = useContext(AppContext);
  const { buildStep, setBuildStep,
    cart,//setCurrentOrder,currentOrder, 
    setShop, shop, storefrontData,
    updateStorefrontData } = useContext(StorefrontContext);


  //   useEffect(() => {

  //   },[])

  //   useEffect(() => {

  //   },[qrCodeData])
  const handleDownload = async () => {
    const canvas = canvasRef?.current;

    const imageData = await canvas.toDataURL('image/png').replace("image/png", "image/octet-stream");
    const link = document.createElement('a');
    link.href = imageData;
    link.download = `QauRa_FramedQR-${id}.png`;
    link.click();
  };

  const handleDelete = async (e) => {
    e.preventDefault()
    try {
      const data = await API.deleteQR(qrCodeData)
      console.log("[Delete Data]:", data)
      updateQRCodeData({});
      updateAppData({
        ...appData,
        modalOpen: false,
        modalView: ''
      })

    } catch (err) { console.log(err) }

  }
  const handleCancel = async (e) => {
    e.preventDefault()
    try {

      props.setFramingModalOpen(false)
    } catch (err) { console.log(err) }

  }
  const handleSave = async (e) => {
    // e.preventDefault()
    setIsSaving(true)
    try {
      // if( imageReady )
      // {

      //TODO Implement function to delete existing Qr_pic from Cloudinary
      const canvas = canvasRef?.current;

      const imageData = canvasRef?.current.toDataURL('image/png').replace("image/png", "image/octet-stream");
      const imageBlob = helpers.dataURLToBlob(imageData);

      console.log('[handleSave] imageData:', imageData)
      const data = new FormData();
      // data.append("file", imageBlob);
      data.append("file", imageData);
      data.append("public_id", `QauRa_FramedQR-${qrCodeData?._id}_00.png`);
      //  data.append("use_filename",true);
      data.append("upload_preset", "TinCan");
      data.append("folder", "QRS");
      data.append("cloud_name", "dzxzdsnha");
      //  data.append("return_delete_token", 1);

      fetch("https://api.cloudinary.com/v1_1/dzxzdsnha/upload", {
        method: "post",
        body: data,
        return_delete_token: 1
      })
        .then((res) => res.json())
        .then(async (data) => {
          console.log('[pic response]:', data)
          setSavedQRLink(data?.url?.toString());
          console.log('[QRFraming] data?.url?.toString():', data?.url?.toString())

          try {
            const res = await API.updateQRCode({
              ...qrCodeData,
              pic_url: data?.url?.toString(),
              pic_public_id: data?.public_id
            })

            if (res?.pic_public_id) {

              console.log('[QRFraming] response:', res)
              await updateQRCodeData(res)
              if (view === 'build-shirt' || view === 'edit-QR' || view === 'process') {

                await setCurrentOrder({
                  ...currentOrder,
                  qr_pic: data?.url?.toString(),
                  qr_pic_public_id: data?.public_id?.toString()
                })
                if (currentOrder?.qr_pic === data?.url.toString() && currentOrder?.qr_pic_public_id === data?.public_id?.toString()) {
                  setPicLoading(false);
                  setBuildStep(2)
                  await updateStorefrontData({
                    ...storefrontData,
                    currentOrder,
                    buildStep
                  })
                }// else if (!currentOrder?.qr_pic || currentOrder?.qr_pic === undefined) {
                //   props.buildQR() 
                //   setBuildStep(2)
                //     updateStorefrontData({
                //     ...storefrontData,
                //     currentOrder,
                //     buildStep
                // })
                //   return
                // }

              }
              setPicLoading(false);
              setFramingModalOpen(false)
            } else { setActionError('QR Image Failed To Save.') }

          } catch (err) { console.log(err) }

          console.log('[BuildFramedQR]:', data.url);
          setPicLoading(false);
          setIsSaving(false)
        })
        .catch((err) => {
          console.log('!!!****QR Build failed****!!!', err);
          setIsSaving(false)
          setPicLoading(false);
        });
      //  link.click();
      // }
      setIsSaving(false)
    } catch (err) { console.log(err) }
    setIsSaving(false)
  };

  const sleep = (time) => {
    console.log('***Sleeping***')
    return new Promise(resolve => setTimeout(resolve, time))
  }

  useEffect(() => {
    if (qrCodeData?._id && !qrCodeData?.pic_url && props?.view !== 'build-shirt' && props?.view !== 'process') {
      handleSave()
    }
  }, [qrCodeData])

  useEffect(() => {
    // Fetch images (replace with your image fetching logic)
    let actQR
    let qrCodeRaw
    var qrFile
    let qrCodeURL
    let qrID = view === 'process' ? currentOrder?.qr_id : qrCodeData?._id

    const qrDat = async () => {
      try {
        // const qr = await API.fetchQRByID({_id:'6602df3b7129b81cba98c41c'})
        const qr = await API.fetchQRByID(qrID)
        //  if(qr?.statusText ==='OK'){
        if (qr?.data) {
          //  updateQRCodeData()
          actQR = qr?.data
          console.log('[qrDat] actQR:', actQR)
          updateQRCodeData(actQR)
          qrCodeRaw = await new QRCodeStyling(actQR).getRawData('png')

          console.log('[qrDat] qrCodeRaw:', qrCodeRaw)

          var blob = new Blob([qrCodeRaw], { type: "image/png" });

          qrCodeURL = URL.createObjectURL(blob);
          // if(qrCodeURL){
          // try{
          console.log('[qrDat] qrCodeURL :', qrCodeURL)
          await generateQRCode(qrCodeURL)

          // await  generateQRCode(qrCodeURL)
          // }catch(err){console.log('Failed to [Generate] QR code',err)}

          //  .then(URL.revokeObjectURL(qrCodeURL))
          // return qrCodeURL
          // }
        } else {
          console.log('Failed to [Receive] QR code', qr)
          return null
        }
      } catch (err) { console.log('Failed to [Execute] qrDat.', err) }

    }


    document.addEventListener('load', qrDat())
    // if(qrCodeURl){
    const generateQRCode = async (url) => {


      try {

        const ctx = canvasRef.current.getContext('2d');

        // if(image1?.src){
        const image2 = await new Image();
        // image2.src = image2Url;
        image2.src = '/assets/QauRa-QR-Frame.png';
        image2.crossOrigin = 'Anonymous';

        const image1 = await new Image();
        image1.src = url;
        image1.crossOrigin = 'Anonymous';
        image1.onload = () => {
          // ctx.drawImage(image1, 19, 18.5, 370, 370); // Adjust as needed
          ctx.drawImage(image1, -2, 1, 422, 422); // Adjust as needed
          ctx.drawImage(image2, 0, 0, 410, 410); // Adjust as needed
        };

        // const image2 = await new Image();
        // // image2.src = image2Url;
        // image2.src = '/assets/QauRa-QR-Frame.png';
        // image2.crossOrigin = 'Anonymous';
        // image2.onload = () => {
        // Draw image2 on top of image2 (adjust position and size)
        // ctx.globalCompositeOperation = 'destination-over'
        // };
        // setImageReady(true)
        //  sleep(10000)
        // }


        // const image3 = new Image();
        // image3.src = image2Url;
        // image3.crossOrigin = 'Anonymous';
        // image1.onload = () => {
        //   // Draw image3 on top of image2 (adjust position and size)
        //   sleep(10000)
        //   ctx.drawImage(image2, 0, 0, 410, 410); // Adjust as needed
        //   // };
        //   setImageReady(true)
        // }
        setImageReady(true)
      } catch (err) { console.log(err) }
      if (props?.view === 'build-shirt' && !qrCodeData?.pic_url) {//} && qrCodeData?.pic_public_url){
        await handleSave()

      }

    }

    // }
    // }

  }, []);

  useEffect(() => {

  }, [qrCodeData?.pic_url])
  useEffect(() => {
    setActionError('')
  }, [])

  return (
    <Container style={{
      display: 'flex', flexDirection: 'column',
      maxWidth: '400px', textAlign: 'center',
      gap: '0.75rem', justifyContent: 'center', alignItems: 'center', padding: '1rem'
    }}>

      <Typography>{qrCodeData?.name ? qrCodeData?.name : ''}</Typography>
      {/* <Typography>{qrCodeData?._id?qrCodeData?._id:''}</Typography> */}

      <canvas ref={canvasRef} width={410} height={410} />
      {actionError && <Typography sx={{ margin: 'auto', textAlign: 'center' }}>{actionError}</Typography>}
      {props?.view !== 'build-shirt' && <Stack sx={{ display: 'flex', margin: 'auto', justifyContent: 'center', width: '100%', gap: '0.5rem' }}>

        <Stack sx={{ display: 'flex', flexDirection: 'row', margin: 'auto', justifyContent: 'space-evenly', width: '100%' }}>
          <Button variant='outlined' onClick={handleDownload}>Download QR</Button>
          {!qrCodeData?.pic_url && <Button variant='outlined' onClick={(e) => handleSave(e)}>Save QR</Button>}
          {(view === 'edit-QR' || view === 'process' && qrCodeData?.pic_url) && <Button variant='outlined'
            disabled={isSaving || !imageReady}
            onClick={(e) => handleSave(e)}>Save QR</Button>}
        </Stack>
        <Button
          disabled={isSaving}
          sx={{ margin: 'auto', width: '70%' }}
          variant='outlined' onClick={handleCancel}>Cancel</Button>
      </Stack>}

    </Container>
  );
};

export default QRFraming;
