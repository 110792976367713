import React from 'react'
import { Box, Button, Container, Divider, Paper, Select, TextField, 
    Stack,Typography} from '@mui/material'

const BecomeAPartner = ({view}) => {
  return (
    <Paper style={{color:"#000",margin:'1rem auto', fontFamily:'Gilroy',padding:'0.5rem'}}>
      {view!='home'&& <div 
                        style={{
                            width:'100%', 
                            display: 'flex', 
                            flexDirection:'column', 
                            justifyContent:'center'}}>
                       <span  style={{textAlign:'center',maxWidth:'600px', margin:'auto'}}>
                        
                        A <b>dynamic QR code management system </b>centered around providing individuals and organizations with:
                        </span> 
                        <br/>
                        
                    <div style={
                        {display: 'flex', 
                        flexDirection:'row',
                        justifyContent:'space-evenly', 
                        alignItems:'center', 
                        width:'100%',
                        flex: 1, 
                        gap:'0.5rem'}}>

                              <p style={{width:'50%',borderRight:'2px solid #858585',padding:'0.25rem'}}>
                                The opportunity to <b>start the conversation about what's important</b> to them, with our physical assets 
                            <br/>
                            <br/>
                                </p>  
                            {/* </li> */}
                            {/* <li> */}
                            <p style={{width:'50%',padding:'0.25rem'}}>
                                The ability to always <b>be in the right place and time</b> with our digital ones.
                                
                        <br/>
                        <br/>
                            </p>
                            {/* </li> */}
                            {/* <li> */}
                    </div>
                        <br/>
                            <p style={{textAlign:'center',paddingTop:'0.5rem',borderTop:'2px solid #858585'}}>

                                And the means to <b>nurture & track</b> the reach of their <b>interactions</b>
                        <br/>
                            </p>
                            {/* </li> */}
                        
                        {/* </ul> */}
                    </div> }
                        <br/>
<Stack sx={{gap:'0.5rem', 
textAlign:'center',
  justifyContent:'center', border:'2px solid #757575', borderRadius:'5px',
  boxShadow:'0px 0px 5px 3px rgba (0 ,0 ,0, 0.5)',
   padding:'0.5rem'}}>
     <h1 style={{textAlign:'center'}} >Feel your brand could benefit?</h1>
     <h2 style={{textAlign:'center'}} >Become A Partner!</h2>
      <p>( contact: 
       <b>
         <a href='mailto:services@rexentropy.com?subject = QauRa Partner Inquiry'

        style={{textDecoration:'none'}}
      >
        services@rexentropy.com </a>
        </b>
        for more details )</p>
</Stack>

    </Paper>
    
    )
}

export default BecomeAPartner