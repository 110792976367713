import React, {useState, useContext, useEffect } from "react";
import { AppContext } from "../context/AppProvider";
import useWindowDimensions from "../hooks/windowDimensions";

import EditUserInfo from './EditUserInfo'

import * as API from '../utils/APICalls'
import * as helpers from '../utils/helpers'
import { Container, Divider, List, ListItem ,Box,
  Stack,
   Button,Pagination} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import CloseButton from "./CloseButton";
import { Spinner } from "@chakra-ui/react";

const MetricCard = () => {
    const { appData,updateAppData,userData,currentScanSet,  setCurrentScanSet,
      scans, setScans,modalOpen, follows, setFollows, QRs,setModalOpen ,scanHeaders, setScanHeaders } = useContext(AppContext);
    const [ followHeaders, setFollowHeaders ] = useState()
    const [ cities, setCities ] = useState({}) 
    const [ showCities, setShowCities ] = useState(false) 
    const [ refresh, setRefresh] = useState(false)
    const [ view , setView ] = useState('scans')
    const [ followsTabs , setFollowsTabs ] = useState()
    const { height, width } = useWindowDimensions();


const fetchScans = async ()=>{
  const data = await API.fetchOwnerScans(userData?._id)
  // console.log('fetchScans data:', data)
  setScans(data)
  updateAppData({...appData,scans})
  setRefresh(false)

  return data
}

const fetchFollows = async ()=>{
  const data = await API.fetchOwnerFollows(userData?._id)
  // console.log('fetchFollows data:', data)
  setFollows(data)
  updateAppData({...appData,follows})

  setRefresh(false)

  return data
}

 const handleButtonClick = (row) => {
  // Perform action with the row data
  // console.log('Button clicked for row:', row);
    setCurrentScanSet(row.set)
            try{
              updateAppData({
              ...appData,
              currentScanSet,
              modalOpen: true,
              modalView:'single-metric'
            }) 
            }catch(err){console.log(err)}
};
/*******************
//!---Scan helpers
******************/
const headersFilter = () =>{  
  // let s= [] = scans
  // console.log('headers (scans): ', scans)
  const hdrs = [...new Set(scans?.map(s => s?.qr_id))]
  // console.log('headers: ', hdrs)
  setScanHeaders([...hdrs])
  return hdrs
}
const followsFilter = (arr,elem,func) =>{  
  // let s= [] = scans
  // console.log('headers (arr): ', arr)
  const hdrs = [...new Set(arr?.map(s => s[elem]))]
  // console.log('headers: ', hdrs)
  func([...hdrs])
  if (arr === follows){
    for(let i=0; i < follows?.length; i++){
      setFollowsTabs({...followsTabs,
        [i]:false
      })
    }
  }
  return hdrs
}
const createSets = (arr,field,val)=>{
  const arrSet = arr?.filter(s=> {return s[field] === val})
  // console.log('createArrSet: ',arrSet)
  return arrSet
}
const cityListFilter = async (arr,id) =>{  
  // console.log('city (scans): ', scans)
  //  const unq = [...new Set(scans?.map(s=>{
    
    //   })]
  const set = await createSets(arr,'qr_id',id)
  // console.log('city (set): ', set)
  const cts = [...new Set(set?.map(s => s?.geolocation?.city +', '+s?.geolocation?.state))]
  // console.log('cities: ', cts)
  if (cities){
    setCities({
      ...cities,
      [id]: [...cts]
    })

  }else(
    setCities({[id]: [...cts]})
  )

  // if(cities){
    // setCities(...cities,cts)
  // }else{setCities(cts)}
  return  cts
  // (
  //   <>
  //     {cities?.map(c=>
  //       <>{c}:</>
  //     )}
  //   </>
  //   )
}
const mostFreq = (arr) =>{
  var mf = 1;
  var m = 0;
  var item;

  for (var i=0; i<arr?.length; i++)
  {      
          for (var j=i; j<arr.length; j++)
          {
                  if (arr[i] == arr[j])
                  m++;
                  if (mf<m)
                  {
                    mf=m; 
                    item = arr[i];
                  }
          }
          m=0;
  }
  return item
}
const scanCount = (arr,key,val) =>{
const count =  arr?.filter(e=>{return e[key] === val})
 return count?.length
}

const qrName = (id)=>{
  let qr = QRs?.find(q=>{
    return q?._id === id 
  })
  return qr?.name
}
const createRow = (arr)=>{

 

}
const scanRows = scanHeaders?.map((s,i)=>{
       
    let thisSet = [...createSets(scans,'qr_id',s)]
    // console.log('thisScanSet',thisSet)
    const last_scan = thisSet.reduce((max, obj) => 
    obj.createdAt > max ? obj.createdAt : max, '')
    return {
      set: thisSet,
      id: thisSet[0].qr_id,
      name: thisSet[0].qr_name,
      scans: thisSet.length,
      last_scan: helpers.getDisplayDate(last_scan)
    }
   })

const scanColumns = [
  { field: 'name', headerName: 'Name', width: 150 },
  {
    field: 'last_scan',
    headerName: 'Last Scan',
    minWidth: 120,
    // editable: true,
  },
  {
    field: 'scans',
    headerName: 'Scans',
    minWidth: 70,
    // editable: true,
  },
  {
    field: 'view',
    headerName: 'view',
    minWidth: 85,
    // editable: true,
    renderCell: (params) => {
     
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleButtonClick(params.row)}
          >
            view
          </Button>
        );
     
    },
  },
  ];
const followRows = (email)=>{

  console.log('-----[followRows] email-----:', email)
     const rows = follows?.map((f,i)=> {
      //  console.log('followRows f.POC:', f.POC_email?.toString().toLowerCase())
      //  console.log('followRows email:', email?.toString().toLowerCase())
      // if(f?.POC_email?.toLowerCase() == email?.toLowerCase()){
        return {
          email: f?.follower_email,
          id: f?._id,
          location: f?.geolocation? f?.geolocation?.city +', ' + f?.geolocation?.state:'',
          date: helpers.getDisplayDate(f?.createdAt)
        }
      // }
      
    })
    console.log('followRows rows:', rows)
    return rows

   }
   
const followColumns = [
  { field: 'email', headerName: 'Email', width: 150 },
  {
    field: 'date',
    headerName: 'Date',
    minWidth: 120,
    // editable: true,
  },
  {
    field: 'location',
    headerName: 'Location',
    minWidth: 70,
    // editable: true,
  },
  {
    field: 'actions',
    headerName: 'actions',
    minWidth: 85,
    // editable: true,
    renderCell: (params) => {
     
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleButtonClick(params.row)}
          >
           actions
          </Button>
        );
     
    },
  },
  ];

   

useEffect(()=>{

  try{
    fetchScans()
    fetchFollows()
    
        headersFilter()
  
      let arr = followsFilter(follows,'POC_email',setFollowHeaders)
      console.log('follow arr,', arr)
      setFollowHeaders(arr)
      //  setFollowHeaders(arr)
      console.log('follow headers', followHeaders)
   
    }catch(err){console.log(err)}
  
  },[])

useEffect(()=>{
  if(!scans){
    fetchScans()
    // headersFilter()
  }
  if(scans && !scanHeaders){
    headersFilter()
    // setRefresh(!refresh)
  }
},[scans,scanHeaders])

// useEffect(()=>{
  
// },[])

useEffect(()=>{
  // setRefresh(true)
  // setRefresh(false)
},[scans,follows,followHeaders,followsTabs,scanHeaders,cities])

useEffect(()=>{
  let l = Object.keys(cities).length
 if(scans && scanHeaders && !Object.keys(cities).length){
    for( let i = 0 ; i<scanHeaders?.length ; i++)
        { cityListFilter(scans,scanHeaders[i]) }
      }
      console.log('[cityfilterlist]:',cities,'length',l,':',scanHeaders?.length)
    },[])

  return (
    <>
    
    {!refresh && (<Container
    // className='left-content'
        style={{
            position:'relative',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            width:'100%',
            // maxWidth:'80vw',
            height: '100%',
            // minHeight:'13rem',
            minHeight:'40vh',
            borderRadius:'10px',
            backgroundColor:'rgba(256,256,256,0.6)',
            backdropFilter: 'blur(5px)',
            boxShadow: 'inset 0px 0px 10px 3px rgba(255, 255, 255, 0.5), 0px 0px 5px 3px rgba(0, 0, 0, 0.3)',
            border:'3px solid #000',
            padding:'0.25rem',
            overflow:'hidden',
            // overflowY:'scroll',
            justifyContent:'center',
            textAlign:'center',
            // margin:'1rem 1rem 1rem 1rem'
            // zIndex:'10'
            gap: '0.5rem',
            margin:'auto'
            
        }}  
        >
          <h2>Scan Insights</h2>
        <div style={{position:'absolute', top:'0.5rem', right:'0.5rem',zIndex:999999}}>
            <CloseButton func={updateAppData} 
                    view='dash'
                    />
        </div>
          {/* <span 
              style={{display: 'flex', flexDirection:'row',
              position:'absolute',left:'0.75rem',top:'1.25rem', 
              textAlign:'center',cursor: 'pointer',gap:'0.125rem',whiteSpace:'nowrap'}}
                  onClick={()=>{setView('')}}
                  >
                <img className={`${view?'rot180':'rot-180'}` }
                src={`/assets/arrow-${view?'up':'down'}.svg`} style={{height:'0.75rem', alignSelf: 'center',opacity:view?'1':'0.3'}} alt="Clear Current QR"/>
              
          </span> */}
<Box  style={{display:'flex', flexDirection:'row', 
margin:'auto',alignItems:'center',justifyContent:'center', 
textAlign:'center',
    gap:'0.75rem'}}>
    <Stack
      className='metric-card-btn button-accent-hover'
      style={{display:'flex', cursor:'pointer',
        justifyContent:'center',
        width:'80px',
        boxShadow:'0px 0px 5px 1px rgba(0,0,0,0.2)',
        border:'2px solid #868686', borderRadius:' 5px', textAlign:'center',backgroundColor: view ==='scans'? 'rgba(146, 251, 181, 0.2)':'rgba(255,255,255,1)',
        padding:'0.25rem'}}
        onClick={(e)=>{
        setView('scans')
        headersFilter()
      }}
      > 
        scans:
        <Divider sx={{borderColor:'#000'}}/>
        <b>{scans? scans?.length:<Spinner/>}</b>
      </Stack>
       

    <Stack
        className='metric-card-btn button-accent-hover'
        style={{display:'flex',  cursor:'pointer',
          width:'80px',
          justifyContent:'center', boxShadow:'0px 0px 5px 1px rgba(0,0,0,0.2)',
        border:'2px solid #868686', borderRadius:' 5px', textAlign:'center',backgroundColor: view ==='follows'? 'rgba(146, 251, 181, 0.2)':'rgba(255,255,255,1)',
        padding:'0.25rem'}}
        onClick={()=> setView('follows') }
        >
        follows:
        <Divider sx={{borderColor:'#000'}}/>
        <b>{follows? follows?.length:<Spinner/>}</b>
      </Stack>

      </Box>

                
            <div style={{
                possition: 'relative',
                flexDirection:'row',
                display: 'flex',
                // border:'2px solid #fff',
                borderRadius: '0px 0px 20px 20px',
                // borderLeft:'none',
                width: '100%',
                height: '100%',
          
                }}>
           
                <div >
                  <img src="/assets/refresh.svg" 
                  className='refresh-button'
                    style={{ cursor:'pointer', position:'absolute',left:'0.75rem',top:'1rem',width:' 2rem'}}
                    onClick={()=>{
                      fetchScans()
                      fetchFollows()
                      setRefresh(true)
                    }}
                    /> 
                </div>
          

    {view && <Container style={{position:'relative',
        display:'flex',flexDirection:'column',flex:1, 
        alignItems:'center',justifyContent:'center',
        height:'80vh',overflow:'hidden',
        borderRadius:'10px',
       padding:'0.5rem',
        border:'2px solid #000', 
        boxShadow: 'inset 0px 0px 10px 3px rgba(0, 0, 0, 0.5)',
        overflowY:'scroll'}}>

       {view === 'scans' && 
        // <Box sx={{ height: '100%', width: '100%' ,color:'#00FF00'}}>
            <DataGrid
                rows={scanRows}
                columns={scanColumns}
                initialState={{
                pagination: {
                    paginationModel: {
                    pageSize: 15,
                    // color:'#00ff00'
                    },
                },
                }}
                  components={{
                    Pagination: (props) => (
                    <Pagination {...props} className="custom-pagination" />
                    ),}}
                // getRowId={(row)=>{row.qr_id}}
                // getRowClassName={() => 'share-row-text-color'}
                pageSizeOptions={[5]}
                // checkboxSelection
                disableRowSelectionOnClick
                sx={{
                width:'100%',
                  
            }}
            />
            // </Box>
       
    //    <List style={{ display:'flex', flexDirection:'column',
    //     listStyle:'none', justifyContent: 'left',textAlign: 'left',margin:'0',
    //     marginTop:'350vh',width:'80vw', padding:'0.5rem'}} >
     
       
    //    {scanHeaders?.map((s,i)=>{
       
    // let thisSet = [...createSets(scans,'qr_id',s)]
    // console.log('thisScanSet', thisSet)
    //       return(
    //         <ListItem sx={{minWidth:'200px',maxWidth:'400px'}}>
    //           {i+1}/{scanHeaders?.length}
    //           <div
    //         style={{display:'flex',flexDirection:'column',border:'2px solid #000000',
    //             minHeight:'90px',
    //           padding: '0.5rem',cursor:'pointer',
    //         fontSize:'0.75rem',borderRadius: '5px',
    //         // margin:'0.25rem auto',marginLeft:0,marginRight:0,
    //         width:'100%',
    //         alignSelf:'center',
    //         //boxShadow:'0px 0px 3px 2px rgba(0,0,0,0.3)',
    //       }} 
    //       onClick={()=>{
    //         setCurrentScanSet(thisSet)
    //         try{
    //           updateAppData({
    //           ...appData,
    //           currentScanSet,
    //           modalOpen: true,
    //           modalView:'single-metric'
    //         }) 
    //         }catch(err){console.log(err)}
    //     }}
    //       className='metric-list-card'// button-accent-hover' 
    //           key={i}>
    //          <b style={{width:'100%',margin:'auto',justifyContent:'center'}}> {qrName(scanHeaders[i])}</b> 
    //          {/* <br/> */}
    //          <span style={{marginBottom:'0.25rem',fontSize:'0.75rem'}}>
    //             <b>id:&nbsp;</b>{s} 
    //           </span> 
    //          {/* <br/> */}
    //          <hr/>
    //          {/* <Divider /> */}
    //          <div style={{display:'flex', flexDirection:'row', gap:'0.5rem',marginTop: '0.25rem'}}>

    //          <div style={{display:'flex', flexDirection:'column' }}>
    //           <b>scans</b>
    //           {scanCount(scans,'qr_id',s)} 
    //           </div>

    //           <div style={{display:'flex', flexDirection:'column' }}>
    //             <b>top locations</b>
    //             <div style={{display:'flex', flexDirection:'row', gap: '0.25rem' }}>
    //               <span style={{display:'flex', gap:'0.25rem', alignItems:'center'}}>
    //                   <b>
    //                   {cities[s]?.length}  
    //                   </b>

    //                   <div style={{cursor:'pointer',display: 'flex', flexDirection:'column',}}>

    //                   { cities[s]?.map((c,i) =>{
    //                       return( 
    //                       <span key={i}>{c}</span>
    //                       )
    //                     })}
                       
    //                   </div>
                    
    //                   <img height='10rem' src='/assets/arrow-trend-up-solid.svg' />
    //               </span>
    //             </div>
    //           </div>
    //               <hr />

    //          </div>
    //          <hr/> 
    //           <span className='metric-list-item' style={{maxWidth: width <= 600? '325px':'100%', display:'flex'}}>
    //             <b>last scan: &nbsp;</b>
    //             <div id="hover-scroll-container" >  
    //                 <div id="hover-scroll-text">
                     
    //                    {thisSet ? thisSet[thisSet?.length-1]?.createdAt?.toLocaleString():''}
                       
    //                 </div>   
            
    //             </div>
                
    //           </span> 
    //           </div>
    //         </ListItem>
         
    //       )
    //     })}
    //      </List>
          }

        {view === 'follows' && (<Container 
            // className='center'
            style={{ display:'flex', flexDirection:'column',
              maxHeight:'100%',height:'100%',
            listStyle:'none', justifyContent: 'center',textAlign: 'left', 
            // marginTop: width <= 600 ? '30rem':'30rem',
            width:'100%',// padding:'1rem 0',margin:0,marginTop:'10rem'
            
            }}> 
        { followHeaders?.map((fh,x)=>{
            const rows = followRows(fh)
            // console.log('[follow] rows:', rows)
              return (
                  <div  key={x}>  
                    <span style={{display: 'flex', flexDirection:'row',
                    // position:'absolute',left:'1rem',top:'0.75rem', 
                    textAlign:'center',cursor: 'pointer',gap:'0.25rem',whiteSpace:'nowrap'}}
                    onClick={()=>{
                      // followTabs[fh]= !followTabs[fh]
                    setFollowsTabs({...followsTabs,
                      [fh]:!followsTabs[fh]
                    })
                    }}
                    >
                  {/* <br/> */}
                  <div style={{margin:'0.5rem auto'}}>
                    <b>{fh}</b>
                  </div>
              <img className={`${followsTabs[fh]?'rot180':'rot-180'}` }
              src={`/assets/arrow-${followsTabs[fh]?'up':'down'}.svg`} style={{height:'0.75rem', alignSelf: 'center',opacity:followsTabs[fh]?'1':'0.3'}} alt="show hearder content"/>
              {/* } */}
            </span>
        <br/>
        {/* <hr/> */}
        {/* <div style={{border:'1px solid #868686'}}> */}
{/* 
              {follows?.map((f,j)=>{ */}
            {/* //  let date =  */}
            {/* const rows = followRows(fh)
              return (
                <> */}
                 <DataGrid
                  key={x}
                  rows={rows}
                  columns={followColumns}
                  initialState={{
                  pagination: {
                      paginationModel: {
                      pageSize: 15,
                      // color:'#00ff00'
                      },
                  },
                  }}
                  components={{
                    Pagination: (props) => (
                    <Pagination {...props} className="custom-pagination" />
                    ),}}
                // getRowId={(row)=>{row.qr_id}}
                // getRowClassName={() => 'share-row-text-color'}
                pageSizeOptions={[5]}
                // checkboxSelection
                disableRowSelectionOnClick
                sx={{
                width:'100%',
                marginTop:'0.5rem'
                  
            }}
            />
                  {/* { (followsTabs[fh] && fh === f.POC_email )&& <li className='follow-list-card button-accent-hover' 
                          style={{display:'flex',flexDirection:'column',
                          alignSelf:'center',
                          border:'1px solid #000000',padding: '0.5rem',cursor:'pointer',fontSize:'0.75rem',borderRadius: '5px',margin:'0.25rem auto',
                          // marginRight:0,
                          width:'100%',minWidth:'200px',
                          height:'fit-content',minHeigh:'3rem'
                          }} key={j}>
                          
                            <b>{f?.follower_email}:</b>
                            <b> {f?.geolocation? f?.geolocation?.city+', ' +f?.geolocation?.state:''} </b>
                            <p> {Date(f?.createdAt).toLocaleString()} </p>
                          </li>  } */}
                  {/* </> */}
                        
                        {/* })} */}
        </div>
              //  </div>
        )
        })}
                 
                 </Container>)}

            </Container>  }    
                {/* <div style={{textAlign:'left', margin: 'auto', width:'100%', padding:'0.25rem'}}> */}
                    {/* <h3> {user?.preferredName}</h3>
                    <p>{user?.email}</p>
                    <p>{user?.phone}</p> */}
                {/* </div> */}
                
                
            </div>
          



</Container>)}
    </>
  )
}

export default MetricCard