import React, { useEffect, useRef, useState, useCallback } from 'react';
import EnvRoute from '../../../middleware/envRoute';
import {
    Box, Button, Stack, Tooltip,
    Typography, TextField, InputAdornment, IconButton
} from '@mui/material';
import * as API from '../../../utils/APICalls';
import * as helpers from '../../../utils/helpers';
import SendIcon from '@mui/icons-material/Send';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const ChatFeed = ({ isVideoOnTop, socketRef, isHost,
    listener, roomId, isJoined, isJoining, memoizedJoinRoom
}) => {
    const [message, setMessage] = useState('')
    const [messages, setMessages] = useState([])
    const [hasMore, setHasMore] = useState(false);
    const contentRef = useRef(null);



    const inputStyles = {
        width: '80%',
        position: 'sticky',
        bottom: '0',

        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                // height: '3.5rem',
                borderColor: '#00FF00',
                borderRadius: '50px',
                boxShadow: '0px 0px 5px 3px rgba(0, 255, 0 ,0.5),inset 0px 0px 3px 1px rgba(0, 255, 0 ,0.2)',
            },
            '&:hover fieldset': {
                borderColor: '#00FF00',
                boxShadow: '0px 0px 5px 3px rgba(0, 255, 0 ,0.5),inset 0px 0px 3px 1px rgba(0, 255, 0 ,0.2)',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#00FF00',
                boxShadow: '0px 0px 5px 3px rgba(0, 255, 0 ,0.5),inset 0px 0px 3px 1px rgba(0, 255, 0 ,0.2)'
            },
        },
        '& .MuiInputLabel-root': {
            color: '#FF00FF',
            boxShadow: '0px 0px 5px 3px rgba(0, 0, 0 ,0.5),inset 0px 0px 5px 3px rgba(0,0, 0 ,0.5)',
            background: 'rgba(0,0,0,0.5)',
            backdropFilter: 'blur(5px)'
        },
        '& .MuiInputBase-input': {
            color: '#00FF00',
        },

    }

    const handleSend = () => {
        if (message !== '' && isJoined) {
            // let name;
            // if (isHost) {
            //     name = listener?.preferredName ? listener?.preferredName : listener?.email?.split('@')[0]
            // } else {
            //     name = listener?.preferredName ? listener?.preferredName : 'listener' + listener?._id
            // }
            try {
                let msgObj = {
                    ownerId: listener?._id,
                    ownerName: listener?.preferredName, //? listener?.preferredName : listener?.email?.split('@')[0],
                    // ownerName: name,
                    roomId,
                    text: message,
                    time: new Date()
                }
                console.log('[ handleSend ] msgObj:', msgObj)
                let tempMsgs = messages
                tempMsgs.push(msgObj)
                setMessages(tempMsgs);
                // setMessages([...messages, msgObj]);
                console.log('[ handleSend ] messages:', messages)
                socketRef.current?.emit('new-message', { ...msgObj })
                setMessage('')
            } catch (err) { console.log(err) }
        } else { console.log('no message to send') }
    }

    const keyPress = (e) => {
        if (e.keyCode == 13) {
            // console.log('value', e.target.value);
            // put the login here
            handleSend()

        }
    }

    const isOwner = (id1, id2) => { return id1 === id2 }
    function hasMoreToScroll(element) {
        return element?.scrollHeight > element?.clientHeight;
    }

    useEffect(() => {

    }, [message, messages])

    useEffect(() => {

    }, [listener])

    useEffect(() => {
        if (socketRef.current) {

            socketRef.current?.on("message-received", (newMessageRecieved) => {
                // if (
                //     !selectedChatCompare || // if chat is not selected or doesn't match current chat
                //     selectedChatCompare._id !== newMessageRecieved.chat._id
                // ) {
                //     if (!notification.includes(newMessageRecieved)) {
                //         setNotification([newMessageRecieved, ...notification]);
                //         setFetchAgain(!fetchAgain);
                //     }
                // } else {
                // const isMessage = messages?.find((m) => m === newMessageRecieved)
                // console.log('isMessage:', isMessage)
                // if (!isMessage) {

                setMessages([...messages, newMessageRecieved]);
                // }
                // }
            });
        }
    });

    useEffect(() => {
        const element = contentRef.current;
        console.log('hasMore:', hasMore)
        if (element) {
            setHasMore(hasMoreToScroll(element));
        }
    }, [contentRef]);


    return (
        <Box sx={{
            position: 'relative',
            display: 'flex', flexDirection: 'column',
            background: !isVideoOnTop ? 'rgba(0,0,0,0.5)' : '#000',
            backdropFilter: 'blur(5px)',
            border: `2px solid ${isVideoOnTop ? 'rgba( 0, 0, 0, 1)' : 'rgba( 0, 255, 0, 1)'}`,
            boxShadow: `0px 0px 8px 4px  ${isVideoOnTop ? 'rgba( 0, 0, 0, 0.5)' : 'rgba( 0, 255, 0, 0.5)'}`,
            overflow: 'hidden', overflowY: 'scroll',
            height: '35vh',
            width: '95%',
            maxWidth: '600px',
            minHeight: '250px', gap: '0.5rem', color: '#00FF00',
            borderRadius: '5px',
            margin: 'auto',
            marginBottom: '2.5rem'
        }}>
            <Box sx={{ position: 'sticky', top: 0, left: 0, zIndex: 0, width: '100%', height: '1.5rem', background: '#F6F6F6' }}>

                <Typography sx={{ color: '#858585' }} className='blink'>
                    <b>{listener?.preferredName}'s Chat Feed</b>
                </Typography>
            </Box>

            <Stack sx={{
                height: '100%', width: '100%',
                padding: '0.75rem',
                // flex: 1,
                margin: 'auto',
                // overflow: 'hidden'
            }} >
                {/* {!isJoined && (
                    <Box sx={{
                        position: 'absolute', left: '0px', top: '0px',
                        width: '100%', height: '100%', backgroundColor: 'rgba( 0, 0, 0,0.5) ',
                        zIndex: 99999,
                    }}>

                        <Button
                            disabled={isJoining}
                            loading={isJoining}
                            // color='success'
                            className='blink'
                            sx={{
                                position: 'absolute', left: '50%',
                                top: '50%', transform: 'translate(-50%, -50%)',
                                color: isJoining ? 'rgba( 255, 135, 1, 1)' : '#00FF00', borderColor: isJoining ? 'rgba( 255, 135, 1,1)' : '#00FF00',
                                boxShadow: `inset  0px 0px 5px 4px ${isJoining ? 'rgba( 255, 135, 1,1)' : ' rgba( 0, 255, 0, 0.3)'} `,
                                width: '55px',
                            }}
                            variant='outlined' size="small" onClick={memoizedJoinRoom}>
                          
                            Join{isJoining ? 'ing' : ''} Room
                        </Button>
                    </Box>
                )} */}
                <Stack
                    ref={contentRef}
                    sx={{
                        position: 'relative',
                        color: '#00FF00',
                        width: '96%',
                        overflow: 'hidden',
                        overflowY: 'scroll',
                        height: '150px',
                        padding: '0.5rem',
                        width: '95%',
                    }}>
                    {messages && messages?.map((msg, i) => {
                        // console.log('msg:', msg)

                        return (
                            <Stack key={i} sx={{
                                justifyContent: isOwner(msg?.ownerId, listener?._id) ? 'end' : 'start',
                                alignSelf: isOwner(msg?.ownerId, listener?._id) ? 'end' : 'start',
                                width: 'fit-content', maxWidth: '300px'
                            }} >
                                <span style={{
                                    display: 'flex',
                                    textAlign: isOwner(msg?.ownerId, listener?._id) ? 'end' : 'start',
                                    alignSelf: isOwner(msg?.ownerId, listener?._id) ? 'end' : 'start',
                                }}>
                                    {(!isOwner(msg?.ownerId, listener?._id)) && <b style={{ color: '#FF00FF' }}>{msg?.ownerName}:</b>}
                                    &nbsp;{msg?.text}
                                    {(isOwner(msg?.ownerId, listener?._id)) && <b style={{ color: '#FF00FF' }}> &nbsp;:You</b>}
                                </span>
                                <span style={{ fontSize: '0.7rem', alignSelf: isOwner(msg?.ownerId, listener?._id) ? 'end' : 'start' }}>
                                    ( {helpers?.getDisplayDate(msg?.time)} )
                                </span>
                            </Stack>
                        )
                    })}


                </Stack>

                <Box sx={{
                    // display: 'flex',
                    // flexDirection: 'column',
                    position: 'absolute',
                    bottom: '1rem',
                    // left: '50%',
                    // transform: 'translate(-50%,0%)',
                    width: '100%',
                    gap: '0.5rem',
                    // margin: 'auto'
                    justifyContent: 'center'
                }}>
                    {hasMore && <span style={{
                        display: 'flex', color: '#00FF00', marginBottom: '0.5rem',
                        alignContent: 'center', justifyContent: 'center',
                        margin: 'auto',
                    }}><ArrowDropUpIcon sx={{ transform: 'rotate(180deg)' }} />&nbsp;scroll&nbsp;<ArrowDropUpIcon sx={{ transform: 'rotate(180deg)' }} /></span>}
                    <TextField label='message'
                        variant="outlined"

                        sx={inputStyles}
                        value={message}
                        onChange={(e) => { setMessage(e.target.value) }}
                        onKeyDown={(e) => { keyPress(e) }}

                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        sx={{ color: '#00FF00' }}
                                        aria-label="send"
                                        disabled={message === ''}
                                        onClick={handleSend}
                                        //   onMouseDown={handleClicK}
                                        edge="end"
                                    >
                                        <SendIcon sx={{ color: message === '' ? 'rgba( 0, 255,0,0.4)' : '#00FF00' }} />
                                    </IconButton>
                                </InputAdornment>
                        }}

                    />
                    {isVideoOnTop && <Box sx={{
                        // position: 'absolute', right: '0.3rem', top: '0.1rem',
                        color: '#00FF00', fontSize: '0.75rem', fontWeight: 'bold',
                        zIndex: 99999,
                        width: 'fit-content',
                        margin: 'auto',
                        marginTop: '0.5rem',
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                        padding: '0.125rem 0.5rem',
                        backdropFilter: 'blur(5px)',
                        borderRadius: '5px',
                    }}>click to bring forward</Box>}
                </Box>
            </Stack >
        </Box >

    )
}

export default ChatFeed