import RedirectHeader from './RedirectHeader';
import { AppProvider, AppContext, AppState } from "../context/AppProvider";
import { QRCodeContext } from "../context/QRCodeProvider";

import Modal from './Modal'

import { useParams } from "react-router-dom";
import React, {useEffect, useContext, useState} from 'react'
import * as API from '../utils/APICalls'

import axios from 'axios'

import EnvRoute from '../middleware/envRoute';
import LandingPage from './LandingPage';
import LoadingSpinner from './LoadingSpinner';
import PersonaCard from './PersonaCard';
import GeoConditions from './GeoConditions';
import ActivateQR from './ActivateQR';
// import { registerUser } from '../../../api/controllers/userControllers';

const ENDPOINT = EnvRoute('prod'); 


// const ENDPOINT = `http://localhost:5003/api`
//   const ENDPOINT = `https://qaura-api.onrender.com/api`

const ScanRedirect =  () => {
    const { qrCodeData, updateQRCodeData } = useContext(QRCodeContext);
    const { appData, updateAppData, modalOpen, 
            geoData, setGeoData, scanQR, setScanQR } = AppState();
    // const [ geoData,setGeoData ] = useState(false);
    const [ scanData, setScanData ] = useState()
    const [ scanSent, setScanSent ] = useState(false)
    const [ qrFields, setQrFields ] = useState()
    const  [ geoConditions, setGeoConditions ] = useState(qrCodeData?.geoConditions)
    const [ IP, setIP ] = useState()
    const [ statusText, setStatusText ] = useState('[fetching data...]')
    const [ isLink, setIsLink] = useState(false)
    const [ dataBlock, setDataBlock ] = useState()
    const [resolvedPayload, setResolvedPayload] = useState(false)
    const [ payloadType, setPayloadType ] = useState(qrCodeData?.payload_type)
    const [ directScan, setDirectScan ] = useState(false)

    const qr_id = useParams().id?.toString()
    const isDemo = qr_id === '00000001'? true : false
    //   const regionTypes = [
    //     'location','zip','city', 'county','state','country',
    //     'exact ( lat, lon ) coords'
    // ]
    
    const fetchQRdata =  async () =>{
        console.log('[fetch qr_id: ', qr_id)
        // const config = {
        //     headers: {
        //         "Content-type" : "application/json"
        //     }
        // }
        try{
            setStatusText('[Fetching QR data...]')
            // const { data } = await axios.post(`${ENDPOINT[0]}/qr/check`,{qr_id},config)
            const data  = await API.fetchQRByID(qr_id)
            if(data?.data){
                setStatusText('[Data Received...]')
            }else{setStatusText('[Processing...]')}
            
            const resData = await buildData(data?.data)
            if( resData ){

                localStorage.setItem('scanData', JSON.stringify(resData))
                
                collectScanData()
                
                console.log('[Data Received...]',resData)
                
                // scanQR(data.data)
                // updateAppData({
                //     ...appData,
                //     scanQR
                //     })
                // try{
                console.log('check Payload',qrCodeData)
                setStatusText('[Processing...]')
    
                // if(geoConditions?.length > 0 ){
                //     conditionCheck(geoConditions)
                // }else{
                    checkPayload(qrCodeData)
                // }
                
                setStatusText('')
                updateQRCodeData(data?.data)
            }
                // }catch(err){console.log("[Redirect Error]: While Checking Payload")}
              // }
        }catch(err){console.log("[Redirect Error]: Fetching qr",err)}

    }
    //This function takes in latitude and longitude of two locations
// and returns the distance between them as the crow flies (in meters)
const calcCrow = (coords1, coords2) =>{
    // var R = 6.371; // km
    var R = 6371000;
    var dLat = toRad(coords2.lat-coords1.lat);
    var dLon = toRad(coords2.lng-coords1.lng);
    var lat1 = toRad(coords1.lat);
    var lat2 = toRad(coords2.lat);

    var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c;
    console.log('Cal Distance:',c)
    return d;
    }

    // Converts numeric degrees to radians
    const toRad = (Value) =>{
        return Value * Math.PI / 180;
    }
    const meterToMile = (Value) =>{
        return Value / 1609.34 
    }
         const regionTypes = [
        'location','zip','city', 'county','state','country',
        'exact ( lat, lon ) coords'
    ]

    const bboxCheck = (point,bbox) =>{
        // Check if the point is within the bbox's x-axis range.
        if (point.x < bbox.xMin || point.x > bbox.xMax) {
            return false;
        }

        // Check if the point is within the bbox's y-axis range.
        if (point.y < bbox.yMin || point.y > bbox.yMax) {
            return false;
        }

        // The point is inside the bbox.
        return true;
        }





    
const conditionCheck = async (arr) =>{
 if(qrCodeData?.geoConditions?.length > 0 ){
    arr?.forEach(async(e,i)=>{
        try{
            if(!scanData){
                collectScanData()
            }
            //else{

                if(e?.on){
                    console.log('[conditionCheck] element:',e)
                    if( e?.pin?.lat && e?.pin?.lon && e?.pin?.range){
                        console.log('[conditionCheck] geoPin Based:',e)
                        let  condCoords = {
                            lat: e?.pin?.lat,
                            lon: e?.pin?.lon, 
                        }
                        let scanCoords = {
                            lat: geoData?.geolocation?.latitude,
                            lon: geoData?.geolocation?.longitude, 
                        }
                        console.log('distance',Number(calcCrow( condCoords, scanCoords )))
                        if( meterToMile(calcCrow( condCoords, scanCoords ) )<= e?.pin?.range){
                            console.log('[conditionCheck] geoPin Based:',e)
                            await setResolvedPayload(e?.bypassPayload)
                            await setPayloadType(e?.payload_type)
                            await setDirectScan(qrCodeData?.direct_scan)
                            return e?.bypassPayload
                        }else{
                            await setResolvedPayload(qrCodeData?.payload)
                            console.log('!!!![Basic Business]:!!!',resolvedPayload); // true
                            return qrCodeData?.payload
                        }
                    }
                    if(e?.region !== null){
                        console.log('[conditionCheck] Region-Based:',e)
                        
                        if(e?.region?.regionType ==='location'){
                            const point = { x: scanData?.geelocation?.longitude, y: scanData?.geelocation?.latitude };
                            const bbox = { xMin: e?.region?.bbox?.lon1, yMin:e?.region?.bbox?.lat1, xMax: e?.region?.bbox?.lon2, yMax: e?.region?.bbox?.lat2 };
                            
                            try{
                                const isInside = bboxCheck(point, bbox);
                                if(isInside){
                                    console.log('[INSIDE LOCATION ZONE]:',isInside); // true
                                    await setResolvedPayload(e?.bypassPayload)
                                    await setPayloadType(e?.payload_type)
                                    await setDirectScan(qrCodeData?.direct_scan)
                                    return e?.bypassPayload
                                }else{
                                    await setResolvedPayload(qrCodeData?.payload)
                                    await setPayloadType(qrCodeData?.payload_type)
                                    await setDirectScan(qrCodeData?.direct_scan)
                                    return qrCodeData?.payload
                                }
                            }catch(err){console.log(err)}
                        }
                        if(e?.region?.regionType ==='zip'){
                            console.log('[conditionCheck] Region-Based (zip):',e)
                            console.log('[conditionCheck] Region-Based (zip)-region.postcode:',e?.region?.postcode)
                            console.log('[conditionCheck] Region-Based (zip)-.geolocation?.postal:',scanData?.geolocation?.postal)
                            if(e?.region?.postcode.toString() === scanData?.geolocation?.postal.toString()){
                                console.log('[conditionCheck] Region-Based (zip)+Condidition:',e)
                                await setResolvedPayload(e?.bypassPayload)
                                await setPayloadType(e?.payload_type)
                                await setDirectScan(qrCodeData?.direct_scan)
                                return e?.bypassPayload
                            }else{
                                await setResolvedPayload(qrCodeData?.payload)
                                await setPayloadType(qrCodeData?.payload_type)
                                await setDirectScan(qrCodeData?.direct_scan)
                                return qrCodeData?.payload
                            }
                        }
                            else if(e?.region?.regionType ==='city'){
                                if(e?.region?.city?.includes( scanData?.geolocation?.city )){
                                    await setResolvedPayload(e?.bypassPayload)
                                    await setPayloadType(e?.payload_type)
                                    await setDirectScan(qrCodeData?.direct_scan)
                                    return e?.bypassPayload
                                }
                            }
                            else if(e?.region?.regionType ==='county'){
                                if(e?.region?.county?.contains(scanData?.geolocation?.county)){
                                    await setResolvedPayload(e?.bypassPayload)
                                    await setPayloadType(e?.payload_type)
                                    await setDirectScan(qrCodeData?.direct_scan)
                                    return e?.bypassPayload
                                }else{
                                await setResolvedPayload(qrCodeData?.payload)
                                await setPayloadType(qrCodeData?.payload_type)
                                await setDirectScan(qrCodeData?.direct_scan)
                                return qrCodeData?.payload
                            }
                            }
                            else if(e?.region?.regionType ==='state'){
                                if(e?.region?.state?.contains(scanData?.geolocation?.state)){
                                    await setResolvedPayload(e?.bypassPayload)
                                    await setPayloadType(e?.payload_type)
                                    await setDirectScan(qrCodeData?.direct_scan)
                                    return e?.bypassPayload
                                }else{
                                    await setResolvedPayload(qrCodeData?.payload)
                                    await setPayloadType(qrCodeData?.payload_type)
                                    await setDirectScan(qrCodeData?.direct_scan)
                                    return qrCodeData?.payload
                                }
                            }
                            if(e?.region?.regionType === 'country'){
                                console.log('[conditionCheck] Region-Based (Country):',e)
                                if(e?.region?.country?.contains( scanData?.geolocation?.country)){
                                    await setResolvedPayload(e?.bypassPayload)
                                    await setPayloadType(e?.payload_type)
                                    await setDirectScan(qrCodeData?.direct_scan)
                                    return e?.bypassPayload
                                }else{
                                    await setResolvedPayload(qrCodeData?.payload)
                                    await setPayloadType(qrCodeData?.payload_type)
                                    await setDirectScan(qrCodeData?.direct_scan)
                                    return qrCodeData?.payload
                                }
                            }
                        }
                    }else{
                        console.log('!!!![Basic Business(not effected by Geo-Conditions)]:!!!'); // true
                        await setResolvedPayload(qrCodeData?.payload)
                        await setPayloadType(qrCodeData?.payload_type)
                        await setDirectScan(qrCodeData?.direct_scan)
                        return qrCodeData?.payload
                    }
            // }
    

    }catch(err){console.log(err)}
    })
    }else{
        await setResolvedPayload(qrCodeData?.payload)
        await setPayloadType(qrCodeData?.payload_type)
        await setDirectScan(qrCodeData?.direct_scan)
        return qrCodeData?.payload
    }
}

    // const conditionCheck = (obj) =>{
    //     if(){

    //     }
    //     else{
    //         return obj?.payload
    //     }
    // }
    const buildData = async (obj) =>{
        try{

        let p = await conditionCheck(obj?.geoConditions)
         const data = {
            owner: obj?.owner,
            qr_id: obj?._id,
            qr_name: obj?.name,
            qr_payload: resolvedPayload ? resolvedPayload : qrCodeData?.payload,
            geolocation: await getGeoData(),
           location:{ 
               host: document.location.host,
               href: document.location.href,
               origin: document.location.origin,
               pathname: document.location.pathname,
               port: document.location.port,
           },
           referrer: document.referrer,
           screen:{ 
               availHeight:window.screen.availHeight,
               availWidth:window.screen.availWidth,
               height:window.screen.height,
               width:window.screen.width,
               pixelDepth:window.screen.pixelDepth,
               orientation:{
                  angle: window.screen.orientation.angle,
                  type: window.screen.orientation.type,
               }
    
           },
           navigator: navigator.userAgent,
          
       }
       console.log('buildData:', data)
       return data
    }catch(err){console.log('[buildData]',err)}
    }
    const adjustProtocol = (url) =>{
        let adjURL = 'https://' + url
        return adjURL
    }
    const isValidUrl = async (string) =>{
        let url
        try {
           url = new URL(string);

            console.log('isValidUrl: ', string)
            // return true;
        } catch (err) { 
           
            // await setResolvedPayload(temp)
            console.log('[NO PROTOCOL]')
            return 
        }
        // if(url.protocol !== "http:" || url.protocol !== "https:" || url.protocol !== "ftp:"){
        //     let temp = 'https://'+ string
        //     // setResolvedPayload(temp)
        //     console.log('[NO PROTOCOL]',temp)
        //     return temp
        // }
        // else{console.log('[PROTOCOL]',url)}
        return (url.protocol === "http:" || url.protocol === "https:" || url.protocol === "ftp:");
    }
    
    // const checkPayload = (obj) =>{
    //     if( isValidUrl(qrCodeData?.payload)){
            
    //         // console.log('isValidUrl: ', isValidUrl(qrCodeData?.payload))
    //         setIsLink(true)
    //         if(qrCodeData?.payload.contains('qaura.pro/fm/')){
    //             window.location.replace('qaura.pro/fm/'+qrCodeData?.payload)

    //         }
    //         else if(qrCodeData?.payload.contains('qaura.pro/persona/')){
    //             window.location.replace('qaura.pro/persona/'+qrCodeData?.payload)

    //         }
    //         else if(qrCodeData?.payload.contains('qaura.pro/signupsheet/') || qrCodeData?.payload.contains('qaura.pro/partners/')){
    //            followLink()

    //         }
    //         else if(qrCodeData?.direct_scan){
    //             window.location.replace(qrCodeData?.payload)

    //         }
    //         // console.log('isLink: ', isLink)
    //     }else if(!isValidUrl(qrCodeData?.payload)){
    //         setIsLink(false)
    //         if(qrCodeData?.direct_scan){
    //             window.location.replace(qrCodeData?.payload)

    //         }
    //         setDataBlock(qrCodeData?.payload) 
    //     }else{return}
    // }
    const checkPayload = (obj) =>{
        if( resolvedPayload){
            
            // console.log('isValidUrl: ', isValidUrl(resolvedPayload))
            setIsLink(true)
            if(resolvedPayload?.includes('qaura.pro/fm/')){
                // window.location.replace('qaura.pro/fm/'+resolvedPayload)
                window.location.replace(resolvedPayload)

            }
            else if(resolvedPayload?.includes('qaura.pro/persona/')){
                window.location.replace(resolvedPayload)

            }
            else if(resolvedPayload?.includes('qaura.pro/storefront/')){
                window.location.replace(resolvedPayload)

            }
            else if(resolvedPayload?.includes('qaura.pro/signupsheet/') || resolvedPayload.contains('qaura.pro/partners/')){
               followLink()

            }
            else if(qrCodeData?.direct_scan){
                window.location.replace(resolvedPayload, '_blank')

            }
            // console.log('isLink: ', isLink)
        // }else if(!isValidUrl(resolvedPayload)){
            setIsLink(false)
            if(qrCodeData?.direct_scan ){
                window.location.replace(resolvedPayload, '_blank')

            }
            setDataBlock(resolvedPayload) 
        // }else{
        //     setDataBlock(resolvedPayload) }
    }
}
    const followLink = () =>{
        window.location.replace(resolvedPayload)
    }

    const getGeoData = async () => {
        try{

            // const res = await axios.get('https://geolocation-db.com/json/')
            // console.log('user geo res.data: ', res.data)
            // if(res?.data){

            //     //!Change when Google API Change 
            //     setGeoData({...res?.data})
            //     console.log('user geodata: ', geoData)
            //      return res?.data
            // }else{

            // }

            
            // if(res?.data){
            //     collectScanData(res?.data)
            // }
            
            //   if (navigator.geolocation) {
            //   if (res?.data?.IPv4) {
            //      var requestOptions = {
            //         method: 'GET',
            //         };
            //     fetch(`https://api.geoapify.com/v1/ipinfo?ip=${geoData?.IPv4}&apiKey=2489433b042f4e95acb6ee6f13bb447b`, requestOptions)
            //     .then(response => response.json())
            //     .then(result => {
            //         console.log('[GeoApify]:',result)
            //         // setRegionList(result.results)
            //     })
    
            //   }else{console.log('No IP')}
    
          
            // TODO--------When Google API KEY------------
            // TODO---------------------------------------
            //  if ( !res.ok ) {
            //   if (navigator.geolocation) {
    
            //     const returnCurrentPosition = async position => {
            //       const latitude = position.latitude;
            //       const longitude = position.longitude;
            //       let geolocationData = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=[API_KEY]`)
            //       .then(response => response.json())
            //       .then(data => {
            //           // Check if response status is OK
            //           if (data.status === "OK") {
            //               // Extract state from address components
            //               state = data.results[0].address_components.find(component =>
            //                   component.types.includes('administrative_area_level_1')
            //               ).long_name;
    
            //               console.log("State:", state);
            //           } else {
            //               quizEligibility = 'cannot find location';
            //           }
            //       })
            //     }
    
                const getNavigatorLocation = async () => {
                  return new Promise((resolve, reject) => {
                      navigator.geolocation.getCurrentPosition(
                          position => {
                              resolve(position.coords);
                          },
                          error => {
                              reject(error);
                          }
                      );
                  });
                }
                
                const currentPosition = await getNavigatorLocation()
                console.log('[currentPosition]:',  currentPosition)
                return currentPosition
                 // if (navigator.geolocation) {
            //   const  nav_geo =  navigator.geolocation
            // console.log('user geo [navigator.geolocation]: ', nav_geo)
            // }
                // await returnCurrentPosition(currentPosition)
                
            //   } else {
            //     quizEligibility = 'cannot find location';
            //   }
            // }
            // TODO--------When Google API KEY------------
            // TODO---------------------------------------
            setGeoData(currentPosition)
        }catch(err){console.log('[getGeoData] [error]:')}
       

    }

    const  objVal = async (obj,key) =>{
        console.log('obj[key]: ', obj[key])
        return obj[key].toString()
    }

    const collectScanData = async (geo) =>{
        console.log('collectScanData qr: ',qrCodeData)
        const q = JSON.parse(localStorage.getItem('scanData'))
        console.log('[data1]----->:',q)
        const res = await API.saveScan(q)
        if(res){
            
            console.log('scan res',res)
            try{
                await setScanData(res)
                await setScanSent(true)    
                console.log('scanData',scanData)
            }catch(err){console.log(err)}
        }else{
            console.log('scan data sending error')
            setScanSent(false)
        }
    }

    const resolvePayload = async () =>{
        console.log('resolvedPayload begin:')
                // if(qrCodeData?.geoConditions?.length ){
                    try{
                        let p = await conditionCheck(qrCodeData?.geoConditions)
                        console.log('resolvedPayload p conditions:',p)
                        if (p){
                            console.log('resolvedPayload p conditions:',p)
                             if( payloadType === 'url' && !isValidUrl(p)){
                                    let temp = adjustProtocol(p)
                                    await setResolvedPayload(temp)
                                }else{
                                await  setResolvedPayload(p)
                                console.log('resolvedPayload:',resolvedPayload)
                              }  
                              if (payloadType === 'url' && qrCodeData?.direct_scan ){
                                window.location.replace(resolvedPayload)
                              }
                        }
                    }
                    catch(err){console.log('resolvedPayload [error]',err)}
                // }else{
            
                //     await setResolvedPayload(qrCodeData?.payload)
                // }
            }

    useEffect(()=>{
        if( !qrCodeData && !isDemo ){

            fetchQRdata()
        }else{return}
        
    },[qrCodeData])

    useEffect(()=>{

      
        
    },[])

    useEffect(()=>{
        if(!resolvedPayload){
            resolvePayload()
            // console.log('[useEffect] :',resolvedPayload)
        }//else{conditionCheck(qrCodeData?.geoConditions)}
       
    },[resolvedPayload,scanData])

    
    let s = 0
    useEffect(()=>{
        try{
            const q = JSON.parse(localStorage.getItem('scanData'))
            if(q){
                // let p = qrCodeData?.payload
                let p = resolvedPayload
                let GCL = qrCodeData?.geoConditions?.length
                //TODO ---Make so if resolved payload contains below 
                if(GCL < 1 ){
                    const u = {
                        root1: 'https://qaura.rexentropy.com',
                        root2: 'https://qaura.netlify.app',
                        root3: 'https://qaura.pro',
                        fm : '/fm/',
                        per : '/persona/',
                        part : '/partners/',
                        shop : '/storefront/'
                    }
                    if((p?.includes(u.root1)||p?.includes(u.root2)||p?.includes(u.root3))&&(p?.includes(u.fm) || p?.includes(u.per)|| p?.includes(u.part) || p?.includes(u.shop)) ){
                                localStorage.setItem('scanData','')
                                window.location.replace(p)
                
                        }
                    if (payloadType === 'url' && directScan ){
                            window.location.replace(p)
                        }
                        s+=1
                }else{conditionCheck(qrCodeData?.geoConditions)}
            }
            // else{
            //     setResolvedPayload(qrCodeData?.payload)
            // }

        }catch(err){console.log(err)}
        
        
    },[scanSent,qrCodeData]) 
    if(isDemo){
        window.location.replace('/error')
        }
    else{
    
    return (
    <div style={{ height:'100%',width:'100%'}}>
        <RedirectHeader /> 
        <div style={{ display: 'flex', flexDirection:'column', height: '100%',minHeight:'60vh', justifyContent: 'center', alignItems: 'center'}}>
            { appData.modalOpen &&
              <Modal confirm={false}>
                <h1 style={{color:"#FFFFFF"}}>{appData?.modalView}</h1>
                {/* { appData?.modalView==='scan' && <Scan /> }
                { appData?.modalView==='menu' && <Menu /> } */}
                {/* <div style={{height:'100%',overflow:'hidden',overflowY:'scroll'}}> */}
                    { appData?.modalView==='info' && (<LandingPage view ='modal'/> )}
                {/* </div> */}
              </Modal>}
            <div className= "color-grad-blue-border "
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '80%', height: '70%', 
                    border: '3px solid',
                    borderRadius: '20px', 
                    backgroundColor: 'rgba(255,255,255, 0.1)', 
                    backdropFilter: 'blur(3px)',
                    boxShadow: 'inset 0px 0px 10px 5px rgba( 255, 255, 255, 0.4), 0px 0px 20px 15px rgba( 0, 0, 0, 0.5)',
                    padding: '1rem',
                    marginTop: '2rem',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                <h1 className='text-shadow-02 blink' 
                    style={{marginBottom: '1.5rem', display: 'flex', flexDirection:'row'}}>
                Scan Redirect <div style={{content:'',width:'1rem',height:'1rem', borderRadius:'50%',backgroundColor: scanSent? '#00FF00':'orange', boxShadow:'inset 0px 0px 5px 3px rgba(0,0,0,0.5)'}}></div>
                </h1> 
                <div // className='blink'
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '20px',
                        width: '95%',height:'50%',
                        minHeight:'300px',
                        backgroundColor: 'rgba( 255,255,255, 0.2)',
                        backdropFilter: 'blur(3px)',
                        border: '3px solid rgba( 0, 0, 0, 1)', 
                        boxShadow: 'inset  0px 0px 15px 10px rgba( 0, 0, 0, 0.4),0px 0px 10px 5px rgba( 255, 255, 255, 0.7)',
                        justifyContent: 'center',
                        gap:'1rem',
                        overflow:'hidden'
                        }}>
    
                        {/* {!qrCodeData?.asset_id && (
                        <div style={{display: 'flex', flexDirection: 'column', gap: '0.5rem'}}>
                            <div className='scaler'>
    
                            <img className={'spinner'} src={'../assets/spinner-solid.svg'} 
                            style={{height:'50px', alignSelf: 'center', 
                            marginRight:'0.5rem'}} alt="loading"/>
                            </div>
                            {statusText}
                        </div>)} */}
          {/* <LoadingSpinner statusText={statusText} /> */}
    
          {/* <PersonaCard view='view' /> */}
          {!resolvedPayload &&  <LoadingSpinner statusText={statusText} />}
          {/* {!qrCodeData?.payload &&  <LoadingSpinner statusText={statusText} />} */}
                        <b style={{margin:'1rem auto',maxInlineSize:'95%',wordWrap:'break-word',cursor: payloadType === 'url' ?'pointer':''}} 
                            onClick={()=>{
                                // const payload = conditionCheck(qrCodeData?.geoConditions)
                               
                                console.log('[Post Condition Check]',resolvedPayload)
                                if(payloadType === 'url'){window.location.replace(resolvedPayload)}
                        }}><u>
                            {resolvedPayload}
                        
                        {/* { !resolvedPayload ? qrCodeData?.payload :''} */}
                        </u></b>
                        {payloadType === 'url' && scanSent ? (
                            <div style={{display: 'flex', flexDirection:"column", gap:'1rem'}}>
                            <button className='redirect-button blink' onClick={()=>{followLink()}}> 
                                Follow link
                            </button>
                            <button className='redirect-button blink' onClick={()=>{ navigator.clipboard.writeText(resolvedPayload)}}> 
                                Copy to clipboard
                            </button>
                            </div>
                        
                       )
                       :<p>{dataBlock}</p>}
                       { !qrCodeData?.isActivated && qrCodeData?.pin &&<ActivateQR pin={qrCodeData?.pin}/> }
                </div>
                <h5 style={{margin:'.5rem auto'}}>QR id:</h5>
                <h4 style={{margin:'.25rem auto'}}>{qr_id}</h4>
             <div style={{display:'flex', width:'100%', justifyContent: 'center', alignItems: 'center', color:'#000000', margin: '0.5rem auto'}}>
                © 2023 - {new Date().getFullYear()} RexEntropyLLC
            </div> 
            </div>
        </div>
    </div>
    )
}

}

export default ScanRedirect