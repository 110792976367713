import {
    Container, Modal, Box, Button, Input,
    Card, Divider,
    Typography, TextField, Stack, Switch
} from '@mui/material'
import React, { useState, useContext, useEffect, useRef } from 'react'
import CloseButton from '../../../components/CloseButton'
import StreamComponent from './StreamComponent'
import { AppContext } from "../../../context/AppProvider";
import Login from '../../../components/auth/Login';
import io from 'socket.io-client';
import WebRTCComponent from './WebRTCComponent';
import EnvRoute from '../../../middleware/envRoute';
import * as API from '../../../utils/APICalls'
import KeyboardAwareWrapper from '../../../components/KeyboardAwareWrapper'
import {
    DialogContentText,
    DialogContent,
    DialogActions,
    DialogTitle,
    Dialog
} from '@mui/material';

import { Chat, LiveKitRoom, ParticipantTile, ChatEntry } from '@livekit/components-react';
import SingleWebRTCComponent from './SingleWebRTCComponent';
import HMSComp from './HMSComp';
import {
    useHMSActions,
    useHMSStore,
    selectHLSState,
    selectDevices,

} from '@100mslive/react-sdk';
// import MeteredFrame from '@metered/sdk-frame';

const StreamModal = (props) => {
    const frameRef = useRef(null);
    const { artist, setArtist, streamModalOpen, setStreamModalOpen,
        listener, updateModalOpen, setUpdateModalOpen,
        isStreaming, setIsStreaming, isHost, roomConfig, } = props
    const { appData, userData, setUserData, actionText, setActionText } = useContext(AppContext);
    const [showLogin, setShowLogin] = useState(false)
    const [showSettings, setShowSettings] = useState(false)
    const [isJoined, setIsJoined] = useState(false);
    const [showLeaveConfirm, setShowLeaveConfirm] = useState(false)
    const [isPlaying, setIsPlaying,] = useState(false)
    const [videoDevices, setVideoDevices] = useState([]);
    const hmsActions = useHMSActions();
    const devices = useHMSStore(selectDevices);

    const [currentCameraIndex, setCurrentCameraIndex] = useState(devices?.length > 0 ? 1 : 0);
    const localVideoRef = useRef();
    const remoteVideoRef = useRef();
    const peerConnectionRef = useRef();
    const socketRef = useRef();
    const isMobile = /iPhone|iPad|iPod|Android|BlackBerry|Windows Phone|webOS|Opera Mini|IEMobile|Mobile/i.test(navigator.userAgent);
    const touchCheck = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

    // const devices = useHMSStore(selectDevices);
    // let videoDevices// = devices?.videoInput;
    // navigator.mediaDevices.enumerateDevices()
    //     .then(devices => {
    //         videoDevices = devices.filter(device => device.kind === 'videoinput');
    //         // Use the deviceId of the desired camera
    //     });

    // let roomConfig = null
    // useEffect(()=>{
    //      var frame = new MeteredFrame(); 
    //         frame.init({
    //         roomURL: "rexentropy.metered.live/cktcs4oqzh",
    //     }, frameRef.current);

    // },[])

    const getVideoDevices = async () => {
        try {
            // const devices = await navigator.mediaDevices.enumerateDevices();
            // const videoDevices = devices.filter(device => device.kind === 'videoinput');
            console.log("videoDevices devices:", devices)
            if (devices) {

                setVideoDevices(devices?.videoInput)
                console.log("videoDevices:", videoDevices[0]?.deviceId)
            }

        } catch (error) {
            console.error('Error getting video devices:', error);
        }
    };
    const switchToSpecificCamera = async (e) => {
        const deviceId = e.target.value
        try {
            const selectedCameraId = deviceId
            // const devMap = videoDevices?.map((d, i) => {
            //     return d
            // })
            console.log('selectedCamera id:', selectedCameraId)
            const dev = await videoDevices?.find(v => v?.deviceId === selectedCameraId)
            console.log('selectedCamera dev:', dev)

            const newIndex = videoDevices.indexOf(dev)
            setCurrentCameraIndex(newIndex);
            await hmsActions.switchCamera(deviceId);
            console.log('Switched to specific camera', dev);
        } catch (error) {
            console.error('Failed to switch to specific camera:', error);
        }
    };
    useEffect(() => {

    }, [userData])
    useEffect(() => {

    }, [currentCameraIndex, videoDevices, devices])
    useEffect(() => {

    }, [isJoined]);
    useEffect(() => {
        getVideoDevices()
    }, []);
    useEffect(() => {
        // console.log('[StreamModal]  roomConfig:', roomConfig)

    }, [roomConfig])
    if (!listener?.preferredName && streamModalOpen) {
        return (
            <Dialog
                open={!listener?.preferredName}
                // onClose={}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"No username"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Must have a username to enter?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setStreamModalOpen(false)}>Cancel</Button>
                    <Button onClick={() => { setUpdateModalOpen(true) }} autoFocus>
                        update
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
    return (
        <>
            <Modal
                open={streamModalOpen}
                onClose={() => setStreamModalOpen(!streamModalOpen)}
                disableEscapeKeyDown={isJoined}
            >
                <Container sx={{ zIndex: 999, padding: '0.5rem', }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '100%',
                        maxWidth: '98vw',
                        height: '98vh',
                        overflow: 'hidden',
                        overFlowY: 'scroll',
                        backgroundColor: 'rgba( 0, 0, 0, 0.8)',
                        backdropFilter: 'blur(5px)',
                        borderRadius: '10px',
                        border: '2px solid #000',
                        fontFamily: 'Gilroy, sans-serif',
                        justifyContent: 'center',
                        textAlign: 'center',
                        alignItems: 'center',
                        gap: '0.5rem',

                        color: '#FFF',
                    }}>
                        <Box onClick={() => {
                            console.log('isJoined:', isJoined)
                            if (isJoined) {
                                setShowLeaveConfirm(true)
                            }
                            if (!isJoined) {
                                setStreamModalOpen(false)
                            }
                        }}
                            sx={{ position: 'absolute', top: '1.75rem', right: '0.5rem', zIndex: 99999999 }}>
                            {/* <CloseButton func={(!isJoined || (isJoined && !isPlaying)) ? setStreamModalOpen : setShowLeaveConfirm} val={(!isJoined || (isJoined && !isPlaying)) ? streamModalOpen : showLeaveConfirm} */}
                            <CloseButton func={setShowLeaveConfirm} val={showLeaveConfirm}
                            />
                        </Box>
                        {isHost &&
                            <>
                                {!showSettings && <Box
                                    // className="button save-button "
                                    type="submit"
                                    onClick={() => {
                                        setShowSettings(true)
                                    }}
                                    style={{
                                        display: 'flex',
                                        position: 'absolute',
                                        top: '2.5rem',
                                        left: '1.5rem',
                                        borderRadius: '5px',
                                        background: 'rgba( 0, 0, 0,0)',
                                        cursor: 'pointer',
                                        zIndex: 99999
                                    }}>
                                    {/* <img src={'/assets/bars-solid.svg'} style={{height:'35px', alignSelf: 'center'}} alt="menu"/> */}
                                    <img className="settings-gear"
                                        src={'/assets/SettingsGearGreen.svg'} style={{ height: '25px', alignSelf: 'center' }} alt="menu" />
                                </Box>}
                                {showSettings && <Stack sx={{
                                    position: 'absolute', top: '25%', left: '50%',
                                    transform: 'translate(-50%,-25%)',
                                    zIndex: 99999999,
                                    gap: '0.75rem',
                                    justifyContent: 'center',
                                    border: '2px solid #00FF00', borderRadius: '8px',
                                    color: '#00FF00',
                                    bgcolor: 'rgba(0,0,0,0.7)', backdropFilter: 'blur(5px)',
                                    boxShadow: '0px 0px 5px 3px rgba(0,255,0,0.3)',
                                    padding: '0.75rem'
                                }}>
                                    <Typography>
                                        Settings
                                    </Typography>
                                    <Box className='button-woddle'
                                        sx={{
                                            display: 'flex', position: 'absolute',
                                            cursor: 'pointer',
                                            right: '0.5rem', top: '0.5rem',
                                            fontSize: '1rem',
                                            color: '#00FF00', fontWeight: 'bold',
                                            textAlign: 'center', alignContent: 'center', justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '1.125rem',
                                            width: '1.125rem',
                                            border: '1px solid #00FF00', borderRadius: '5px',
                                            color: '#00FF00',
                                            bgcolor: 'rgba(0,0,0,0.5)', backdropFilter: 'blur(5px)',
                                            boxShadow: '0px 0px 3px 1px rgba(0,255,0,0.3)', padding: '0.125rem'
                                        }}
                                        onClick={() => setShowSettings(false)}
                                    ><span>x</span></Box>
                                    <Card sx={{//position:'absolute',left:'50%',transform:'translate(-50%,0)',top:'0.75rem',
                                        padding: '0rem', textAlign: 'center',
                                        bgcolor: 'rgba(0,0,0,0.5)', backdropFilter: 'blur(5px)',
                                        margin: 'auto', width: 'fit-content',
                                        borderRadius: '10px', border: `1px solid ${artist?.stream?.isStreaming ? '#00FF00' : '#FF0000'}`
                                    }}>

                                        <b style={{ color: artist?.stream?.isStreaming ? '#00FF00' : '#FF0000' }}>streaming</b>
                                        <Divider sx={{ borderColor: artist?.stream?.isStreaming ? '#00FF00' : '#FF0000' }} />
                                        <Stack direction="row" spacing={1} alignItems="center" justifyContent='center' textAlign={'center'}>
                                            <Typography sx={{ color: '#FF0000', alignSelf: 'center', paddingLeft: '0.25rem' }}>off</Typography>

                                            <Switch defaultValue={artist?.stream?.isStreaming} inputProps={{ 'aria-label': 'ant design' }}
                                                onChange={async (e) => {
                                                    // toggleStorefront(currentShop)
                                                    try {

                                                        const data = await API.updateArtist({
                                                            _id: artist?._id, stream: {
                                                                ...artist.stream,
                                                                isStreaming: !artist.stream.isStreaming
                                                            }
                                                        })
                                                        if (data) {
                                                            setArtist(data)
                                                        }
                                                    } catch (err) { console.log(err) }
                                                }}

                                                checked={artist?.stream?.isStreaming} />

                                            <Typography sx={{ color: '#00FF00', alignSelf: 'center', paddingRight: '0.25rem' }}>on</Typography>
                                        </Stack>
                                    </Card>
                                    {videoDevices && <Stack sx={{ gap: '0.75rem' }}>

                                        <Typography>
                                            Camera
                                        </Typography>
                                        <select type="select" value={videoDevices[currentCameraIndex]?.deviceId}
                                            style={{
                                                width: '70%', margin: 'auto', bgcolor: 'rgba(0,0,0,0.5)',
                                                backdropFilter: 'blur(5px)',

                                                border: '1px solid #00FF00', borderRadius: '5px',
                                                // color: '#00FF00',
                                                boxShadow: '0px 0px 3px 1px rgba(0,255,0,0.3)',
                                            }}
                                            // defaultValue={qrCodeData?.dotOptions?.type} 
                                            name="dotOptions.type" onChange={(e) => switchToSpecificCamera(e)} >
                                            {(videoDevices?.length > 0) && videoDevices?.map((v, i) => {
                                                // console.log('device:', v)
                                                return (
                                                    <option value={v?.deviceId} key={i}>{v?.label}</option>
                                                )
                                            })}
                                        </select>
                                    </Stack>}
                                </Stack>}
                            </>

                        }

                        <Container sx={{
                            display: 'flex', position: 'relative', maxHeight: '90vh',
                            flexDirection: 'column', justifyContent: 'center', gap: '0.5rem'
                        }}>
                            {!artist?.stream?.isStreaming && (
                                <Stack sx={{ position: 'sticky', top: '35%', }}>
                                    <Typography variant='h3'>
                                        Stream currently unavailable.
                                    </Typography>
                                    <Typography variant='caption'>

                                        ( Check back soon! )
                                    </Typography>
                                </Stack>
                            )}
                            {/* {!userData?._id && <Box
                        className='color-grad-blue-border'
                            sx={{
                                margin:'auto',
                                // position:'absolute',
                                // top:'50%',
                                // left:'50%',
                                // transform: 'translate(-50%,-50%)',
                                zIndex:999999,
                                width:'60%',
                                maxWidth:'400px',
                                backgroundColor:'rgba(255,255,255,0.7)',
                                border:'2px solid #000',
                                borderRadius:'10px',padding:'0.5rem'
                                }}>
                                
                                    {showLogin?<Login view='stream'/>:
                                    <Box sx={{cursor:'pointer'}}
                                    onClick={()=>setShowLogin(!showLogin)}
                                    >
                                     <Typography>
                                        Host?
                                        </Typography>   
                                   (click to login)
                                    </Box>}
                                </Box> 
                            } */}
                            {/* {artist?.owner === userData?._id &&  */}
                            {/* <Container sx={{position:'relative',padding:'0.5rem',height:'100vh',width:'100%'}}> */}
                            {/* <StreamComponent isStreaming={isStreaming} setIsStreaming={setIsStreaming}/> */}

                            {/* <WebRTCComponent 
                                hostList={hostList}
                                isHost={isHost}
                                listener={listener}
                                artist={artist}
                                isStreaming={isStreaming} 
                                setIsStreaming={setIsStreaming}/> */}


                            {/* <LiveKitRoom url={EnvRoute[0]+'/socket.io'} token="YOUR_LIVEKIT_TOKEN"> */}
                            {/* <Chat>
                           <ChatEntry />
                           </Chat> */}
                            {/* <ParticipantTile />  */}
                            {/* </LiveKitRoom> */}
                            {/* <iframe> */}
                            {/* <div id="metered-frame" ref={frameRef} style={{ width: '100%', height: '500px' }} /> */}

                            {/* <script>
                           
                        </script> */}
                            {/* </iframe> */}

                            {/* </Container> */}
                            {/* } */}
                        </Container>
                        {artist?.stream?.isStreaming && (<Box sx={{
                            position: 'absolute', top: '0px', left: '0px',
                            height: '100%', width: '100%'
                        }} >

                            {/* {((isMobile && touchCheck) && roomConfig) && (<KeyboardAwareWrapper>

                                <HMSComp
                                    isHost={isHost}
                                    isMobile={isMobile && touchCheck}
                                    setIsJoined={setIsJoined}
                                    roomConfig={roomConfig}
                                />
                            </KeyboardAwareWrapper>)} */}

                            {!!roomConfig && <HMSComp
                                isMobile={isMobile && touchCheck}
                                roomConfig={roomConfig}
                                isHost={isHost}
                                isJoined={isJoined}
                                setIsJoined={setIsJoined}
                                isPlaying={isPlaying}
                                setIsPlaying={setIsPlaying}
                                listener={listener}
                                setStreamModalOpen={setStreamModalOpen}
                                showLeaveConfirm={showLeaveConfirm}
                                setShowLeaveConfirm={setShowLeaveConfirm}
                            />}



                            {/* <SingleWebRTCComponent
                                hostList={hostList}
                                isHost={isHost}
                                listener={listener}
                                artist={artist}
                                isStreaming={isStreaming}
                                setIsStreaming={setIsStreaming}
                                updateModalOpen={updateModalOpen}
                                setUpdateModalOpen={setUpdateModalOpen}
                                isJoined={isJoined}
                                setIsJoined={setIsJoined}
                                showLeaveConfirm={showLeaveConfirm}
                                setShowLeaveConfirm={setShowLeaveConfirm}

                            /> */}
                        </Box>)
                        }

                    </Box>
                </Container>
            </Modal >

        </>
    )
}

export default StreamModal