import React, { useEffect, useRef, useState, useCallback } from 'react';
import io from 'socket.io-client';
import * as mediasoupClient from 'mediasoup-client';
import EnvRoute from '../../../middleware/envRoute';
import { Box, Button, Stack, Tooltip, Typography, IconButton } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import EditIcon from '@mui/icons-material/Edit';
import * as API from '../../../utils/APICalls';
import PersonIcon from '@mui/icons-material/Person';
import ViewersModal from './viewersModal';
import ChatFeed from './ChatFeed';
// import adapter from 'webrtc-adapter';

const ENDPOINT = EnvRoute('prod');
const ROOM_ID = 'Base-Reality';
const COOLDOWN_PERIOD = 1000; // 1 second
let lastToggleTime = 0;


const SingleWebRTCComponent = ({ isJoined, setIsJoined, showLeaveConfirm,
  setShowLeaveConfirm, listener, artist, hostList, updateModalOpen, setUpdateModalOpen }) => {
  const isHost = hostList?.includes(listener?._id);
  // const [isHost, setIsHost] = useState(false);
  const [showCameraOptions, setShowCameraOptions] = useState(true);
  const [producers, setProducers] = useState(new Map());
  const [participants, setParticipants] = useState(null);
  const [currentCameraIndex, setCurrentCameraIndex] = useState(0);
  const [isProducerReady, setIsProducerReady] = useState(false)
  const [showViewers, setShowViewers] = useState(false)
  const [isVideoOnTop, setIsVideoOnTop] = useState(true)
  // const [showLeaveConfirm, setShowLeaveConfirm] = useState(false)
  const [confirmedLeave, setConfirmedLeave] = useState(false)

  let rtpCaps;
  let dtlsParams;
  let iceConfig;
  const [state, setState] = useState({
    consumers: [],
    localStream: null,
    isConnected: false,
    isJoining: false,
    isJoined: false,
    isProducerReady: false,
    currentCameraIndex: 0,
    videoDevices: [],
    routerRTPCaps: null
  });
  let retryAmount = -1
  const retryMax = 5

  const updateState = (newState) => setState(prev => ({ ...prev, ...newState }));

  const socketRef = useRef();
  const deviceRef = useRef();
  const localVideoRef = useRef();
  const remoteVideoRef = useRef();
  const remoteAudioRef = useRef();
  const producerTransportRef = useRef();
  const consumerTransportRef = useRef();
  const [videoDevices, setVideoDevices] = useState([]);
  const [isConnected, setIsConnected] = useState(false);
  // const [isJoined, setIsJoined] = useState(false);
  const [isJoining, setIsJoining] = useState(false);
  const [localStream, setLocalStream] = useState(null);
  const [remoteStreams, setRemoteStreams] = useState(new Map());
  const [remoteStream, setRemoteStream] = useState(null);

  const [viewersModalOpen, setViewersModalOpen] = useState()

  // useEffect(() => {
  // console.log('[connectWithRetry] routerRtpCaps:', state?.routerRTPCaps);
  // console.log('[connectWithRetry] rtpCaps:', rtpCaps);
  // }, [rtpCaps]);

  const connectWithRetry = async () => {
    try {
      console.log('Retry #:', retryAmount)
      retryAmount++;


      const { routerRtpCapabilities, iceConfiguration } = await API.fetchRTPConfig();
      console.log('[connectWithRetry] routerRtpCapabilities:', routerRtpCapabilities)
      if (!!routerRtpCapabilities) {
        rtpCaps = routerRtpCapabilities
        iceConfig = iceConfiguration
        updateState({ routerRTPCaps: routerRtpCapabilities });
        console.log('[connectWithRetry] rtpCaps:', rtpCaps)
        console.log('[connectWithRetry] iceConfig:', iceConfig)
      } else { console.log('no routerRtpCapabilities') }

      const SOCKET_ENDPOINT = ENDPOINT[0].slice(0, -4);
      socketRef.current = io(`${SOCKET_ENDPOINT}/chat`, {
        transports: ['websocket'],
        upgrade: false,
      });

      socketRef.current.on('connect', () => {
        console.log('Connected to server');
        updateState({ isConnected: true });
        setIsConnected(true)
      });
      socketRef.current.on('host-joined', ({ userId, participants, participantCount }) => {

        console.log('[host-joined] userId:', userId)
        console.log('[host-joined] participants:', participants)
        // updateState({ isConnected: true });
        // setIsConnected(true)
        setParticipants([...participants])

      });
      socketRef.current.on('viewer-joined', ({ userId, participants, participantCount }) => {

        console.log('[viewer-joined] userId:', userId)
        console.log('[viewer-joined] participants:', participants)
        // updateState({ isConnected: true });
        // setIsConnected(true)
        setParticipants(participants)
      });
      socketRef.current.on('participant-left', ({ userId, participants, participantCount }) => {

        console.log('[participant-left] userId:', userId)
        console.log('[participant-left] participants:', participants)
        // updateState({ isConnected: true });
        // setIsConnected(true)
        setParticipants(participants)
      });

      socketRef.current.on('disconnect', () => {
        console.log('Disconnected from server');
        updateState({ isJoined: false, isConnected: false });
        setIsProducerReady(false)
        setIsJoined(false)
        setIsJoining(false)
        setIsConnected(false)

        setTimeout(() => {
          console.log('Attempting to reconnect...');
          joinRoom(ROOM_ID, listener?._id);
        }, 5000);
      });


    } catch (err) {
      console.error('Connection error:', err);
      setIsProducerReady(false)
    }
  };

  const getVideoDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(device => device.kind === 'videoinput');
      updateState({ videoDevices });
      setVideoDevices(videoDevices)

    } catch (error) {
      console.error('Error getting video devices:', error);
    }
  };


  const joinRoom = async (roomId, userId) => {
    console.log('[joinRoom] state?.routerRTPCaps:', state)
    if (!rtpCaps) {
      // if(  retryAmount < retryMax  ){
      //   console.log('Retry #:',retryAmount)
      connectWithRetry()
      // } else { console.log('Max Retries Met')}
    } else {

      try {
        updateState({ isJoining: true });
        setIsJoining(true)
        deviceRef.current = new mediasoupClient.Device();

        try {
          await deviceRef.current.load({ routerRtpCapabilities: rtpCaps });
        } catch (error) {
          console.error("Error loading device:", error);
          // Handle the error appropriately
        }
        socketRef.current.emit('join-room', roomId, userId, isHost, {
          rtpCapabilities: deviceRef.current.rtpCapabilities
        });

        // if (isHost) {
        await setupProducerTransport(roomId, userId);

        if (isHost && producerTransportRef.current) {
          try {
            const stream = await navigator.mediaDevices.getUserMedia({
              video: { deviceId: videoDevices[currentCameraIndex]?.deviceId },
              audio: true,
            }).catch(e => {
              console.error('Error getting user media:', e);
              setIsJoining(false)
              return null;
            });

            if (!stream) {
              console.error('Failed to get user media');
              setIsJoining(false)
              return;
            }
            updateState({ localStream: stream });
            setLocalStream(stream)
            console.log('Local stream obtained:', stream.getTracks().map(track => ({
              kind: track.kind,
              enabled: track.enabled,
              readyState: track.readyState
            })));

            if (stream && localVideoRef.current) {
              localVideoRef.current.srcObject = stream;

            }
            let localVideoProducer
            let localAudioProducer
            console.log('producerTransportRef.current.connectionState:', producerTransportRef.current.connectionState)
            if (producerTransportRef.current.connectionState === 'connected' || producerTransportRef.current.connectionState === 'new') {
              localVideoProducer = await producerTransportRef.current.produce({
                track: stream.getVideoTracks()[0],
                codecOptions: { videoGoogleStartBitrate: 1000 }
              });

              if (isHost && localVideoProducer) {
                await localVideoProducer?.replaceTrack({ track: stream.getVideoTracks()[0] });

                localVideoProducer?.on("connect", () => {
                  console.log("Video connect");
                });
                localVideoProducer?.on("trackended", () => {

                  console.log("Video trackended");
                });
              }
              localAudioProducer = await producerTransportRef.current.produce({
                track: stream.getAudioTracks()[0],
                codecOptions: { opusStereo: true }
              });
              if (isHost && localAudioProducer) {
                await localAudioProducer?.replaceTrack({ track: stream.getAudioTracks()[0] });

                localAudioProducer?.on("transportclose", () => {
                  console.log("Audio transportclose");
                });
                localAudioProducer?.on("transportclose", () => {
                  console.log("Audio transportclose");
                });
              }
            }

            setIsJoining(false)
          } catch (error) {
            setIsJoining(false)
            console.error('Error in producer setup:', error);
          }
        }
        // } else {
        await setupConsumerTransport(roomId, userId);
        // }
        await new Promise(resolve => {
          socketRef.current.once('producer-transport-created', resolve);
          socketRef.current.once('consumer-transport-created', resolve);
          setIsJoining(false)


        });

        updateState({ isJoining: false, isJoined: true });
        setIsJoining(false)
        setIsJoined(true)
      } catch (error) {
        console.error('Join error:', error);
        updateState({ isJoining: false });
        setIsJoining(false)
        setIsJoined(false)
      }
    }
  };


  const setupProducerTransport = async (roomId, userId) => {
    try {
      const response = await new Promise((resolve) => {
        socketRef.current.emit('create-producer-transport', { roomId, userId }, resolve);
      });

      console.log('[Client] Producer Transport Created:', response?.params);

      const { id, iceParameters, iceCandidates, dtlsParameters } = response?.params;

      producerTransportRef.current = deviceRef.current.createSendTransport({
        id,
        iceParameters,
        iceCandidates,
        dtlsParameters,
        // iceServers: iceConfig?.iceServers,
      });

      producerTransportRef.current.on('connect', async ({ dtlsParameters }, callback, errback) => {
        console.log('[Client] Sending DTLS Parameters:', dtlsParameters);
        dtlsParams = dtlsParameters
        try {
          await new Promise((resolve, reject) => {
            socketRef.current.emit('connect-producer-transport', { dtlsParameters, roomId, userId }, (response) => {
              if (response.error) reject(new Error(response.error));
              else resolve();
            });
          });
          callback();
        } catch (error) {
          console.error('Error in connect event:', error);
          errback(error);
        }
      });
      socketRef.current.on('producer-transport-connected', async ({ roomId, userId, error }) => {

        if (error) {
          console.error('Producer transport connection failed:', error);
          // Handle the error (e.g., show an error message to the user)
          return;
        }

        console.log('Producer transport connected successfully');

        setIsProducerReady(true)


        // Update UI to reflect successful connection
        // setTransportStatus('connected');

        // Start producing media
        // startProducingMedia(roomId, userId);
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: { deviceId: videoDevices[currentCameraIndex]?.deviceId },
            audio: true
          });
          const videoTrack = stream.getVideoTracks()[0];
          await videoTrack.applyConstraints({
            deviceId: { exact: videoDevices[currentCameraIndex]?.deviceId }
          });

          if (producerTransportRef?.current?.connectionState !== 'connected' || producerTransportRef?.current?.connectionState === 'new') {
            console.warn('Transport not connected, attempting to reconnect');
            socketRef.current.emit('connect-producer-transport', { dtlsParameters: dtlsParams, roomId: ROOM_ID, userId: listener?._id });
          }
          if (producerTransportRef?.current?.connectionState === 'connected' || producerTransportRef?.current?.connectionState === 'new') {
            console.log('[toggleCamera] producerTransportRef.current?:', producerTransportRef?.current)
            // Find the video producer

            // const videoProducer = producerTransportRef?.current?.producers?.find(p => p.kind === 'video');

            const videoProducer = await producerTransportRef?.current?.produce({
              track: videoTrack,
              encodings: [
                { maxBitrate: 100000, scaleResolutionDownBy: 4 },
                { maxBitrate: 300000, scaleResolutionDownBy: 2 },
                { maxBitrate: 900000 }
              ],
              codecOptions: {
                videoGoogleStartBitrate: 1000
              }
            });


            console.log('videoProducer:', videoProducer)
            if (videoProducer && !videoProducer.closed) {
              // Replace the track in the producer
              await videoProducer?.replaceTrack({ track: videoTrack });
            } else {
              console.error('No video producer found');
              setIsJoining(false)
            }
          } else {
            setIsJoining(false)
            console.error('Transport not connected');

          }
          // socketRef.current.emit('connect-producer-transport', { dtlsParameters: dtlsParams, roomId: ROOM_ID, userId: listener?._id })
          if (localVideoRef.current) {
            localVideoRef.current.srcObject = stream;
          }

          // Update the local stream in your state
          updateState({ localStream: stream });
          setLocalStream(stream)
          setIsJoining(false)
        } catch (error) {
          console.error('Error producing media:', error);
        }
      });

      producerTransportRef.current.on('produce', async ({ kind, rtpParameters }, callback, errback) => {
        try {
          const { id } = await new Promise((resolve, reject) => {
            socketRef.current.emit('produce', { roomId, userId, kind, rtpParameters }, (response) => {
              if (response.error) reject(new Error(response.error));
              else resolve(response);
            });
          });
          callback({ id });
        } catch (error) {
          console.error('Error in produce event:', error);
          errback(error);
        }
      });

      console.log('[Client] Producer transport setup complete');

    } catch (error) {
      console.error('[Client] Error setting up producer transport:', error);
    }
  };


  const startProducingMedia = async (roomId, userId) => {
    try {
      // Get user media (audio and video)
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true, video: { deviceId: videoDevices[currentCameraIndex]?.deviceId }
      });

      // Produce audio
      const audioTrack = stream.getAudioTracks()[0];
      if (audioTrack) {
        const audioProducer = await producerTransportRef.current.produce({
          track: audioTrack,
          kind: 'audio',
          codecOptions: {
            opusStereo: 1,
            opusDtx: 1
          }
        });
        console.log('Audio producer created:', audioProducer.id);
        await audioProducer?.replaceTrack({ track: audioTrack });
      }

      // Produce video
      const videoTrack = stream.getVideoTracks()[0];
      if (videoTrack) {
        const videoProducer = await producerTransportRef.current.produce({
          kind: 'video',
          track: videoTrack,
          codecOptions: {
            videoGoogleStartBitrate: 1000
          }
        });
        console.log('Video producer created:', videoProducer.id);
        // await videoProducer?.replaceTrack({ track: videoTrack });
        if (videoProducer) {
          // Replace the track in the producer
          await videoProducer?.replaceTrack({ track: videoTrack });
        } else {
          console.error('No video producer found');
        }
      }

      // Update UI to show local media
      setLocalStream(stream);
      if (localVideoRef.current) {
        localVideoRef.current.srcObject = stream;
      }

    } catch (error) {
      console.error('Error starting media production:', error);
      // Handle the error (e.g., show an error message to the user)
    }
  };

  const setupConsumerTransport = async (roomId, userId) => {
    socketRef.current.on('consumer-transport-created', async (response) => {
      console.log('[Client] Consumer Transport Created response:', response)

      const {
        id,
        iceParameters,
        iceCandidates,
        dtlsParameters,
        // sctpParameters
      } = response.params;

      console.log('[Client] Consumer Transport Created dtlsParameters:', id)
      consumerTransportRef.current = await deviceRef.current.createRecvTransport(
        {
          id,
          iceParameters,
          iceCandidates,
          dtlsParameters,
          // sctpParameters
        });
      setupConsumerTransportEventHandlers(roomId, userId);
    });
  };

  const setupConsumerTransportEventHandlers = (roomId, userId) => {
    if (!consumerTransportRef.current) return;

    consumerTransportRef.current.on('connect', ({ dtlsParameters }, callback, errback) => {
      console.log('Consumer transport connecting...');
      socketRef.current.emit('connect-consumer-transport', { dtlsParameters, roomId, userId }, (response) => {
        if (response.error) {
          console.error('Consumer transport connection failed:', response.error);
          errback(new Error(response.error));
        } else {
          console.log('Consumer transport connected successfully');
          callback();
        }
      });
    });

    consumerTransportRef.current.on('connectionstatechange', (state) => {
      console.log('Consumer transport connection state changed to', state);
    });

    socketRef.current.on('new-consumer', async ({ consumerId, producerId, kind, rtpParameters }) => {
      try {
        if (!isHost) {

          await consumeStream(consumerId, producerId, kind, rtpParameters);
        }
      } catch (error) {
        console.error('Consumption failed:', error);
      }
    });

    socketRef.current.on('existing-producers', async (existingProducers) => {
      for (const { producerId, kind } of producers) {
        try {
          console.log('existing-producers', existingProducers)
          await requestConsumer(producerId, kind);
        } catch (error) {
          console.error('Failed to request consumer for existing producer:', error);
        }
      }
    });
  };
  const consumeStream = async (consumerId, producerId, kind, rtpParameters) => {
    let userId = listener?._id
    try {
      const consumer = await consumerTransportRef.current.consume({
        id: consumerId,
        producerId,
        kind,
        rtpParameters
      });

      const { track } = consumer;

      // Handle the consumed track (e.g., add it to a video element)
      handleConsumedTrack(track, kind);

      // Resume the consumer
      await socketRef.current.emit('resume-consumer', { consumerId, roomId: ROOM_ID, userId: listener._id });

      console.log(`${kind} consumer created and resumed`);
    } catch (error) {
      console.error('Error consuming stream:', error);
      throw error;
    }
  };

  const requestConsumer = async (producerId, kind) => {
    let userId = listener?._id
    return new Promise((resolve, reject) => {
      socketRef.current.emit('consume', { producerId, kind, roomId: ROOM_ID, userId }, (response) => {
        if (response.error) {
          reject(new Error(response.error));
        } else {
          consumeStream(response.consumerId, producerId, kind, response.rtpParameters)
            .then(resolve)
            .catch(reject);
        }
      });
    });
  };

  const handleConsumedTrack = (track, kind) => {
    if (kind === 'video') {
      // const videoElement = document.getElementById('remoteVideo');
      if (remoteVideoRef) {
        remoteVideoRef.current.srcObject = new MediaStream([track.video]);
      }
    } else if (kind === 'audio') {
      // const audioElement = document.getElementById('remoteAudio');
      if (remoteAudioRef) {
        remoteAudioRef.current.srcObject = new Audio([track.audio]);
        remoteAudioRef.play()
      }
    }
  };

  const leaveRoom = (roomId, userId) => {
    socketRef.current.emit('leave-room', { roomId, userId });
    if (producerTransportRef.current) {
      producerTransportRef.current.close();
    }
    if (consumerTransportRef.current) {
      consumerTransportRef.current.close();
    }
    setIsProducerReady(false)
    updateState({ isJoined: false });
    setIsJoined(false)
    setIsJoining(false)
  };


  const toggleCamera = async () => {
    const hasPermissions = await navigator.permissions.query({ name: 'camera' });
    // if (hasPermissions.state !== 'granted') {
    //   console.error('Camera permission not granted');
    //   return;
    // }

    const now = Date.now();
    if (now - lastToggleTime < COOLDOWN_PERIOD) {
      console.log('Please wait before switching cameras again');
      return;
    }
    lastToggleTime = now;

    if (!isHost || !producerTransportRef?.current?.connectionState === 'connected') return;
    // const newIndex = (currentCameraIndex + 1) % videoDevices.length;
    const newIndex = (currentCameraIndex + 1) % videoDevices.length;
    console.log('selectedCamera:', videoDevices[newIndex].label)
    updateState({ currentCameraIndex: newIndex });
    setCurrentCameraIndex(newIndex);
    // startProducingMedia(ROOM_ID, listener?._id)
    if (localStream) {
      localStream.getTracks().forEach(track => track.stop());
    }
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: videoDevices[newIndex].deviceId },
        audio: true
      });
      const videoTrack = stream.getVideoTracks()[0];
      await videoTrack.applyConstraints({
        deviceId: { exact: videoDevices[newIndex].deviceId }
      });


      if (producerTransportRef?.current?.connectionState === 'connected' || producerTransportRef?.current?.connectionState === 'new') {
        console.log('[toggleCamera] producerTransportRef.current?:', producerTransportRef.current?.producers)
        // Find the video producer

        const videoProducer = producerTransportRef?.current?.producers?.find(p => p.kind === 'video');

        if (videoProducer && !videoProducer.closed) {
          // Replace the track in the producer
          await videoProducer?.replaceTrack({ track: videoTrack });
        } else {
          console.error('No video producer found');
        }
      } else {
        console.error('Transport not connected');

        socketRef.current.emit('connect-producer-transport', { dtlsParameters: dtlsParams, roomId: ROOM_ID, userId: listener?._id })
      }
      if (localVideoRef.current) {
        localVideoRef.current.srcObject = stream;
      }

      // Update the local stream in your state
      updateState({ localStream: stream });
      setLocalStream(stream)
    } catch (error) {
      console.error('Error toggling camera:', error);
    }
  };
  const handleCameraSelect = async (e) => {
    // const hasPermissions = await navigator.permissions.query({ name: 'camera' });
    // if (hasPermissions.state !== 'granted') {
    //   console.error('Camera permission not granted');
    //   // return;
    // }

    const now = Date.now();
    if (now - lastToggleTime < COOLDOWN_PERIOD) {
      console.log('Please wait before switching cameras again');
      return;
    }
    lastToggleTime = now;

    const selectedCameraId = e.target.value
    const dev = await videoDevices?.find(v => v?.deviceId === selectedCameraId)
    console.log('selectedCamera:', dev.label)
    // console.log('selectedCamera:', selectedCamera)
    if (!isHost || !producerTransportRef.current) return;
    // const newIndex = (currentCameraIndex + 1) % videoDevices.length;
    // updateState({ currentCameraIndex: newIndex });
    const newIndex = videoDevices.indexOf(dev)
    setCurrentCameraIndex(newIndex);

    // startProducingMedia(ROOM_ID, listener?._id)
    if (localStream) {
      localStream.getTracks().forEach(track => track.stop());
    }


    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: videoDevices[newIndex]?.deviceId },
        audio: true
      });
      const videoTrack = stream.getVideoTracks()[0];

      // Find the video producer
      const videoProducer = producerTransportRef.current?.producers?.find(p => p.kind === 'video');

      if (videoProducer) {
        // Replace the track in the producer
        await videoProducer.replaceTrack({ track: videoTrack });
      } else {
        console.error('No video producer found');
      }

      if (localVideoRef.current) {
        localVideoRef.current.srcObject = stream;
      }

      // Update the local stream in your state
      updateState({ localStream: stream });
      setLocalStream(stream)
    } catch (error) {
      console.error('Error toggling camera:', error);
    }

  }

  useEffect(() => {
    if (artist?.stream?.isStreaming) {
      if (retryAmount < retryMax) {
        connectWithRetry();
      } else { console.log('Max Retries Met') }
      getVideoDevices();
    }
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        setIsJoining(false)
      }
      if (consumerTransportRef.current) {
        consumerTransportRef.current.close();
      }
    };
  }, []);

  useEffect(() => {

    console.log('participants:', participants)
  }, [participants?.length]);

  useEffect(() => {
    if (!socketRef.current) return;

    socketRef.current.on('new-producer', async ({ producerId, kind }) => {
      console.log('new-producer producerId:', producerId)
      const consumer = await consumeStream(producerId, ROOM_ID, kind);
      setProducers(prev => new Map(prev).set(producerId, { consumer, kind }));
    });

    return () => {
      socketRef.current.off('new-producer');
    };
  }, []);


  useEffect(() => {

  }, [showCameraOptions]);

  useEffect(() => {

  }, [isJoined]);

  useEffect(() => {

    console.log('[ ] remoteVideoRef:', remoteVideoRef.current?.srcObject)
  }, [remoteVideoRef]);

  useEffect(() => {

    // console.log('isVideoOnTop:', isVideoOnTop)
  }, [isVideoOnTop]);

  useEffect(() => {
    if (!socketRef.current) return;

    socketRef.current.on('new-consumer', async ({ consumerId, producerId, producerUserId, kind, rtpParameters, type, appData, producerPaused }) => {
      const consumer = await deviceRef.current.consumeStream({ id: consumerId, producerId, kind, rtpParameters });
      const stream = new MediaStream();
      stream.addTrack(consumer.track);

      setRemoteStreams(prev => new Map(prev).set(producerUserId, stream));

      if (kind === 'video') {
        await consumer.resume(); // Resume video consumer
      }

      socketRef.current.emit('consumer-resume', { consumerId });
    });

    socketRef.current.on('all-consumers-created', () => {
      console.log('All consumers created, ready to display streams');
      // You might want to update UI state here to indicate readiness
    });

    // ... other event listeners ...

    return () => {
      socketRef.current.off('new-consumer');
      socketRef.current.off('all-consumers-created');
      // ... remove other listeners ...
    };
  }, []);

  const handleNewProducer = async ({ producerId, userId, kind }) => {
    console.log(`New ${kind} producer ${producerId} from user ${userId}`);
    await consumeStream(producerId, ROOM_ID, kind);
  };

  const handleExistingProducers = async (producers) => {
    for (const { producerId, kind } of producers) {
      await consumeStream(producerId, ROOM_ID, kind);
    }
  };

  const memoizedJoinRoom = useCallback(() => joinRoom(ROOM_ID, listener?._id), [listener?._id]);

  return (
    <Stack sx={{
      position: 'relative', gap: '0.5rem', padding: '1rem 0.5rem',
      zIndex: 0,
      marginTop: '1rem', height: '100%',
      // maxHeight: '90vh'
    }}>
      <Stack sx={{
        position: 'absolute',
        top: '10vh',
        left: '50%',
        transform: 'translate(-50%,-10vh)',
        width: '100%',
        // maxWidth: '100vw',
        // height: '100vh',
        // minHeight: '600px',
        // border: '1px solid #00FF00',
        // padding: '0.5rem',
        borderRadius: '5px',
        zIndex: !isVideoOnTop ? 0 : 999999
      }}>
        {(isHost && isJoined && producerTransportRef) && (
          <Typography sx={{ color: isProducerReady ? '#00FF00' : 'orange' }}>
            Host {isProducerReady ? 'Ready' : 'Initializing...'}
          </Typography>
        )}
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          position: 'relative',
          width: '60%',
          height: '100%',
          minHeight: '15vh',
          maxHeight: '250px',
          margin: 'auto',
          padding: '0.75rem',
          paddingTop: '0.5rem',
          border: '1px solid #00FF00',
          borderRadius: '5px 5px 0 0',
          borderBottom: 'none',
          backdropFilter: 'blur(5px)',
          background: 'rgba( 0, 0,0,0.2)',
          boxShadow: `inset  0px 0px 8px 4px  rgba( 0, 255, 0, 0.3)`
        }}>
          {isJoined && <Tooltip Tooltip title='participants'
            style={{
              position: 'absolute',
              left: '0.25rem',
              top: '0.25rem',
              cursor: isHost ? 'pointer' : '',
              color: '#00FF00',
              display: 'flex', gap: '0.25rem',
              textAlign: 'center',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center', width: '4rem'
            }}
            onClick={() => {
              if (isHost) {
                setShowViewers(true)

              }
            }}
          >{participants?.length > 0 ? participants?.length : '0'} <PersonIcon /> </Tooltip>}

          {artist?.stream?.isStreaming && (
            <Stack sx={{ justifyContent: 'center', textAlign: 'center', }}>
              <Typography sx={{ color: isConnected ? '#00FF00' : 'orange', fontSize: !isHost ? '0.7rem' : '0.8rem' }}>
                {isConnected && isHost ? 'Hosting' : isConnected && !isHost ? 'Connected to' : 'Disconnected'}
                {/* <br /> */}
                &nbsp;<b>{ROOM_ID}</b>
              </Typography>
              <Box style={{
                display: 'flex', width: '100%', alignSelf: 'center',
                alignContent: 'center', justifyContent: 'center',
                gap: '0.5rem',
              }}>

                <Typography sx={{ color: isConnected ? '#00FF00' : 'orange', fontSize: '0.8rem' }}>
                  as <b>{listener?.preferredName || 'listener' + listener?._id}</b>
                </Typography>
                <EditIcon
                  className='button-woddle'
                  sx={{ fontSize: '1rem', color: isConnected ? '#00FF00' : 'orange', cursor: 'pointer' }}
                  onClick={() => {
                    updateModalOpen = setUpdateModalOpen(true)
                  }}
                />
              </Box>

            </Stack>
          )}

          {isConnected && (
            <Box sx={{
              display: "flex", margin: 'auto',
              gap: '0.5rem', justifyContent: 'center'
            }}>
              {/* {routerRtpCapabilities && 
       <> */}

              {!isJoined && (
                <Button
                  disabled={isJoining}
                  loading={isJoining}
                  // color='success'
                  className='blink'
                  sx={{
                    color: isJoining ? 'rgba( 255, 135, 1, 1)' : '#00FF00', borderColor: isJoining ? 'rgba( 255, 135, 1,1)' : '#00FF00',
                    boxShadow: `inset 0px 0px 5px 4px ${isJoining ? 'rgba( 255, 135, 1,1)' : ' rgba( 0, 255, 0, 0.3)'} `,
                    width: '55px',
                  }}
                  variant='outlined' size="small" onClick={memoizedJoinRoom}>
                  {/* <Button disabled={isJoining} variant='outlined' onClick={joinRoom}> */}
                  Join{isJoining ? 'ing' : ''} Room
                </Button>
              )}
              {isJoined && (
                <Button variant='outlined' color='error' size="small" sx={{
                  color: '#FF0000', borderColor: '#FF0000',
                  width: '55px',
                  boxShadow: '0px 0px 5px 3px rgb(255, 0, 0,0.3),inset  0px 0px 10px 4px rgba(255, 0, 0, 0.3) ',
                }} onClick={() => setShowLeaveConfirm(true)
                  // () => leaveRoom(ROOM_ID, listener?._id)
                }>
                  Leave Room
                </Button>
              )}
              <Dialog
                open={showLeaveConfirm}
                onClose={() => setShowLeaveConfirm(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Exit Room?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Would you like to leave the room?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setShowLeaveConfirm(false)}>Stay</Button>
                  <Button onClick={() => {
                    leaveRoom(ROOM_ID, listener?._id)
                    setShowLeaveConfirm(false)

                  }} autoFocus>
                    Leave
                  </Button>
                </DialogActions>
              </Dialog>

              {isHost && !showCameraOptions && <Button variant='outlined' size="small"
                sx={{ width: '55px' }}
                onClick={() => setShowCameraOptions(true)} >
                select camera
              </Button>}

              {isHost && showCameraOptions && (videoDevices.length > 1) && (
                <Stack sx={{
                  position: 'relative', width: '100%', border: '1px solid #00FF00',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '0.75rem',
                  gap: '0.5rem',
                  borderRadius: '5px',
                  boxShadow: '0px 0px 3px 1px rgba(0,255,0,0.5)',
                  backdropFilter: 'blur(5px)',
                  // background: 'rgba( 0, 0,0,0.5)',

                }}>
                  <Box className='button-woddle'
                    sx={{
                      display: 'flex',
                      cursor: 'pointer', fontWeight: 'bold', //padding: '0.125rem 0.125rem',
                      textAlign: 'center', alignContent: 'center', justifyContent: 'center',
                      alignItems: 'center',
                      border: '1px solid #00FF00', borderRadius: '5px',
                      color: '#00FF00',
                      position: 'absolute', right: '0.25rem', top: '0.25rem',
                      height: '1.125rem',
                      width: '1.125rem',
                      fontSize: '1rem',
                      boxShadow: '0px 0px 3px 1px rgba(0,255,0,0.3)',
                    }}
                    onClick={() => setShowCameraOptions(false)}>
                    <span>x</span>

                  </Box>
                  <Button variant='outlined' size="small" sx={{ color: '#00FF00' }}
                    onClick={toggleCamera}>
                    Switch Camera
                  </Button>
                  {/* <IconButton  sx={{ color: '#00FF00' }}variant='outlined' size="small" onClick={toggleCamera}>
                    {/* Switch Camera */}
                  {/* <CameraswitchIcon sx={{ color: '#00FF00' }} />
                </IconButton> */}
                  <select type="select" value={videoDevices[currentCameraIndex]?.deviceId}
                    style={{ width: '70%' }}
                    // defaultValue={qrCodeData?.dotOptions?.type} 
                    name="dotOptions.type" onChange={(e) => handleCameraSelect(e)} >
                    {videoDevices.map((v, i) => {
                      // console.log('device:', v)
                      return (
                        <option value={v?.deviceId} key={i}>{v?.label}</option>
                      )
                    })}
                  </select>
                </Stack>
              )}
              {/* </>
          } */}

            </Box>
          )}

        </Box>
        <Box sx={{ position: 'relative' }}>

          {!isVideoOnTop && <Box sx={{
            position: 'absolute', right: '0.3rem', top: '0.1rem',
            color: '#00FF00', fontSize: '0.75rem', fontWeight: 'bold',
            zIndex: 99999,
            backgroundColor: 'rgba(0,0,0,0.6)',
            padding: '0.25rem 0.5rem',
            backdropFilter: 'blur(5px)',
            borderRadius: '0px 5px 5px 5px',
          }}>click to bring forward</Box>}
          {/* {isConnected && isJoined && */}
          {
            isHost ? <video
              ref={localVideoRef}
              autoPlay
              playsInline
              style={{
                width: '99%',
                maxWidth: '98vw',
                // height: '100vh',
                maxHeight: '50vh',
                border: '1px solid #00FF00',
                borderRadius: '5px',
                backdropFilter: 'blur(5px)',
                background: 'rgba( 0, 0,0,0.2)',
                zIndex: !isVideoOnTop ? 0 : 9999

              }}
              onClick={() => {
                setIsVideoOnTop(true)
              }}
            /> :
              <video
                ref={remoteVideoRef}
                id='remoteVideo'
                autoPlay
                playsInline
                style={{
                  width: '99%',
                  maxWidth: '98vw',
                  // height: '100vh',
                  // minHeight: '600px',
                  maxHeight: '50vh',
                  border: '1px solid #00FF00',
                  backdropFilter: 'blur(5px)',
                  background: 'rgba( 0, 0,0,0.2)',
                  borderRadius: '5px',
                  zIndex: !isVideoOnTop ? 0 : 9999
                }}
                onClick={() => {
                  setIsVideoOnTop(true)
                }}
              />
          }
        </Box>
        {/* } */}
      </Stack >


      {
        Array.from(producers).map(([producerId, { consumer, kind }]) => (
          <video
            key={producerId}
            ref={el => {
              if (el) {
                el.srcObject = new MediaStream([consumer.track]);
                el.play().catch(e => console.error('Error playing video:', e));
              }
            }}
            autoPlay
            playsInline
            muted={kind === 'audio'}
          />
        ))
      }
      < ViewersModal
        showViewers={showViewers}
        setShowViewers={setShowViewers}
        participantIds={participants}
      />
      <Box sx={{ position: 'absolute', bottom: '0px', left: 0, width: '100%', zIndex: isVideoOnTop ? 0 : 9999 }}
        onClick={() => {
          setIsVideoOnTop(false)
        }}
      >

        <ChatFeed isVideoOnTop={isVideoOnTop}
          socketRef={socketRef}
          listener={listener}
          roomId={ROOM_ID}
          isJoined={isJoined}
          isJoining={isJoining}
          memoizedJoinRoom={memoizedJoinRoom}
        />
      </Box>
    </Stack >
  );
};

export default SingleWebRTCComponent;
