import React, { useRef, useEffect, useState } from 'react'
import * as THREE from 'three';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { OutputPass } from 'three/examples/jsm/postprocessing/OutputPass.js';
import { GUI } from 'lil-gui';
import * as Shaders from '../../src/utils/Shaders'

const Visualizer = ({ audioRef, audioURL, isPlaying, currentTime, playerRef }) => {
    const [audio, setAudio] = useState()

    const mountRef = useRef(null);
    // const audioRef = useRef(null);

    const boxParams = {
        // red: 1.0,
        // green: 0.0,
        // blue: 1.0,
        red: 0.2,
        green: 0.9,
        blue: 0.9,
        threshold: 0.3,
        strength: 0.4,
        radius: 0.5,
    };
    const sphereParams = {
        red: 0.0,
        green: 0.9,
        blue: 0.0,
        threshold: 0.3,
        strength: 0.3,
        radius: 0.5,
    };
    let audioContext;
    let source;

    function playAudio(buffer) {
        // audioContext = new AudioContext();
        // source = audioContext.createBufferSource();
        // source.buffer = buffer;
        // source.connect(audioContext.destination);
        // // source.start();
        try {

            if (audioRef.current) {
                audioRef.current.play(currentTime);
                // audioRef.current?.start(currentTime);
            }
        } catch (err) {
            throw new Error(err)
        }
    }

    function pauseAudio() {
        try {

            if (audioRef.current) {
                // currentTime = audioContext.currentTime - source.context.currentTime + source.startTime;
                audioRef.current = null;
                audioRef.current?.audio?.current?.stop();
                // audioRef.current.stop();
            }
        } catch (err) {
            throw new Error(err)
        }
    }

    function resumeAudio(buffer) {
        if (!source) {
            audioContext = new AudioContext();
            source = audioContext.createBufferSource();
            source.buffer = buffer;
            source.connect(audioContext.destination);
            source.start(currentTime);
        }
    }
    useEffect(() => {
        if (!isPlaying) {
            audioRef.current.pause()
            pauseAudio()
        }
        else {
            pauseAudio()
            resumeAudio()
        }
    }, [isPlaying])

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current?.audio?.current.stop()
            // audioRef.current.play()
        }
    }, [audioURL, audioRef])

    // Sets the color of the background.
    // renderer.setClearColor(0xFEFEFE);
    useEffect(() => {
        const renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setSize(mountRef.current.offsetWidth, mountRef.current.offsetHeight);
        // renderer.setSize(window.innerWidth, window.innerHeight);
        // document.body.appendChild(renderer.domElement);
        if (mountRef.current) {
            mountRef.current.appendChild(renderer.domElement);
        }

        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(
            35,
            window.innerWidth / window.innerHeight,
            0.1,
            1000
        );

        // Sets orbit control to move the camera around.
        const orbit = new OrbitControls(camera, renderer.domElement);

        // Camera positioning.
        // camera.position.set(6, 8, 50);
        // camera.position.set(2, 3, 50);
        camera.position.set(55, -25, 40);
        camera.lookAt(0, 0, 5);
        // camera.rotation.set(-5, 10, 0);
        // Has to be done everytime we update the camera position.
        orbit.update();


        //==================================

        const boxUniforms = {
            u_time: { value: 0.0 },
            u_frequency: { value: 0.0 },
            u_red: { value: boxParams.red },
            u_green: { value: boxParams.green },
            u_blue: { value: boxParams.blue },
        };
        const sphereUniforms = {
            u_time: { value: 0.0 },
            u_frequency: { value: 0.0 },
            u_red: { value: sphereParams.red },
            u_green: { value: sphereParams.green },
            u_blue: { value: sphereParams.blue },
        };

        const boxMat = new THREE.ShaderMaterial({
            wireframe: true,
            uniforms: boxUniforms,
            // vertexShader: Shaders.GlitchShader.vertex,
            // fragmentShader: Shaders.GlitchShader.fragment,
            vertexShader: Shaders.vertexshader,
            fragmentShader: Shaders.fragmentshader,
        });
        const sphereMat = new THREE.ShaderMaterial({
            wireframe: true,
            uniforms: sphereUniforms,
            vertexShader: Shaders.vertexshader,
            fragmentShader: Shaders.fragmentshader,
        });

        let mouseX = 0;
        let mouseY = 0;

        // document.addEventListener('mousemove', function (e) {
        //     let windowHalfX = window.innerWidth / 2;
        //     let windowHalfY = window.innerHeight / 2;
        //     mouseX = (e.clientX - windowHalfX) / 100;
        //     mouseY = (e.clientY - windowHalfY) / 100;
        // });

        const audio = playerRef.current.audio.current
        const knotGeo = new THREE.TorusKnotGeometry(10, 4, 70, 16, 2);
        const sphereGeo = new THREE.IcosahedronGeometry(6, 6);
        const boxGeo = new THREE.BoxGeometry(14, 14, 14, 6, 6, 6);
        const knotMesh = new THREE.Mesh(knotGeo, boxMat);
        const sphereMesh = new THREE.Mesh(sphereGeo, sphereMat);
        const boxMesh = new THREE.Mesh(boxGeo, boxMat);
        // scene.add(knotMesh);
        scene.add(sphereMesh);
        scene.add(boxMesh);
        // Load the background texture
        // var texture = THREE.ImageUtils.loadTexture('https://res.cloudinary.com/dzxzdsnha/image/upload/v1734750295/ART/TestGrid_Crop_md198j.png');
        // var backgroundMesh = new THREE.Mesh(
        //     new THREE.PlaneGeometry(2, 2, 0),
        //     new THREE.MeshBasicMaterial({
        //         map: texture
        //     }));


        // const loader = new THREE.TextureLoader();
        // loader.load('https://res.cloudinary.com/dzxzdsnha/image/upload/v1734750295/ART/TestGrid_Crop_md198j.png', function (texture) {
        //     scene.background = texture;
        // });


        console.log('playerRef', playerRef)
        const listener = new THREE.AudioListener();
        camera.add(listener);

        const sound = new THREE.Audio(listener);
        // Check if audio is already connected

        // if (audio && !audio.dataset.connected) {
        //     try {

        //         // sound.setMediaElementSource(null);
        //         sound.setMediaElementSource(playerRef.current.audio.current);
        //         audio.src = audioURL
        //         audio.play()
        //         audio.dataset.connected = 'true'; // Mark as connected
        //     } catch (err) { throw new Error(err) }
        // }


        const audioLoader = new THREE.AudioLoader();
        // audioLoader.load('Rex Entropy - The Fire ( Instrumental ).mp3', function (buffer) {
        audioLoader.load(audioURL, function (buffer) {
            sound.setBuffer(buffer);
            // source = sound
            // window.addEventListener('click', function () {
            audioRef.current = null
            audioRef.current = sound
            // sound.setVolume(0);
            if (playerRef.current?.audio?.current) {

                playerRef.current.audio.current.volume = 0
                playerRef.current.audio.current.muted = true
            }
            // audioRef.current.play(currentTime);
            playAudio(currentTime)
            // });

            // window.addEventListener('click', function () {
            // sound.pause();
            // });
        });

        const clock = new THREE.Clock();
        const analyser = new THREE.AudioAnalyser(sound, 32);


        renderer.outputColorSpace = THREE.SRGBColorSpace;
        const renderScene = new RenderPass(scene, camera);
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        const bloomPass = new UnrealBloomPass(
            new THREE.Vector2(window.innerWidth, window.innerHeight)
        );
        bloomPass.threshold = boxParams.threshold;
        bloomPass.strength = boxParams.strength;
        bloomPass.radius = boxParams.radius;

        const outputPass = new OutputPass();
        const bloomComposer = new EffectComposer(renderer);

        bloomComposer.addPass(renderScene);
        bloomComposer.addPass(bloomPass);
        bloomComposer.addPass(outputPass);


        function animate() {
            requestAnimationFrame(animate);
            // camera.position.x += (mouseX - camera.position.x) * 0.05;
            // camera.position.y += (-mouseY - camera.position.y) * 0.5;
            camera.lookAt(scene.position);

            boxUniforms.u_time.value = clock.getElapsedTime();
            sphereUniforms.u_time.value = clock.getElapsedTime();
            boxUniforms.u_frequency.value = analyser.getAverageFrequency();
            sphereUniforms.u_frequency.value = analyser.getAverageFrequency();


            // Get audio data
            // analyser?.getByteFrequencyData(dataArray);

            // Calculate average audio level (you can adjust this based on your needs)
            let average = 0;
            for (let i = 0; i < bufferLength; i++) {
                average += dataArray[i];
            }
            average /= bufferLength;

            // Map the average audio level to a scale range
            const minScale = 0.25; // Minimum scale value
            const maxScale = 1.5; // Maximum scale value
            const scale = minScale + (maxScale - minScale) * (average / 255);

            const boxRotationSpeed = boxUniforms.u_frequency.value / 255 * Math.PI / 180;

            boxMesh.rotation.y += boxRotationSpeed * 0.75;
            boxMesh.rotation.x += boxRotationSpeed * 0.5;
            boxMesh.rotation.z += boxRotationSpeed * 0.25;
            sphereMesh.rotation.y -= boxRotationSpeed * 0.75;

            // boxMesh.scale.x = scale;
            // boxMesh.scale.y = scale;
            // boxMesh.scale.z = scale;
            // sphereMesh.scale.set(scale, scale, scale);

            bloomComposer.render();
        }
        animate();


        // Handle resizing
        const handleResize = () => {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(window.innerWidth, window.innerHeight);
        };
        window.addEventListener('resize', handleResize);



        //==================================

        renderer.setAnimationLoop(animate);

        window.addEventListener('resize', function () {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(window.innerWidth, window.innerHeight);
            bloomComposer.setSize(window.innerWidth, window.innerHeight);
        });

        //-------GUI stuff--------
        // const gui = new GUI();

        // const colorsFolder = gui.addFolder('Colors');
        // colorsFolder.add(params, 'red', 0, 1).onChange(function (value) {
        //     uniforms.u_red.value = Number(value);
        // });
        // colorsFolder.add(params, 'green', 0, 1).onChange(function (value) {
        //     uniforms.u_green.value = Number(value);
        // });
        // colorsFolder.add(params, 'blue', 0, 1).onChange(function (value) {
        //     uniforms.u_blue.value = Number(value);
        // });

        // const bloomFolder = gui.addFolder('Bloom');
        // bloomFolder.add(params, 'threshold', 0, 1).onChange(function (value) {
        //     bloomPass.threshold = Number(value);
        // });
        // bloomFolder.add(params, 'strength', 0, 3).onChange(function (value) {
        //     bloomPass.strength = Number(value);
        // });
        // bloomFolder.add(params, 'radius', 0, 1).onChange(function (value) {
        //     bloomPass.radius = Number(value);
        // });

        return () => {
            window.removeEventListener('resize', handleResize);
            if (mountRef.current && renderer.domElement) {
                mountRef.current.removeChild(renderer.domElement);
            }
            renderer.dispose();
        };
    }, []);

    return (
        <div ref={mountRef} style={{ width: '100%', height: '380px', overflow: 'hidden' }} />
    )
}

export default Visualizer