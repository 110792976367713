import React, { useEffect, useState, useRef } from 'react';
import {
    useHMSActions,
    useHMSStore,
    selectHLSState,
    selectPeers,
    selectVideoTrackByPeerID,
    selectIsConnectedToRoom,
    selectVideoDevices,
    useVideo
} from '@100mslive/react-sdk';
import io from 'socket.io-client';

import PersonIcon from '@mui/icons-material/Person';
import ChatFeed from './ChatFeed';
import EnvRoute from '../../../middleware/envRoute';
import ChatIcon from '@mui/icons-material/Chat';
import {
    Box, Button, Stack, Tooltip,
    Typography, IconButton,
    DialogContentText,
    DialogContent,
    DialogActions,
    DialogTitle,
    Dialog,
    Container
} from '@mui/material';
import StreamDuration from './StreamDuration';
import AddAlarmIcon from '@mui/icons-material/AddAlarm';
import ViewersModal from './viewersModal';

const ENDPOINT = EnvRoute('prod');

function HMSComp({ isPlaying, setIsPlaying,
    isHost, listener, isJoined, setStreamModalOpen,
    isJoining, roomConfig, setIsJoined, setShowLeaveConfirm, showLeaveConfirm }) {

    const hmsActions = useHMSActions();
    const isConnected = useHMSStore(selectIsConnectedToRoom);
    const hlsState = useHMSStore(selectHLSState);



    const [isVideoOnTop, setIsVideoOnTop] = useState(true)
    const [streamStartTime, setStreamStartTime] = useState(0)
    const [showTime, setShowTime] = useState(false)
    const [participantIds, setParticipantIds] = useState()
    const [participants, setParticipants] = useState()
    const [showViewers, setShowViewers] = useState(false)
    const socketRef = useRef();


    // console.log('roomConfig:', roomConfig)





    useEffect(() => {
        const video = videoRef.current;
        // console.log('[StreamDuration] video:', video)
        if (video) {
            video.addEventListener('playing', () => setIsPlaying(true));
            video.addEventListener('pause', () => setIsPlaying(false));
            return () => {
                video.removeEventListener('playing', () => setIsPlaying(true));
                video.removeEventListener('pause', () => setIsPlaying(false));
            };
        }
    }, []);

    const connectChat = () => {
        const SOCKET_ENDPOINT = ENDPOINT[0].slice(0, -4);
        socketRef.current = io(`${SOCKET_ENDPOINT}/chat`, {
            transports: ['websocket'],
            upgrade: false,
        });
        socketRef.current.emit('join-room', roomConfig?.roomId, listener?._id, isHost);

        socketRef.current.on('connect', () => {
            console.log('Connected to server');

            setIsJoined(true)
        });
        socketRef.current.on('stream-start', ({ startTime }) => {
            console.log('startTime', startTime);

            setIsPlaying(true)

            setStreamStartTime(startTime)
        });
        socketRef.current.on('stream-start-time', ({ startTime }) => {
            console.log('startTime', startTime);

            setIsPlaying(true)
            setStreamStartTime(startTime)
        });
        socketRef.current.on('updated-participants', ({ participants }) => {
            console.log('participants', participants);

            setParticipantIds(participants)
        });
        socketRef.current.on('participant-left', ({ userId, participants }) => {
            console.log('participants', participants);

            setParticipantIds(participants)
        });
        socketRef.current.on('roomJoined', ({ userId, roomId, startTime }) => {
            console.log('roomJoined', startTime);

            // setIsPlaying(true)
            setStreamStartTime(startTime)
        });
        socketRef.current.on('disconnect', () => {
            console.log('Disconnected from server');

            setIsJoined(false)
            // setIsJoining(false)
            setTimeout(() => {
                console.log('Attempting to reconnect...');
                //    joinRoom(ROOM_ID, listener?._id);
            }, 5000);
        });

    }
    useEffect(() => {
        connectChat()
        hmsActions.join({
            roomId: roomConfig?.roomId,
            authToken: roomConfig?.token,
            roomCode: roomConfig?.code,
            // userName: listener?.preferredName ? listener?.preferredName : 'listener' + listener?._id,
            userName: listener?._id,
            role: isHost ? 'host' : 'viewer',
            roleName: isHost ? 'host' : 'viewer'
        });

        setIsJoined(isConnected);
    }, [hmsActions, isConnected]);
    const peers = useHMSStore(selectPeers);

    const hostPeer = peers.find(peer => peer.roleName === 'host');
    const hostVideoTrack = useHMSStore(selectVideoTrackByPeerID(hostPeer?.id));
    let trackId = hostPeer?.videoTrack
    const { videoRef } = useVideo({ trackId });

    const stopHLSStreaming = async () => {
        try {
            await hmsActions.stopHLSStreaming();
            console.log('HLS stream stopped successfully');
        } catch (err) {
            console.error('Failed to stop HLS stream:', err);
        }
    };
    const leaveStream = async () => {
        try {

            if (hlsState.running) {
                await hmsActions.stopHLSStreaming();
                console.log('HLS stream stopped successfully');
            }
            socketRef.current.emit('leave-room', roomConfig?.roomId, listener?._id, isHost)
            await stopHLSStreaming();
            await hmsActions.leave();
            if (isHost) {
                await hmsActions.endRoom();
            }
            socketRef.current.disconnect();
            setIsJoined(false);
            setShowLeaveConfirm(false);
            setStreamModalOpen(false);
        } catch (err) {
            if (err.code === 404) {
                console.log('No active HLS stream to stop');
            } else {
                console.error('Error stopping HLS stream:', err);
            }

            console.error('Error leaving stream:', err);
        }
    };

    useEffect(() => {

    }, [peers, participantIds])

    if (!hostPeer || !hostVideoTrack) {
        return <Container sx={{
            display: 'flex', textAlign: 'center',
            height: '100%', width: '100%'
        }}>
            <Typography sx={{ alignSelf: 'center', margin: 'auto' }}>

                Waiting {isHost ? 'on connection...' : 'for host...'}
            </Typography>
        </Container>;
    }

    return (
        <>
            {!isVideoOnTop && <Box sx={{
                position: 'absolute', right: '0.3rem', top: '0.1rem',
                color: '#00FF00', fontSize: '0.75rem', fontWeight: 'bold',
                zIndex: 99999,
                backgroundColor: 'rgba(0,0,0,0.6)',
                padding: '0.25rem 0.5rem',
                backdropFilter: 'blur(5px)',
                borderRadius: '0px 5px 5px 5px',
            }}>click to bring forward</Box>}
            <video
                ref={videoRef}
                autoPlay
                muted
                playsInline
                style={{
                    // display: 'flex',
                    position: 'relative',
                    alignSelf: 'center',
                    zIndex: !isVideoOnTop ? 0 : 9999,
                    borderRadius: '8px',
                    outline: `2px solid ${!isVideoOnTop ? 'rgba( 0, 0, 0, 1)' : 'rgba( 0, 255, 0, 1)'}`,
                    boxShadow: `0px 0px 8px 4px  ${!isVideoOnTop ? 'rgba( 0, 0, 0, 0.5)' : 'rgba( 0, 255, 0, 0.5)'}`
                }}
                onClick={() => {
                    setIsVideoOnTop(true)
                }}
            />
            <span style={{
                display: 'flex',
                gap: '0.25rem',
                position: 'absolute', top: '2.5rem',
                alignItems: 'center', alignContent: 'center',
                left: '3.5rem', color: '#00FF00',
                zIndex: 9999,
                // cursor: isHost ? 'pointer' : ''
                cursor: 'pointer'
            }}
                onClick={() => {
                    // if (isHost) {
                    setShowViewers(true)

                    // }
                }}
            >
                {/* <b>{peers?.length > 0 ? peers?.length : '0'}</b> */}
                <b>{participantIds?.length > 0 ? participantIds?.length : '0'}</b>
                <PersonIcon />
            </span>
            <Box sx={{
                dislay: 'flex',
                flexDirection: 'row',
                color: '#00FF00',
                position: 'absolute', top: '2.5rem',
                left: '50%', transform: 'translate(-50%,0)', zIndex: 99999,
                border: showTime ? '2px solid rgba( 0,255,0,1)' : '',
                backgroundColor: showTime ? 'rgba( 255,255,255,0.2)' : '',
                backdropFilter: 'blur(5px)',
                borderRadius: '20px',
                padding: '0.5rem',
                width: 'fit-content',
                // minWidth: '100px',
                height: showTime ? '1.75rem' : 'fit-content',
                boxShadow: showTime ? '0px 0px 5px 3px rgba(0, 255, 0 ,0.5), inset 0px 0px 6px 3px rgba(0, 0, 0 ,0.5)' : ''
                // maxWidth: '60vw',

            }}>

                <StreamDuration
                    showTime={showTime}
                    setShowTime={setShowTime}
                    isPlaying={isPlaying}
                    socketRef={socketRef}
                    startTime={streamStartTime}
                />
                {(isHost && showTime) && <Tooltip title='set timer (coming soon)'>
                    <AddAlarmIcon sx={{
                        position: 'absolute',
                        top: '-0.5rem', right: '-2rem',
                        fontSize: '1.5rem',
                        cursor: 'pointer'
                    }} />
                </Tooltip>}
            </Box>
            {isVideoOnTop &&
                <Tooltip title='chat' >
                    <Box sx={{
                        display: 'flex',
                        cursor: 'pointer',
                        color: '#FF00FF',
                        position: 'absolute', top: '5.5rem',
                        left: '1rem', zIndex: 99999,
                        border: '2px solid #00FF00',
                        borderRadius: '50px',
                        padding: '0.5rem',
                        backgroundColor: 'rgb(255, 255, 255)',
                        backdropFilter: 'blur(5px)',
                        alignContent: 'center',
                        alignItems: 'center',
                        boxShadow: '0px 0px 5px 3px rgba(0, 255, 0 ,0.5), inset 0px 0px 6px 3px rgba(0, 0, 0 ,0.5)'
                    }}

                        onClick={() => {
                            setIsVideoOnTop(false)
                        }}
                    >

                        <ChatIcon className='blink' />

                    </Box>
                </Tooltip>
            }
            <Box sx={{ position: 'absolute', bottom: '0px', left: 0, width: '100%', zIndex: isVideoOnTop ? 0 : 9999 }}
                onClick={() => {
                    setIsVideoOnTop(false)
                }}
            >

                <ChatFeed isVideoOnTop={isVideoOnTop}
                    socketRef={socketRef}
                    listener={listener}
                    roomId={roomConfig?.roomId}
                    isJoined={isJoined}
                    isJoining={isJoining}
                    isHost={isHost}
                // memoizedJoinRoom={memoizedJoinRoom}
                />
                <Dialog
                    open={showLeaveConfirm}
                    onClose={() => setShowLeaveConfirm(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Exit Room?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Would you like to leave the room?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setShowLeaveConfirm(false)}>Stay</Button>
                        <Button onClick={leaveStream} autoFocus>
                            Leave
                        </Button>
                    </DialogActions>
                </Dialog>
                < ViewersModal
                    isHost={isHost}
                    showViewers={showViewers}
                    setShowViewers={setShowViewers}
                    participantIds={participantIds}
                />
            </Box>
        </>
    );
}

export default HMSComp;
