import React,  {useContext} from 'react'
import closeIcon from '../assets/close-x.svg';
import { AppContext } from "../context/AppProvider";


const CloseButton = (props) => {
    const {appData,updateAppData, isAudioOpen,setIsAudioOpen,navValue, setNavValue, menuState, setMenuState} = useContext(AppContext);
  const {func, val ,view}= props
    const btnStyles = {
        position: 'relative',
       // zIndex: menuState & !isAudioOpen? 0: 9999,
        height:'1rem', 
        width:'1rem',  
        color: props.color? props.color  :"#000", 
        borderRadius:'20%',
        padding: '.5rem',
        boxShadow:'0px 0px 4px 2px rgba(0, 0, 0, 0.20)',
        alignItems:'center',
        justifyContent:'center', 
        cursor: 'pointer',
        backgroundColor: props.BGcolor? props.BGcolor  :'#FFF',
        border: '3px solid #000',
       

}

  return (
    <>
        <div className=" button-woddle" style={btnStyles} onClick={(e)=>{
          if ( view === 'dash'){
            // func(false)
            props.func({
            
              modalOpen: false,
              modalView:''
            })
          }else{
            props.func(false)
          }
            // updateAppData({...appData,isAudioOpen})
        }}>
        <img src={closeIcon} width='100%'/>
        {/* {!isAudioOpen && <img src={musicIcon} width='100%'/>} */}
        </div>
    </>
  )
}

export default CloseButton